<template>
  <div class="w-full p-4 lg:px-10 lg:py-5">
    <div class="flex justify-between items-center mb-5">
      <h2 class="text-dktgray text-2xl mb-5">
        Picture Reports for Clockin Setup
      </h2>
    </div>

    <api-table
      apiUrl="/get-picture-reports"
      :fields="fields"
      :show-page="true"
      :show-search="true"
      placeholder="Search by name, phone number or email"
    >
      <template slot="message" slot-scope="props">
        <p class="flex gap-2 pr-2">
          <span v-if="props.rowData.message">{{ props.rowData.message }}</span>
          <a :href="props.rowData.photoUrl" v-else>{{
            props.rowData.photoUrl
          }}</a>
        </p>
      </template>

      <template slot="action" slot-scope="props">
        <span class="flex gap-2 pr-2">
          <button
            v-can="'get-location-details'"
            @click="storeLocation(props.rowData)"
            class="bg-oneGreen text-center text-white text-xs rounded py-1 px-3"
          >
            View
          </button>
        </span>
      </template>
    </api-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "AllLocations",

  data() {
    // let sv = "hidden lg:table-cell";
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "verificationCode",
          title: "Verification number",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "title",
          title: "Title",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 uppercase`,
        },
        {
          name: "message",
          title: "Reason/Data",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 w-56`,
        },
        {
          name: "ticketNumber",
          title: "Ticket",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "reportedByName",
          title: "Operator",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("DD-MM-YYYY"),
        },
        // {
        //   name: "action",
        //   title: "Action",
        //   titleClass: `${tc} text-left pl-4 `,
        //   dataClass: `${dc} text-left pl-4`,
        // },
      ],
    };
  },

  methods: {
    onCellClicked() {
      this.$router.push({ name: "Bio Data" });
    },
    storeLocation(d) {
      this.$store.commit("admin/saveData", {
        key: "locationName",
        data: d.approvedOfficeName,
      });
      this.$router.push({
        name: "Location Details",
        params: { id: d.id },
      });
    },
  },
};
</script>
