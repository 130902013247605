<template>
  <div class="fixed top-20 left-0 h-full w-full z-100">
    <div class="relative h-full w-full opacity-30 bg-black"></div>
    <div
      class="absolute top-5 w-full mx-auto flex items-center justify-end"
      style="z-index: 1200 !important"
    >
      <div>
        <transition name="slidey" appear>
          <section
            id="mod-body"
            class="relative w-[400px] p-5 lg:mr-3 rounded-lg bg-white"
          >
            <div class="absolute top-0 left-0 right-0 flex justify-end mb-1">
              <button @click="close" class="px-3 py-3 rounded-tr-2xl">
                <img
                  src="@/assets/images/icons/close.svg"
                  alt="icons"
                  :class="`h-${bs} w-${bs}`"
                />
              </button>
            </div>

            <div id="content">
              <slot></slot>
            </div>
          </section>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalOverlay",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    mt: {
      type: [String, Number],
      default: 20,
    },
  },

  data() {
    return {
      bs: 5,
    };
  },

  computed: {
    mxw() {
      switch (this.size) {
        case "sm":
          return "30.5rem";
        case "lg":
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.bs = 7;
          return "61.75rem";
        default:
          return "39.5rem";
      }
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
#mod-body {
  /* max-width: var(--mxw); */
  padding: 2.75rem 1rem;
}
#content {
  height: 60vh;
}
</style>
