<template>
  <div class="w-full lg:w-4/12 bg-[#F2F2F2] rounded-lg p-5">
    <h5 class="text-dkgray font-bold text-sm">Authorised & Verified Staffs</h5>
    <div v-if="fetching" class="flex items-center justify-center h-full">
      <spinner class="w-20 h-20" color="bg-gray-800"></spinner>
    </div>
    <div v-else>
      <div
        v-if="!staffs.length"
        class="flex items-center justify-center h-full"
      >
        No data
      </div>
      <div v-else class="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-5 h-full">
        <div
          v-for="(val, i) in staffs"
          :key="i"
          class="text-center flex flex-col justify-center items-center bg-[#FFF] h-30 rounded-lg"
        >
          <div class="mb-3">
            <img
              v-if="val.photograph"
              class="w-[45px] h-[45px] rounded-full"
              :src="val.photograph"
              alt=""
            />
            <img
              v-else
              class="w-[35px] h-[35px] rounded-full"
              src="@/assets/images/null_female.png"
              alt=""
            />
          </div>
          <span class="text-dkgray text-[8px]">{{ val.firstName }}</span>
          <span class="text-dkgray text-[8px]">{{ val.lastName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifiedStaff",
  data() {
    return {
      staffs: [],
      fetching: true,
    };
  },
  beforeMount() {
    this.fetchVerifiedStaff();
  },
  methods: {
    async fetchVerifiedStaff() {
      try {
        const res = await this.$http.get("/recent-employees");

        if (!res) {
          this.fetching = false;
          return;
        }

        const { data } = res.data;

        this.staffs = data;
        this.fetching = false;
      } catch (error) {
        this.fetching = false;
        console.log({ error });
      }
    },
  },
};
</script>
