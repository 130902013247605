<template>
  <nav class="bg-litergray py-2.5">
    <div class="flex items-center justify-start px-3 lg:px-10 gap-4">
      <button
        @click="close"
        class="border-2 border-litgray w-10 h-10 flex justify-center items-center bg-litergray rounded lg:mr-5"
      >
        <span class="text-xl">&larr;</span>
      </button>

      <h3 class="text-sm lg:text-2xl font-bold lg:mr-5 truncate w-10/12">
        <span class="lg:text-2xl mr-1 uppercase">{{ step }}:</span>
        <span class="lg:text-xl">{{ $store.state.admin.staffName }}</span>
      </h3>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavRecord",
  props: {
    step: {
      type: String,
      require: true,
      default: "-",
    },
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
