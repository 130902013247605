<template>
  <div>
    <vue-pdf-embed
      :source="`${source}`"
      @loading-failed="handleDocumentFailed"
      @loaded="handleDocumentLoad"
    />
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  name: "PdfViewer",

  components: {
    VuePdfEmbed,
  },

  props: {
    source: {
      type: String,
      required: true,
      default: null,
    },
  },

  mounted() {
    this.$emit("change-file", { empty: false, loaded: false, real: false });
  },

  methods: {
    handleDocumentFailed() {
      this.$emit("change-file", { empty: true, loaded: true, real: false });
    },
    handleDocumentLoad() {
      this.$emit("change-file", { empty: false, loaded: true, real: true });
    },
  },
};
</script>

<style scoped></style>
