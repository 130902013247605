<template>
  <div
    class="border border-gray-200 p-5 rounded-2xl w-full mx-auto"
    style="max-width: 698px"
  >
    <div v-if="getting" class="flex justify-center h-[50vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <template v-else>
      <section class="flex flex-col items-center">
        <img
          :src="require(`@/assets/images/logos/${PROJECT_NAME}.png`)"
          :alt="`${APP_NAME} logo`"
          class="w-14 h-14"
        />

        <h1 class="font-bold text-2xl uppercase text-gray-700">
          {{ APP_NAME }}
        </h1>
        <h3 class="font-bold uppercase text-gray-600">
          NEW STAFF SETUP PRINTOUT
        </h3>
      </section>

      <section class="border border-gray-200 rounded-lg p-5 w-full mt-2.5">
        <table class="w-full">
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              TITLE
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.title }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              NAME
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staffName }}
            </td>
          </tr>

          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              Date Of Birth (DOB)
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.dateOfBirth | moment("YYYY-MM-DD") }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              GENDER
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.gender }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              NATIONALITY
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.nationality }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              STATE OF ORIGIN
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.stateOfOrigin }}
            </td>
          </tr>
          <!-- <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              LGA
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.lga }}
            </td>
          </tr> -->
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              CITY OF RESIDENCE
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.city }}
            </td>
          </tr>

          <tr class="w-full border-8 border-white">
            <td colspan="2">
              <hr />
            </td>
          </tr>

          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              DATE OF 1ST APPOINNTMENT
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.dateOfAppointment | moment("YYYY-MM-DD") }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              CADRE
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.cadre }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              SALARY GRADE LEVEL
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.salaryGradeLevel }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              STEP
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.currentStep }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              MDA
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.mdaName }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              DEPARTMENT
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.departmentName }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              STAFF TYPE
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.staffTypeName }}
            </td>
          </tr>

          <tr class="w-full border-8 border-white">
            <td colspan="2">
              <hr />
            </td>
          </tr>

          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              BANK TYPE
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.bankType }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              BANK NAME
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.bankName }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              ACCOUNT NUMBER
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.salaryAccountNumber }}
            </td>
          </tr>
          <tr class="w-full border-8 border-white">
            <th
              class="bg-green-100 text-green-900 w-1/3 text-left rounded uppercase font-bold text-xs p-1"
            >
              ACCOUNT NAME
            </th>
            <td class="text-gray-700 text-left uppercase font-bold text-xs p-1">
              {{ staff?.salaryAccountName }}
            </td>
          </tr>
        </table>
      </section>

      <section class="p-4 flex justify-center font-medium text-gray-400">
        Powered by OneID
      </section></template
    >
  </div>
</template>

<script>
export default {
  name: "StaffSummary",

  props: {
    staffData: {
      type: Object,
      default: null,
      required: false,
    },
    getting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      newStaff: { ...this.$store.state.admin.newStaff },
    };
  },

  computed: {
    staff() {
      if (this.staffData) return this.staffData;

      return this.newStaff;
    },
    staffName() {
      return this.staff.name
        ? this.staff.name
        : `${this.staff.firstName} ${this.staff.middleName ?? ""} ${
            this.staff.lastName
          }`;
    },
  },
};
</script>

<style scoped>
td {
  @apply text-gray-700 text-left uppercase font-bold text-xs p-1;
}
</style>
