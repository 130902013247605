<template>
  <div
    class="w-full lg:w-[720px] m-10 lg:ml-[116px] lg:mt-[50px] flex flex-col item-start justify-start"
  >
    <h1 class="font-mullish text-[#484848] text-[24px] font-bold m-0">
      Create a New User
    </h1>
    <span class="text-[#484848] font-mullish text-[11px] m-0 p-0"
      >Create sub level roles for users within your Institution</span
    >

    <div class="bg-[#F9F9F9] p-7 mt-10 rounded-lg">
      <div class="text-[#484848] font-bold text-[12px] lg:text-[15px]">
        You can invite both existing staff and people outside the {{ APP_NAME }}
        serivce to take up admin roles in this system.
      </div>

      <div class="mt-4">
        <p class="text-[12px] text-[#484848] mb-2">
          Enter Email, Staff Name or Verification number
        </p>
        <div class="flex">
          <v-select
            class="w-full mr-2"
            taggable
            label="email"
            :reduce="(b) => b"
            :options="users"
            v-model="user"
            :filterable="false"
            @search="onSearch"
            placeholder="-- select users --"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No results found for <em>{{ search }}</em
                >.
              </template>
              <span v-else clas="text-gray-400 lowercase text-xs">
                Start typing to search for a user
              </span>
            </template>
            <template v-slot:option="option">
              <div
                v-if="option.verificationCode"
                class="flex items-center hover:text-white/70 text-gray-600"
              >
                <img
                  class="w-[40px] h-[40px] rounded-full mr-3"
                  :src="option.photograph"
                  alt=""
                />

                <div>
                  <span>{{ option.firstName }} {{ option.lastName }}</span
                  ><br />
                  <span class="text-[10px]">{{ option.email }}</span>
                </div>
              </div>
              <div v-else class="flex items-center">
                <img
                  class="w-[40px] h-[40px] rounded-full mr-3"
                  src="@/assets/images/null_female.png"
                  alt=""
                />
                <span>{{ option.email }}</span>
              </div>
            </template>
          </v-select>

          <button
            @click="addAddmin"
            class="bg-[#1A8103] text-[12px] w-[111px] rounded text-[#fff]"
          >
            Add
          </button>
        </div>
        <div v-if="showErrMsg" class="text-[red] font-bold text-sm">
          {{ errormessage }}
        </div>
      </div>
    </div>

    <div class="mt-8" v-if="tableData.length">
      <div class="text-[24px] font-bold mb-1 text-[#0090FF]">
        Assign Role and MDA
      </div>
      <my-table
        :fields="fields"
        :tableData="tableData"
        :showPage="false"
        :showSearch="false"
      >
        <template slot="user" slot-scope="props">
          <div class="flex">
            <img
              v-if="props.rowData.user.photo"
              class="w-[35px] h-[35px] mr-2 rounded-full"
              :src="props.rowData.user.photo"
              alt=""
            />
            <img
              v-else
              class="w-[35px] h-[35px] mr-2 rounded-full"
              src="@/assets/images/null_female.png"
              alt=""
            />
            <div>
              <small class="font-bold text-[13px] capitalize">{{
                props.rowData.user.name
              }}</small
              ><br />
              <span class="text-[#8D8D8D] text-[10px]">{{
                props.rowData.user.email
              }}</span>
            </div>
          </div>
        </template>
        <template slot="role" slot-scope="props">
          <div class="w-[150px]">
            <multiselect
              class="w-[100px]"
              v-model="props.rowData.role"
              :options="roles"
              label="knownName"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="select role"
            >
            </multiselect>
          </div>
        </template>

        <template slot="mda" slot-scope="props">
          <div class="w-[220px]">
            <searchable-select
              @add-list="addMdas($event, props.rowData)"
              :list="mdas"
              :prev-values="[]"
            >
              <div class="flex justify-between items-center py-3 px-5">
                <span class="text-[10px]"> Asign MDA</span>
                <img
                  src="@/assets/images/icons/arrows/down-angle.svg"
                  class="h-[8px]"
                  alt="chevron down"
                />
              </div>
            </searchable-select>
          </div>
        </template>

        <template slot="action" slot-scope="props">
          <button
            class="bg-[red] text-[#fff] px-3 py-1 rounded"
            @click="removeUser(props.rowData)"
          >
            remove
          </button>
        </template>
      </my-table>
      <div class="border p-3 bg-[#F9F9F9] flex justify-between items-center">
        <span>{{ tableData.length }} People Added</span>
        <button
          @click="confirmUser"
          :disabled="isReady"
          class="bg-[#1A8103] py-2 px-6 text-[#fff] rounded"
        >
          Activate new user(s)
          <spinner v-if="proceeding"></spinner>
        </button>
      </div>
    </div>
    <!-- <div v-if="userCreated">
      <UserCreated />
    </div> -->
  </div>
</template>

<script>
import SearchableSelect from "@/components/admin/element/SearchableSelect.vue";
export default {
  components: {
    SearchableSelect,
  },
  name: "createUser",

  data() {
    const tc = "bg-[#C7C7C7] py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      showErrMsg: false,
      users: [],
      user: null,
      userfilter: "",
      loading: false,
      proceeding: false,
      errormessage: "",
      fields: [
        {
          name: "user",
          title: "User",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "role",
          title: "Role",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "mda",
          title: "MDA",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
      idCount: 0,
      tableData: [],
      roles: this.$store.state.admin.roles,
      mdas: this.$store.state.staff.mdas,
    };
  },
  computed: {
    isReady() {
      return this.tableData.some((el) => el.role === null || el.mda === null);
    },
    serchFormReady() {
      return (
        (this.user && /^.{3,}$/.test(this.user)) ||
        /^\d{10}$/.test(this.user) ||
        /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.user)
      );
    },
  },
  methods: {
    onSearch(search, loading) {
      if (search.length > 3) {
        this.getUsers(search, loading);
      }
    },
    async getUsers(search, loading) {
      try {
        loading(true);

        const res = await this.$http.get(
          `/users/all-employees?search=${search}`
        );

        if (!res) {
          this.users = [];
          loading(false);
          return;
        }

        const { data } = res;
        if (data.data) {
          this.users = data.data.data;
        }
        loading(false);
      } catch (error) {
        console.log(error);
        loading(false);
      }
    },
    addMdas(selectedMda, row) {
      this.tableData[this.tableData.indexOf(row)].mda = selectedMda;
    },
    async confirmUser() {
      this.proceeding = true;

      try {
        const users = this.tableData.map((el) => {
          return {
            email: el.user.email,
            roleId: el.role._id,
            mdas: el.mda,
          };
        });

        const payload = {
          users,
          baseUrl: `${window.location.origin}/admin/onboard`,
        };

        const res = await this.$http.post("/users/create", payload);

        if (!res) {
          this.proceeding = false;
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.tableData = [];
        this.$router.push({ name: "Admin List Users" });
        this.proceeding = false;
      } catch (error) {
        this.proceeding = false;
        console.log(error);
      }

      // this.userCreated = true
    },
    removeUser(data) {
      this.tableData.splice(this.tableData.indexOf(data), 1);
    },
    addAddmin() {
      this.showErrMsg = false;
      if (!this.user) {
        this.$swal({
          text: "Please enter user email or name",
          icon: "error",
        });

        return;
      }

      this.user =
        typeof this.user == "string" ? { email: this.user } : this.user;
      //check if user is existing staff
      if (!this.user.verificationCode) {
        if (
          this.user.email &&
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.user.email.toLowerCase()
          )
        ) {
          this.errormessage = "Please enter a valid email address";
          this.showErrMsg = true;
          return;
        }

        this.tableData.push({
          id: this.idCount++,
          user: {
            email: this.user.email,
            name: "New User",
            photo: null,
          },

          mda: null,
          role: null,
        });
      } else {
        this.tableData.push({
          id: this.user._id,
          user: {
            email: this.user.email,
            name: `${this.user.firstName} ${this.user.lastName}`,
            photo: this.user.photograph,
          },
          mda: null,
          role: null,
        });
      }

      this.user = null;
      this.users = [];
    },
  },
};
</script>

<style scoped>
input {
  @apply h-[40px] rounded bg-[#F8F8F8];
}

.select {
  height: 30px !important;
}
</style>
