<template>
  <div class="w-full">
    <section
      class="w-full bg-litergray flex md:justify-between items-center px-3 lg:px-6 py-2 flex-col md:flex-row sticky overflow-x-scroll top-0 right-0 left-0 z-100 shadow-sm"
    >
      <div class="flex items-center gap-2">
        <button
          @click="$router.go(-1)"
          class="px-3 py-1 text-gray-500 text-center text-lg"
        >
          &#9204;
        </button>
        <h4 class="font-bold text-xl text-dkgray">Back</h4>
      </div>
    </section>

    <div class="w-full mx-auto px-4 lg:px-10 mt-2">
      <div class="flex justify-between items-center my-5">
        <h2 class="text-dktgray text-2xl">List Staff Clockin History</h2>
      </div>
      <api-table
        :apiUrl="`/setup/clockin-history/${this.$route.params.sid}`"
        :fields="fields"
        :show-page="true"
        :show-search="true"
        placeholder="Search by name, phone number or email"
      >
        <template slot="clockInLocation" slot-scope="props">
          <span v-if="props.rowData.clockInLocation.address">
            {{ props.rowData.clockInLocation.address }}
            ({{ props.rowData.clockInLocation.latitude }},
            {{ props.rowData.clockInLocation.longitude }})
          </span>
          <span v-else>-</span>
        </template>
        <template slot="clockOutLocation" slot-scope="props">
          <span v-if="props.rowData.clockOutLocation.address">
            {{ props.rowData.clockOutLocation.address }}
            ({{ props.rowData.clockOutLocation.latitude }},
            {{ props.rowData.clockOutLocation.longitude }})
          </span>
          <span v-else>-</span>
        </template>
      </api-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListStaffClockinHisotry",

  data() {
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";

    return {
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },

        {
          name: "clockInTime",
          title: "Clock-In Time",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 w-32`,
          formatter: (val) =>
            !val ? "-" : this.moment(val).format("DD MMM YYYYY hh:mm:ss a"),
        },
        {
          name: "clockOutTime",
          title: "Clock-Out Time",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 w-32`,
          formatter: (val) =>
            !val ? "-" : this.moment(val).format("DD MMM YYYYY hh:mm:ss a"),
        },
        {
          name: "clockInLocation",
          title: "Clock-In Location",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "clockOutLocation",
          title: "Clock-Out Location",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },
};
</script>

<style scope></style>
