<template>
  <div class="px-5 w-full">
    <h4 class="font-bold text-xl">OTP Authorisation</h4>

    <section
      class="flex flex-col w-full items-center justify-center gap-12 mt-6"
    >
      <div
        class="w-full max-w-[400px] rounded-lg py-4 px-3 flex justify-between items-start gap-4 bg-[#C79C1C1A]"
      >
        <div>
          <Icon icon="mdi:warning" class="text-5xl text-[#C79C1C]" />
        </div>

        <div>
          <p class="text-dkgray text-sm fot-bold">
            {{ message }}
          </p>

          <button
            class="border border-litgray py-2 px-8 rounded-lg flex gap-2 items-center mt-4"
            @click="$emit('resend')"
            :disabled="resending"
          >
            <span>Resend OTP</span>
            <spinner v-if="resending" class="w-5 h-5"></spinner>
          </button>
        </div>
      </div>

      <div class="flex items-center space-x-2">
        <input
          v-model="form.input1"
          ref="input1"
          type="text"
          class="w-12 h-12 text-center border rounded bg-gray-200 focus:outline-none focus:border-gray-500"
          maxlength="1"
          @paste="handlePaste"
          @input="handleInput(1)"
          autofocus
        />
        <input
          v-model="form.input2"
          ref="input2"
          type="text"
          class="w-12 h-12 text-center border rounded bg-gray-200 focus:outline-none focus:border-gray-500"
          maxlength="1"
          @paste="handlePaste"
          @input="handleInput(2)"
        />
        <input
          v-model="form.input3"
          ref="input3"
          type="text"
          class="w-12 h-12 text-center border rounded bg-gray-200 focus:outline-none focus:border-gray-500"
          maxlength="1"
          @paste="handlePaste"
          @input="handleInput(3)"
        />
        <input
          v-model="form.input4"
          ref="input4"
          type="text"
          class="w-12 h-12 text-center border rounded bg-gray-200 focus:outline-none focus:border-gray-500"
          maxlength="1"
          @paste="handlePaste"
          @input="handleInput(4)"
        />
      </div>

      <div class="flex justify-end">
        <button
          @click="submitToken"
          :disabled="!formReady || processing"
          class="bg-oneGreen py-2.5 px-8 text-white rounded-lg font-semibold flex gap-2 items-center justify-center"
        >
          Authorise
          <spinner v-if="processing" class="w-4 h-4"></spinner>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
export default {
  name: "TokenVerify",

  components: {
    Icon,
  },

  props: {
    message: {
      type: String,
      default:
        "An OTP has been sent to Phone number ending with ...3432. Input OTP into the boxes below to authorise your signature to this GEN 69.",
    },
    resending: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
      },
    };
  },

  computed: {
    formReady() {
      for (const inp in this.form) {
        if (!this.form[inp] || this.form[inp] === "") {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    handleInput(index) {
      const nextInputRef = `input${index + 1}`;
      if (this.form[`input${index}`].length === 1 && this.$refs[nextInputRef]) {
        this.$refs[nextInputRef].focus();
      }
    },
    handlePaste(event) {
      // Prevent pasting more than one character in each input
      event.preventDefault();
      const pastedData = event.clipboardData
        .getData("text/plain")
        .replace(/\D/g, "");
      this.form.input1 = pastedData.charAt(0) || "";
      this.form.input2 = pastedData.charAt(1) || "";
      this.form.input3 = pastedData.charAt(2) || "";
      this.form.input4 = pastedData.charAt(3) || "";
    },
    submitToken() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please enter the token",
        });
        return;
      }

      // Submit the token to the server or perform further validation
      const token =
        this.form.input1 +
        this.form.input2 +
        this.form.input3 +
        this.form.input4;

      this.$emit("token", token);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed 3451 */
</style>
