<template>
  <footer class="w-full pb-7">
    <p class="text-[#C7C7C7] text-xs lg:text-base font-semibold text-center">
      Copyright © {{ year }} Appmart Integrated, All rights reserved
    </p>
    <p class="text-center text-oneGray mt-3">
      <router-link :to="{ name: 'Privacy Policy' }" class="underline">
        Privacy Policy
      </router-link>
    </p>
  </footer>
</template>

<script>
export default {
  name: "AuthFooter",

  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
