<template>
  <div class="pt-20 h-full w-full">
    <p
      class="py-3 border text-center border-oneGreen bg-oneGreen/20 text-oneGreen mb-2 font-bold px-5 rounded w-full w-[400px] m-auto"
    >
      COMMING SOON
    </p>
  </div>
</template>

<script>
export default {
  name: "Payroll",
};
</script>

<style></style>
