<template>
  <auth-layout>
    <section class="text-center">
      <h1 class="text-2pxl lg:text-2xl font-mulish font-black uppercase">
        {{ APP_NAME }} ADMIN LOGIN
      </h1>
      <h5 class="text-sm lg:text-base font-semibold text-oneGray">
        Login to continue
      </h5>
    </section>

    <form @submit.prevent="login">
      <div>
        <label for="emailOrPhone">Username</label>
        <input
          id="emailOrPhone"
          type="text"
          class="input"
          v-model="form.email"
          placeholder="enter email or phone number"
        />
      </div>

      <div class="mt-4">
        <label for="password">Password</label>
        <input
          id="password"
          type="password"
          v-model="form.password"
          class="input"
          placeholder="enter password"
        />
      </div>

      <div class="mt-7">
        <button
          type="submit"
          class="bg-idBlued text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
          :disabled="logging || !loginReady"
        >
          <spinner class="" v-if="logging"></spinner>
          <span>Login</span>
        </button>
      </div>
    </form>

    <section
      class="flex flex-col justify-center items-center mt-9 gap-5 text-xs lg:text-sm font-semibold"
    >
      <p class="">
        <router-link
          :to="{
            name: 'Admin Forgot Password',
          }"
          class="underline text-blue-500"
        >
          Forgot password ?
        </router-link>
      </p>

      <p>
        <router-link
          :to="{
            name: 'Login',
          }"
          class="underline text-blue-500 font-bold"
        >
          Login as staff
        </router-link>
      </p>
    </section>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "AdminLogin",

  components: {
    AuthLayout,
  },

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      logging: false,
    };
  },

  computed: {
    emailOrPhoneReady() {
      return (
        (this.form.email &&
          (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.form.email
          ) ||
            /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.email))) ||
        !this.form.email
      );
    },
    loginReady() {
      return (
        this.form.email &&
        this.emailOrPhoneReady &&
        this.form.password &&
        this.form.password.length >= 6
      );
    },
  },

  created() {
    this.$store.dispatch("general/logout");
  },

  methods: {
    async login() {
      if (!this.loginReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all input",
        });
        return;
      }

      try {
        this.logging = true;

        const res = await this.$http.post("/users/login", this.form, {
          headers: { noauth: true },
        });

        this.logging = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$store.commit("admin/savePermissions", data.payload);

        this.$store.commit("general/saveUser", {
          user: data.data,
          token: data.token,
        });

        this.$store.commit("general/login");

        if (this.$route.query.nextUrl) {
          this.$router.push(this.$route.query.nextUrl);
          return;
        }

        if (this.$store.getters["admin/permit"]("create-user")) {
          await this.$store.dispatch("admin/getPermissions");
        }

        this.$router.push({ name: "Admin Dashboard" });
      } catch (error) {
        this.logging = false;
      }
    },
  },
};
</script>

<style scoped>
form {
  @apply mx-auto w-full mt-11 lg:mt-14;
  max-width: 35rem;
}
</style>
