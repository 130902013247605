<template>
  <div
    class="background-container"
    :style="{
      '--bgImage':
        'url(' +
        require('@/assets/images/' + PROJECT_NAME + '-shield.png') +
        ')',
    }"
  >
    <AuthHeader />

    <main
      class="mt-32 lg:mt-20 font-mulish min-h-[90vh] w-screen flex justify-center items-center"
    >
      <div
        class="flex justify-center items-center h-[600px] w-full flex-wrap-reverse m-6"
      >
        <div class="w-full lg:w-4/12"></div>
        <div class="w-full lg:w-4/12 mx-auto">
          <slot> </slot>
        </div>
        <div
          class="w-full lg:h-full lg:w-4/12 flex items-center lg:items-start lg:justify-center justify-end"
        >
          <div
            @click="close"
            class="bg-[#E8E8E8] text-[20px] border-solid border-2 border-[#C7C7C7] rounded h-[50px] w-[50px] flex items-center justify-center cursor-pointer"
          >
            <img src="@/assets/images/icons/close.svg" alt="" />
          </div>
        </div>
      </div>
    </main>

    <AuthFooter />
  </div>
</template>

<script>
import AuthHeader from "@/components/auth/Header.vue";
import AuthFooter from "@/components/auth/Footer.vue";
export default {
  name: "AdminOnboardingLayout",

  components: {
    AuthHeader,
    AuthFooter,
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.background-container::before {
  content: "";
  background-image: var(--bgImage);
  background-size: 27%;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
}
</style>
