<template>
  <div class="bg-litergray p-5">
    <section
      v-if="getting"
      class="flex justify-center items-center h-[70vh] w-full"
    >
      <spinner class="w-20 h-20" color="oneGreen"></spinner>
    </section>

    <template v-else>
      <section>
        <DataField v-for="(val, i) in fields" :key="i" :field="val" />
      </section>

      <ActionButton
        :doc="doc"
        :update="true"
        @next="next()"
        @approved="updateData('approved')"
        @rejected="openReject = true"
        @requested="openUpdate = true"
        @authorized="updateData('authorized')"
      />
    </template>

    <template>
      <RejectRecord
        v-model="rejectReason"
        :open="openReject"
        @close="openReject = false"
        @rejected="updateData('rejected')"
      />

      <UpdateRecord
        :open="openUpdate"
        @close="openUpdate = false"
        @updated="updateRequest"
        :records="Object.values(fields)"
      />
    </template>
  </div>
</template>

<script>
import DataField from "@/components/admin/element/DataField.vue";
import RejectRecord from "@/components/admin/records/RejectRecord.vue";
import ActionButton from "@/components/admin/records/ActionButton.vue";
import UpdateRecord from "@/components/admin/records/UpdateRecord.vue";

export default {
  name: "AdminEmploymentData",
  components: {
    DataField,
    RejectRecord,
    ActionButton,
    UpdateRecord,
  },
  data() {
    return {
      userId: null,
      getting: false,
      doc: null,
      rejectReason: null,
      openReject: false,
      openUpdate: false,
      fields: {
        unionName: {
          key: "unionName",
          value: null,
          label: "Union Membership",
        },
        employmentStatus: {
          key: "employmentStatus",
          value: null,
          label: "Employment Status",
        },
        dateOfAppointment: {
          key: "dateOfAppointment",
          value: null,
          label: "Date of 1st Appointment",
          type: "date",
          // noedit: true
        },
        dateOfRetirement: {
          key: "dateOfRetirement",
          value: null,
          label: "Date of Retirement",
          type: "date",
          noedit: true,
        },
        presentDuty: {
          key: "presentDuty",
          value: null,
          label: "Present Duty",
        },
        pensionNumber: {
          key: "pensionNumber",
          value: null,
          label: "Pension No",
        },
        pfa: {
          key: "pfa",
          value: null,
          label: "PFA",
        },
        cadre: {
          key: "cadre",
          value: null,
          label: "Cadre",
        },
        confirmationDate: {
          key: "confirmationDate",
          value: null,
          label: "Confirmation Date",
          type: "date",
        },
        salaryGradeLevel: {
          key: "salaryGradeLevel",
          value: null,
          label: "Salary Grade Level",
          noedit: true,
        },
        step: {
          key: "step",
          value: null,
          label: "Step",
          noedit: true,
        },
        secretFile: {
          key: "secretFile",
          value: null,
          label: "Secret File",
        },
        mdaName: {
          key: "mdaName",
          value: null,
          label: "MDA",
        },
        departmentName: {
          key: "departmentName",
          value: null,
          label: "Department",
        },
        dateOfPresentConfirmation: {
          key: "dateOfPresentConfirmation",
          value: null,
          label: "Date of Present Confirmation",
          type: "date",
        },
        nationalIdNumber: {
          key: "nationalIdNumber",
          value: null,
          label: "National Identity number",
        },
        designation: {
          key: "designation",
          value: null,
          label: "Designation",
        },
      },
    };
  },
  async created() {
    this.$store.commit("staff/updateBStep", 4);
    this.userId = this.$route.params.id;
    await this.getData();
  },
  methods: {
    next() {
      this.$router.push({ name: "Admin Bank Information" });
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `process/employee/${this.userId}/info`
        );

        if (!res) {
          this.getting = false;
          return;
        }

        const { data } = res.data;

        this.doc = data;
        for (const fld in this.fields) {
          if (Object.hasOwnProperty.call(this.fields, fld)) {
            this.fields[fld].value = data[fld];
          }
        }

        this.getting = false;
      } catch (error) {
        this.getting = false;
        console.log({ error });
      }
    },
    async updateData(acn) {
      try {
        const wrd =
          acn === "approved"
            ? "Approve"
            : acn === "authorized"
            ? "Authorize"
            : "Reject";
        const rep = await this.$swal({
          icon: "question",
          text: `Are you sure you want to ${wrd} this record?`,
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.$store.commit("general/toggleLoad");
        const payload = { flag: acn };
        if (this.rejectReason) payload.reason = this.rejectReason;

        const res = await this.$http.patch(
          `process/employee/${this.userId}/info/${this.doc._id}`,
          payload
        );

        this.$store.commit("general/toggleLoad");
        if (!res) {
          return;
        }

        // // update docs
        // this.doc = res.data.data;
        if (acn !== "rejected") this.next();

        this.$swal({
          icon: "success",
          text: `Record ${acn} successfully`,
        });
      } catch (error) {
        console.log({ error });
      }
    },
    async updateRequest(dtt) {
      try {
        console.log(dtt);
        const rep = await this.$swal({
          icon: "question",
          text: `Are you sure you want to update this record?`,
          showDenyButton: true,
          confirmButtonText: "Proceed",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.$store.commit("general/toggleLoad");

        const res = await this.$http.patch(
          `process/employee/${this.userId}/update-info`,
          {
            label: dtt.record.key,
            oldValue: dtt.oldValue,
            newValue: dtt.newValue,
            updateReason: dtt.updateReason,
          }
        );

        this.$store.commit("general/toggleLoad");
        if (!res) {
          return;
        }
        this.openUpdate = false;

        // update docs
        console.log(res.data);
        // this.doc = res.data.data;
        this.getData();

        this.$swal({
          icon: "success",
          text: `Record update successfully`,
        });
      } catch (error) {
        this.$store.commit("general/toggleLoad");
        console.log({ error });
      }
    },
  },
};
</script>
