<template>
  <div class="w-full lg:w-8/12 lg:mr-10 rounded-lg p-5 shadow">
    <div class="flex justify-between items-center">
      <h5 class="font-bold">Verification Analysis</h5>

      <div class="w-[250px]">
        <searchable-select
          @add-all="filterMda"
          :list="mdaList"
          :prevValues="[]"
        >
          <template #default>
            <div class="w-full flex justify-between items-center py-3 px-5">
              <span class="text-[10px]"> Select MDA</span>
              <img
                src="@/assets/images/icons/arrows/down-angle.svg"
                class="h-[8px]"
                alt="chevron down"
              />
            </div>
          </template>
        </searchable-select>
      </div>
    </div>

    <div id="chart">
      <div v-if="fetching">
        <img
          src="@/assets/images/graphPlaceholder.png"
          class="h-[400px] w-[100%]"
          alt=""
        />
      </div>
      <div v-else>
        <apexchart
          type="bar"
          height="350"
          :options="options"
          :series="graphData.series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import SearchableSelect from "@/components/admin/element/SearchableSelect.vue";
export default {
  name: "AdminBarChart",

  components: {
    SearchableSelect,
  },

  data() {
    return {
      mdaCode: null,
      duration: null,
      fetching: true,
      graphData: null,
      options: {
        colors: ["#0090FF", "#ED4C5C"],
        chart: {
          type: "bar",
          height: 400,
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: [],
              fontSize: "7px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: "normal",
              cssClass: "apexcharts-xaxis-label",
            },
            show: true,
            // rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: true,
            // showDuplicates: false,
            trim: true,
            // minHeight: undefined,
            floating: true,
            maxHeight: 80,
          },
        },
        yaxis: {
          title: {
            text: "Number of Staff",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val} Staff`;
            },
          },
        },
      },
      mdaList: this.$store.state.staff.mdas,
    };
  },
  beforeMount() {
    this.fetchStatistics([]);
  },
  methods: {
    async fetchStatistics(mdaIds = []) {
      try {
        this.fetching = true;
        const res = await this.$http.post("/a/mda-statistics", {
          mdaIds: !mdaIds.length ? undefined : mdaIds,
        });

        if (!res) {
          this.fetching = false;
          return;
        }

        const { records } = res.data.data;

        const availableMdas = records.map((el) => el.mdaname);

        this.options.xaxis.categories = [...availableMdas.filter((x) => x)]; //filter out null

        const graphData = {
          series: [
            {
              name: "Verified Staffs",
              data: records.map((el) => el.verifiedCount),
            },
            {
              name: "Unverified Staffs",
              data: records.map((el) => el.unverifiedCount),
            },
          ],
        };
        this.graphData = graphData;
        this.fetching = false;
      } catch (error) {
        this.fetching = false;
        console.log({ error });
      }
    },
    filterMda(mdas) {
      this.fetchStatistics(mdas);
    },
  },
};
</script>
