<template>
  <div class="bg-litergray p-5">
    <section
      v-if="getting"
      class="flex justify-center items-center h-[70vh] w-full"
    >
      <spinner class="w-20 h-20" color="oneGreen"></spinner>
    </section>

    <template v-else>
      <section>
        <div
          class="flex justify-between items-center p-2.5 border border-gray-300 rounded-lg mb-4"
        >
          <p class="text-dkgray">Biometric Profile Picture</p>
          <img
            :src="profilePic || require('@/assets/images/null_female.png')"
            alt="user bvn image"
            class="rounded-lg w-14 h-14 border border-gray-400"
          />
        </div>

        <DataField v-for="(val, i) in fields" :key="i" :field="val" />
      </section>

      <ActionButton
        :doc="doc"
        :update="true"
        @next="next()"
        @approved="updateData('approved')"
        @rejected="openReject = true"
        @requested="openUpdate = true"
        @authorized="updateData('authorized')"
      />
    </template>

    <template>
      <RejectRecord
        v-model="rejectReason"
        :open="openReject"
        @close="openReject = false"
        @rejected="updateData('rejected')"
      />

      <UpdateRecord
        :open="openUpdate"
        @close="openUpdate = false"
        @updated="updateRequest"
        :records="Object.values(fields)"
      />
    </template>
  </div>
</template>

<script>
import DataField from "@/components/admin/element/DataField.vue";
import RejectRecord from "@/components/admin/records/RejectRecord.vue";
import ActionButton from "@/components/admin/records/ActionButton.vue";
import UpdateRecord from "@/components/admin/records/UpdateRecord.vue";

export default {
  name: "AdminBioData",
  components: {
    DataField,
    RejectRecord,
    ActionButton,
    UpdateRecord,
  },
  data() {
    return {
      userId: null,
      profilePic: null,
      getting: true,
      doc: null,
      rejectReason: null,
      openReject: false,
      openUpdate: false,
      fields: {
        verificationCode: {
          value: null,
          key: "verificationCode",
          label: "Verification Number",
          noedit: true,
        },
        employmentNumber: {
          value: null,
          key: "employmentNumber",
          label: "Employement Number",
        },
        title: {
          value: null,
          key: "title",
          label: "Title",
        },
        lastName: {
          value: null,
          key: "lastName",
          label: "Last Name",
          // noedit: true,
        },
        firstName: {
          value: null,
          key: "firstName",
          label: "First Name",
          // noedit: true,
        },
        middleName: {
          value: null,
          key: "middleName",
          label: "Middle Name",
        },
        dateOfBirth: {
          value: null,
          key: "dateOfBirth",
          label: "Date of Birth",
          type: "date",
          // noedit: true,
        },
        gender: {
          value: null,
          key: "gender",
          label: "Gender",
        },
        nationality: {
          value: null,
          key: "nationality",
          label: "Nationality",
          noedit: true,
        },
        stateOfOrigin: {
          value: null,
          key: "stateOfOrigin",
          label: "State of Origin",
          noedit: true,
        },
        lgaOfResidence: {
          value: null,
          key: "lgaOfResidence",
          label: "LGA",
          noedit: true,
        },
        cityOfResidence: {
          value: null,
          key: "cityOfResidence",
          label: "City/Town",
        },
        phoneNumber: {
          value: null,
          key: "phoneNumber",
          label: "Phone",
          noedit: true,
        },
        email: {
          value: null,
          key: "email",
          label: "Email",
          noedit: true,
        },
        homeAddress: {
          value: null,
          key: "homeAddress",
          label: "Home Address",
        },
      },
    };
  },
  async created() {
    this.$store.commit("staff/updateBStep", 1);
    this.userId = this.$route.params.id;
    await this.getData();
  },
  methods: {
    next() {
      this.$router.push({ name: "Admin NextOfKin" });
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(`process/employee/${this.userId}`);

        if (!res) {
          this.getting = false;
          return;
        }

        const { data } = res.data;

        this.doc = data;
        this.$store.commit("admin/saveData", {
          key: "staffName",
          data: `${data.firstName} ${data.lastName}`,
        });

        for (const fld in this.fields) {
          if (Object.hasOwnProperty.call(this.fields, fld)) {
            this.fields[fld].value = data[fld];
          }
        }
        this.profilePic = data.photograph;

        this.getting = false;
      } catch (error) {
        this.getting = false;
        console.log({ error });
      }
    },
    async updateData(acn) {
      try {
        const wrd =
          acn === "approved"
            ? "Approve"
            : acn === "authorized"
            ? "Authorize"
            : "Reject";
        const rep = await this.$swal({
          icon: "question",
          text: `Are you sure you want to ${wrd} this record?`,
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.$store.commit("general/toggleLoad");
        const payload = { flag: acn };
        if (this.rejectReason) payload.reason = this.rejectReason;

        const res = await this.$http.patch(
          `process/employee/${this.userId}`,
          payload
        );

        this.$store.commit("general/toggleLoad");
        if (!res) {
          return;
        }

        // update docs
        this.doc = res.data.data;
        if (acn !== "rejected") this.next();

        this.$swal({
          icon: "success",
          text: `Record ${acn} successfully`,
        });
      } catch (error) {
        console.log({ error });
      }
    },
    async updateRequest(dtt) {
      try {
        console.log(dtt);
        const rep = await this.$swal({
          icon: "question",
          text: `Are you sure you want to update this record?`,
          showDenyButton: true,
          confirmButtonText: "Proceed",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.$store.commit("general/toggleLoad");

        const res = await this.$http.patch(
          `process/employee/${this.userId}/update-biodata`,
          {
            label: dtt.record.key,
            oldValue: dtt.oldValue,
            newValue: dtt.newValue,
            updateReason: dtt.updateReason,
          }
        );

        this.$store.commit("general/toggleLoad");
        if (!res) {
          return;
        }
        this.openUpdate = false;

        // update docs
        console.log(res.data);
        // this.doc = res.data.data;
        this.getData();

        this.$swal({
          icon: "success",
          text: `Record update successfully`,
        });
      } catch (error) {
        this.$store.commit("general/toggleLoad");
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
button:active {
  transform: scale(0.9);
}
</style>
