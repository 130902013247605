<template>
  <div>
    <div class="body">
      <div class="text">
        <strong class="bigT">Contact Message</strong><br />
        <span class="smallT">Reply user message</span>
      </div>

      <div class="contactMsg">
        <div class="inner-contactMsg">
          <div class="personalInfo flex">
            <div class="flex-1">
              <strong class="inner-bText text-sm">User name</strong
              ><span class="inner-mText">:&nbsp;&nbsp;{{ contactName }}</span
              ><br />
              <strong class="inner-bText">Subject</strong
              ><span class="inner-mText">:&nbsp;&nbsp;{{ subject }}</span>
            </div>

            <div class="flex-1">
              <strong class="inner-bText">User phone</strong
              ><span class="inner-mText">:&nbsp;&nbsp;{{ contactPhone }}</span
              ><br />
              <strong class="inner-bText">User email</strong
              ><span class="inner-mText">:&nbsp;&nbsp;{{ contactEmail }}</span>
            </div>
          </div>
          <hr class="solid" />
          <div class="personalMsg">
            <strong class="inner-bText">Message:</strong><br />
            <span class="inner-mText">{{ message }} </span>
          </div>
        </div>
      </div>
      <form @submit.prevent="sendReply">
        <div>
          <strong class="bigT">Reply</strong>

          <textarea
            id="reply"
            type="password"
            v-model="reply"
            class="reply"
          ></textarea>
          <div class="grid grid-rows-3 grid-flow-col gap-4">
            <div class="row-start-1 row-end-4">
              <button
                class="postMsg w-[100px] h-[40px] bg-[#1A8103] py-2 mt-5 px-6 text-[#fff] rounded-lg"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "SupportReply",
  data() {
    return {
      contactName: null,
      contactEmail: null,
      contactPhone: null,
      message: null,
      subject: null,
      ticketNumber: null,
      createdAt: null,
      updatedAt: null,
      userId: null,
      reply: null,
    };
  },

  created() {
    this.userId = this.$route.params.id;

    if (!this.userId) {
      this.$router.push({ name: "Support View" });
      return;
    }

    this.getUserRecord();
  },
  methods: {
    async sendReply() {
      if (!this.reply) {
        this.$swal({
          icon: "error",
          text: "Please enter a reply",
        });
        return;
      }

      try {
        const res = await this.$http.post(`/reply-message/${this.userId}`, {
          subject: this.subject,
          message: this.reply,
        });

        if (!res) {
          return;
        }

        const { data } = res;
        this.reply = " ";
        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        this.creating = false;
      }
    },

    async getUserRecord() {
      try {
        this.getting = true;
        const res = await this.$http.get(`/contacts/${this.userId}`);
        this.getting = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.contactName = data.data.contactName;
        this.contactEmail = data.data.contactEmail;
        this.contactPhone = data.data.contactPhone;
        this.message = data.data.message;
        this.subject = data.data.subject;
        this.ticketNumber = data.data.ticketNumber;
        this.createdAt = data.data.createdAt;
        this.updatedAt = data.data.updatedAt;
      } catch (err) {
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.body {
  margin: 0% 5%;
}
.text {
  margin-bottom: 10px;
}
.contactMsg {
  width: 100%;
  height: 300px;
  border: 1px solid gray;
  border-radius: 20px;
  margin-bottom: 25px;
}
.bigT {
  font-size: 25px;
}
.smallT {
  font-size: 12px;
}

.reply {
  width: 100%;
  height: 300px;
  border: 1px solid gray;
  border-radius: 20px;
}
.personalInfo {
  width: 100%;
  height: 80px;
}
.personalMsg {
  width: 100%;
  height: 180px;
}
.inner-bText {
  font-size: 20px;
}
.postMsg {
  background: #1a7f03;
  color: #fff;
}
.inner-contactMsg {
  margin: 25px 25px;
}
.inner-mText {
  font-size: 18px;
}

hr.solid {
  border-top: 3px solid #000;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .bigT {
    font-size: 20px;
  }
  .smallT {
    font-size: 12px;
  }
  .inner-bText {
    font-size: 10px;
  }
  .inner-mText {
    font-size: 8px;
  }
}
</style>
