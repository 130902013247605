<template>
  <div>
    <AuthHeader />

    <main class="flex flex-col lg:flex-row mt-24 gap-y-8">
      <section class="flex justify-center items-center flex-col lg:w-5/12">
        <img
          :src="require(`@/assets/images/${PROJECT_NAME}-shield.png`)"
          :alt="`${APP_NAME} coat of arm`"
          class="w-32 h-28 lg:w-[40vh] lg:h-[70vh] object-contain"
        />
        <div>
          <a
            target="_blank"
            class="underline text-blue-500 px-4 py-2"
            rel="noopener noreferrer"
            :href="GUIDELINE_URL"
          >
            Download Registration Guideline
          </a>
        </div>
      </section>

      <section
        class="relative block lg:flex flex-col justify-center items-center lg:w-7/12"
      >
        <div class="w-full font-mulish pb-9 px-7">
          <slot></slot>
        </div>

        <AuthFooter />
      </section>
    </main>
  </div>
</template>

<script>
import AuthHeader from "@/components/auth/Header.vue";
import AuthFooter from "@/components/auth/Footer.vue";
export default {
  name: "AuthLayout",

  components: {
    AuthHeader,
    AuthFooter,
  },
};
</script>

<style scoped></style>
