<template>
  <div class="customed-select z-0" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      <div>
        <span class="text-idBlued">{{ title }}:</span>
        <span class="text-blue-400">{{ selectedLabel }}</span>
      </div>
    </div>

    <div class="items" :class="{ selectHide: !open }">
      <div
        v-if="!options.length"
        class="text-center italic text-dkgray/10 bg-white"
      >
        No data
      </div>

      <template v-else>
        <div
          v-for="(option, i) in options"
          :key="i"
          @click="optionClick(option)"
        >
          {{ option.label ? option.label : option }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",

  props: {
    value: [String, Number],
    options: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    default: {
      type: [String, Number],
      required: false,
      default: null,
    },
    title: {
      type: String,
      default: "Filter",
    },
    lineHeight: {
      type: String,
      default: "2.5rem",
    },
    borderColor: {
      type: String,
      default: "#ffffff",
    },
    color: {
      type: String,
      default: "#000000",
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      open: false,
      selectedLabel: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0].label
          ? this.options[0].label
          : this.options[0]
        : null,
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0].value
          ? this.options[0].value
          : this.options[0]
        : null,
    };
  },

  watch: {
    options() {
      this.selectedLabel =
        this.default || this.default === 0
          ? this.default
          : this.options.length > 0
          ? this.options[0].label
            ? this.options[0].label
            : this.options[0]
          : null;
      this.selected =
        this.default || this.default === 0
          ? this.default
          : this.options.length > 0
          ? this.options[0].value
            ? this.options[0].value
            : this.options[0]
          : null;
    },
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  methods: {
    optionClick(option) {
      this.selected =
        option.value || option.value === 0 ? option.value : option;
      this.selectedLabel = option.label ? option.label : option;
      this.open = false;
      this.$emit(
        "input",
        option.value || option.value === 0 ? option.value : option
      );
    },
  },
};
</script>

<style scoped>
.customed-select {
  @apply relative text-left py-2 rounded outline-none text-xs bg-[#F2F2F2] min-w-[116px] z-1;
}

.customed-select .selected {
  @apply bg-transparent px-2 cursor-pointer select-none text-black text-sm;
}
.customed-select .selected div {
  @apply mb-0 overflow-hidden overflow-ellipsis align-top w-10/12 flex gap-1 items-center;
  white-space: nowrap;
}
.customed-select .selected.open {
  /* @apply shadow rounded-sm; */
}

.customed-select .selected:after {
  content: "";
  @apply absolute top-[47%] right-3 w-0 h-0;
  border: 0.3125rem solid transparent;
  border-color: #053a51 transparent transparent transparent;
}

.customed-select .items {
  @apply w-full min-w-max bg-white text-idBlued rounded-b-lg overflow-hidden shadow-md absolute mt-1.5 max-h-80 overflow-y-scroll;
  z-index: 20;
}
.customed-select .items::-webkit-scrollbar {
  display: none;
}
.customed-select .items {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.customed-select .items div {
  @apply hover:bg-idBlued/10 text-idBlued px-3 cursor-pointer select-none text-xs py-3;
}

.selectHide {
  display: none;
}
</style>
