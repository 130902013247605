<template>
  <div
    class="py-8 px-6 flex flex-col justify-start items-center gap-4 border border-dtgray rounded-xl"
  >
    <h3 class="font-bold text-xl text-idBlued">BIOMETRIC DATA</h3>

    <!-- <div class="w-30 h-30"> -->
    <img
      :src="userData.photograph"
      alt="Biometric image"
      class="w-30 h-30 rounded-full border object-cover object-center"
    />
    <!-- </div> -->

    <div class="w-full">
      <div class="infos">
        <p>First Name</p>
        <p class="uppercase">{{ userData?.firstName }}</p>
      </div>

      <div class="infos">
        <p>Middle Name</p>
        <p class="uppercase">{{ userData?.middleName }}</p>
      </div>

      <div class="infos">
        <p>Last Name</p>
        <p class="uppercase">{{ userData?.lastName }}</p>
      </div>

      <div class="infos">
        <p>Email</p>
        <p class="lowercase">{{ userData?.email }}</p>
      </div>

      <div class="infos">
        <p>Phone Number</p>
        <p>{{ userData?.phoneNumber }}</p>
      </div>

      <div class="infos">
        <p>City</p>
        <p>{{ userData?.city }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BiometricDataCard",

  props: {
    userData: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.infos {
  @apply border border-litgray rounded-lg flex justify-between px-4 py-2 bg-white w-full mt-2;
}
.infos p:first-of-type {
  @apply italic text-mdgray;
}
.infos p:last-of-type {
  @apply font-bold text-dkgray;
}
</style>
