<template>
  <ActionModal :open="open" @close="$emit('close')">
    <template #content>
      <label for="rejectReason">
        Reason for Rejection
        <span class="req">*</span>
      </label>

      <textarea
        id="rejectReason"
        v-model="rejectReason"
        @input="$emit('input', rejectReason)"
        cols="30"
        rows="5"
        class="border border-dashed border-litgray px-4 py-3 rounded-md"
      ></textarea>
      <small v-if="!rejectReady" class="req text-xs font-bold">
        Please enter a valid reason (min 11 characters)
      </small>
    </template>

    <template #action>
      <button
        @click="$emit('rejected')"
        :disabled="!rejectReady"
        class="bg-oneGreen text-sm p-3 rounded-lg text-white"
      >
        Continue
      </button>
    </template>
  </ActionModal>
</template>

<script>
import ActionModal from "@/components/admin/records/ActionModal.vue";
export default {
  name: "RejectRecord",

  components: {
    ActionModal,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rejectReady() {
      return this.rejectReason && this.rejectReason.trim().length > 10;
    },
  },

  data() {
    return {
      rejectReason: null,
      isOpen: false,
    };
  },
};
</script>

<style scoped></style>
