<template>
  <div
    class="fixed inset-0 h-screen w-screen bg-black/30 z-2222 flex items-center justify-center"
  >
    <spinner class="h-20 w-20"></spinner>
  </div>
</template>

<script>
export default {
  name: "LoadOverlay",
};
</script>

<style scoped>
.z-2222 {
  z-index: 20;
}
</style>
