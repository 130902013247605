var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-litergray p-5"},[(_vm.getting)?_c('section',{staticClass:"flex justify-center items-center h-[70vh] w-full"},[_c('spinner',{staticClass:"w-20 h-20",attrs:{"color":"oneGreen"}})],1):[_c('section',{staticClass:"flex flex-col gap-7 divide-y-2"},_vm._l((_vm.fields.educations),function(education,i){return _c('fieldset',{key:i},[_c('legend',{staticClass:"text-litgray font-medium text-2xl mb-2"},[_vm._v(" Academic Institution #"+_vm._s(i + 1)+" ")]),_c('DataField',{attrs:{"field":{
            value: education.qualification,
            label: 'Institution Level',
          }}}),_c('DataField',{attrs:{"field":{
            value: education.institutionName,
            label: 'Institution Name',
          }}}),_c('DataField',{attrs:{"field":{
            value: education.degree,
            label: 'Academic Degree',
          }}}),_c('DataField',{attrs:{"field":{
            value: education.academicQualification,
            label: 'Academic Qualifications',
          }}}),_c('DataField',{attrs:{"field":{
            value: education.grade,
            label: 'Grade',
          }}}),_c('DataField',{attrs:{"field":{
            value: education.year,
            label: 'Year of graduation',
          }}})],1)}),0),_c('ActionButton',{attrs:{"doc":_vm.doc},on:{"next":function($event){return _vm.next()},"approved":function($event){return _vm.updateData('approved')},"rejected":function($event){_vm.openReject = true},"requested":function($event){_vm.openUpdate = true},"authorized":function($event){return _vm.updateData('authorized')}}})],[_c('RejectRecord',{attrs:{"open":_vm.openReject},on:{"close":function($event){_vm.openReject = false},"rejected":function($event){return _vm.updateData('rejected')}},model:{value:(_vm.rejectReason),callback:function ($$v) {_vm.rejectReason=$$v},expression:"rejectReason"}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }