<template>
  <div>
    <div class="flex justify-center flex-col items-center p-10">
      <img src="@/assets/images/bvn-failed.svg" alt="" />

      <div class="h-[30vh] flex flex-col items-center justify-center">
        <p
          class="py-3 border text-center border-red-700 bg-red-100 text-lg text-red-700 mb-2 font-bold px-5 rounded w-full"
        >
          Biometrics Verification failed. You will be redirected back to where
          you came from.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccreditationFailed",

  data() {
    return {
      status: null,
      imageId: null,
      userId: null,
      dataUrl: null,
      logging: true,
    };
  },

  async created() {
    const { DataURL, ImageID, UserID, status } = this.$route.query;
    console.log({ DataURL, ImageID, UserID, status });
    this.status = status;
    this.userId = this.$store.state.admin.biometricUser.userId;
    // this.userId = UserID;
    this.imageId = ImageID;
    this.dataUrl = DataURL;
    await this.logStatus();
  },

  methods: {
    async logStatus() {
      try {
        this.logging = true;

        const res = await this.$http.post("/biometric/update", {
          biometricCaptured: this.status === "201" ? true : false,
          UserID: this.userId,
        });

        const { data } = res;
        console.log({ data });
        this.logging = false;

        setTimeout(() => {
          window.top.location.href = `${window.location.origin}/admin/records`;
        }, 5000);
      } catch (error) {
        this.logging = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
