<template>
  <div class="bg-litergray p-5">
    <section
      v-if="getting"
      class="flex justify-center items-center h-[70vh] w-full"
    >
      <spinner class="w-20 h-20" color="oneGreen"></spinner>
    </section>

    <template v-else>
      <section class="flex flex-col gap-7 divide-y-2">
        <fieldset v-for="(education, i) in fields.educations" :key="i" class="">
          <legend class="text-litgray font-medium text-2xl mb-2">
            Academic Institution #{{ i + 1 }}
          </legend>

          <DataField
            :field="{
              value: education.qualification,
              label: 'Institution Level',
            }"
          />
          <DataField
            :field="{
              value: education.institutionName,
              label: 'Institution Name',
            }"
          />
          <DataField
            :field="{
              value: education.degree,
              label: 'Academic Degree',
            }"
          />
          <DataField
            :field="{
              value: education.academicQualification,
              label: 'Academic Qualifications',
            }"
          />
          <DataField
            :field="{
              value: education.grade,
              label: 'Grade',
            }"
          />
          <DataField
            :field="{
              value: education.year,
              label: 'Year of graduation',
            }"
          />
        </fieldset>
      </section>

      <ActionButton
        :doc="doc"
        @next="next()"
        @approved="updateData('approved')"
        @rejected="openReject = true"
        @requested="openUpdate = true"
        @authorized="updateData('authorized')"
      />
    </template>

    <template>
      <RejectRecord
        v-model="rejectReason"
        :open="openReject"
        @close="openReject = false"
        @rejected="updateData('rejected')"
      />

      <!-- <UpdateRecord
        :open="openUpdate"
        @close="openUpdate = false"
        @updated="updateRequest"
        :records="Object.values(fields)"
      /> -->
    </template>
  </div>
</template>

<script>
import DataField from "@/components/admin/element/DataField.vue";
import RejectRecord from "@/components/admin/records/RejectRecord.vue";
import ActionButton from "@/components/admin/records/ActionButton.vue";
// import UpdateRecord from "@/components/admin/records/UpdateRecord.vue";

export default {
  name: "Education",
  components: {
    DataField,
    RejectRecord,
    ActionButton,
    // UpdateRecord,
  },
  data() {
    return {
      userId: null,
      getting: false,
      doc: null,
      rejectReason: null,
      openReject: false,
      openUpdate: false,
      fields: { educations: [], professionalBodies: [] },
    };
  },
  async created() {
    this.$store.commit("staff/updateBStep", 3);
    this.userId = this.$route.params.id;
    await this.getData();
  },
  methods: {
    next() {
      this.$router.push({ name: "Admin Employment Data" });
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `process/employee/${this.userId}/education`
        );

        if (!res) {
          this.getting = false;
          return;
        }

        const { data } = res.data;
        this.doc = data;
        this.fields.educations = data.education;
        this.fields.professionalBodies = data.professionalBodies;

        this.getting = false;
      } catch (error) {
        this.getting = false;
        console.log({ error });
      }
    },
    async updateData(acn) {
      try {
        const wrd =
          acn === "approved"
            ? "Approve"
            : acn === "authorized"
            ? "Authorize"
            : "Reject";
        const rep = await this.$swal({
          icon: "question",
          text: `Are you sure you want to ${wrd} this record?`,
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        this.$store.commit("general/toggleLoad");
        const payload = { flag: acn };
        if (this.rejectReason) payload.reason = this.rejectReason;

        const res = await this.$http.patch(
          `process/employee/${this.userId}/education/${this.doc._id}`,
          payload
        );

        this.$store.commit("general/toggleLoad");
        if (!res) {
          return;
        }

        // update docs
        this.doc = res.data.data;
        if (acn !== "rejected") this.next();

        this.$swal({
          icon: "success",
          text: `Record ${acn} successfully`,
        });
      } catch (error) {
        console.log({ error });
      }
    },
    updateRequest(dtt) {
      this.openUpdate = false;
      console.log(dtt);
    },
  },
};
</script>
