<template>
  <div id="main" class="font-mulish px-12 pb-12 pt-3 bg-white">
    <div class="flex justify-end">
      <button
        @click="closeMap()"
        class="h-12 w-12 border border-gray-200 flex justify-center items-center rounded"
      >
        <img src="@/assets/images/icons/close.svg" alt="icons" />
      </button>
    </div>

    <div class="mt-2">
      <div>
        <h2 class="bg-green-50 px-4 py-3 rounded text-sm font-bold text-dkgray">
          Use the search to enter your desired location
        </h2>

        <label
          class="mt-4 flex w-full border border-litgray pl-4 gap-3 h-12 rounded"
        >
          <gmap-autocomplete
            @place_changed="initMarker"
            class="w-full outline-none"
            autofocus
          ></gmap-autocomplete>

          <button
            @click="addLocationMarker"
            class="border px-4 bg-oneGreen rounded-r"
            :disabled="!existingPlace"
          >
            <img src="@/assets/images/icons/map_add.svg" alt="icons" />
          </button>
        </label>
      </div>

      <gmap-map
        :zoom="14"
        :center="center"
        class="w-full h-[44vh] rounded mt-6 border border-litgray"
        ref="mmm"
      >
        <gmap-marker
          :position="location.position"
          @click="center = location.position"
        ></gmap-marker>
      </gmap-map>
    </div>

    <div>
      <div class="mt-8">
        <label for="location" class="text-xs text-dkgray">
          Google Generated Address
        </label>

        <p
          class="h-12 px-4 pt-3 rounded border border-litgray bg-dtgray font-bold text-sm"
        >
          {{ formattedAddress }}
        </p>
      </div>

      <div class="mt-8">
        <button
          @click="doneLocation"
          :disabled="!formReady"
          class="h-12 w-full text-center rounded bg-oneGreen font-semibold text-sm text-white"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapInterface",

  props: {
    value: {
      type: String,
    },
  },

  data() {
    return {
      location: {
        position: null,
      },
      center: {
        lat: 8.96596,
        lng: 7.45433,
      },
      existingPlace: null,
      formattedAddress: null,
    };
  },

  mounted() {
    this.locateGeoLocation();
  },

  computed: {
    formReady() {
      return this.location.position;
    },
  },

  methods: {
    closeMap(d = null) {
      this.$emit("closemap", d);
    },
    initMarker(loc) {
      console.log({ loc });
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        };
        this.location = { position: marker };
        this.center = marker;
        this.formattedAddress = this.existingPlace.formatted_address;
        this.$emit("input", this.formattedAddress);
        this.existingPlace = null;
      }
    },
    locateGeoLocation() {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    doneLocation() {
      this.closeMap(this.formattedAddress);
    },
  },
};
</script>

<style scoped>
#main {
  min-width: 470px;
  max-height: 92vh;
  overflow-y: scroll;
  /* z-index: 1; */
  box-shadow: -4px 0px 40px 0px rgba(0, 0, 0, 0.15);
}
</style>
