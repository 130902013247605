<template>
  <div class="bg-litergray p-5">
    <section
      v-if="getting"
      class="flex justify-center items-center h-[40vh] w-full"
    >
      <spinner class="w-20 h-20" color="oneGreen"></spinner>
    </section>

    <template v-else>
      <section v-if="isRecord">
        <div class="pb-5">
          <section>
            <h3 class="font-bold text-xl lg:text-3pxl text-idBlued">
              BVN VERIFICATION
            </h3>
          </section>

          <section
            class="rounded-lg bg-litergray shadow p-3.5 flex flex-col lg:flex-row gap-4 lg:gap-8 w-full mt-5 lg:mt-7"
          >
            <div>
              <img
                :src="fields.photograph"
                alt="user image"
                class="w-[150px] h-[150px] lg:w-[173px] lg:h-[173px] rounded border"
              />
            </div>

            <table>
              <tr>
                <td>BVN:</td>
                <td>{{ fields.bvnCode }}</td>
              </tr>
              <tr>
                <td>Lastname:</td>
                <td>{{ fields.lastName }}</td>
              </tr>
              <tr>
                <td>Firstname:</td>
                <td>{{ fields.firstName }}</td>
              </tr>
              <tr>
                <td>Middlename:</td>
                <td>{{ fields.middleName }}</td>
              </tr>
              <tr>
                <td>Gender:</td>
                <td>{{ fields.gender }}</td>
              </tr>
              <tr>
                <td>State of Origin:</td>
                <td>{{ fields.stateOfOrigin }}</td>
              </tr>
              <tr>
                <td>Date of Birth:</td>
                <td>{{ fields.dateOfBirth | moment("DD-MMM-YYYY") }}</td>
              </tr>
            </table>
          </section>

          <!-- IF PAYROLL NAME MATCHES/FAILS BVN NAME -->
          <template>
            <section v-if="fields.nameFailed" class="panel panel-fail mt-2">
              <img
                src="@/assets/images/icons/user-id-fail.svg"
                alt="icons"
                class="w-8 h-8 lg:w-10 lg:h-10"
              />

              <div>
                <p class="text-[#FF0000] font-medium text-xs lg:text-sm">
                  Payroll name did not match correctly with your BVN data
                </p>

                <div class="text-xs lg:text-sm font-medium text-[#8D8D8D]">
                  <p>
                    Payroll Name:
                    <span>{{ $store.getters["general/fullName"] }}</span>
                  </p>
                  <p>
                    BVN Name:
                    <span>
                      {{ fields.lastName }} {{ fields.firstName }}
                      {{ fields.middleName }}
                    </span>
                  </p>
                </div>
              </div>
            </section>

            <section v-else class="panel panel-pass mt-2">
              <img
                src="@/assets/images/icons/user-id-pass.svg"
                alt="icons"
                class="w-8 h-8 lg:w-10 lg:h-10"
              />

              <p class="text-oneGreen font-medium text-xs lg:text-sm">
                Payroll name has matched correctly with your BVN data
              </p>
            </section>
          </template>

          <!-- IF DATE OF BIRTH DONT MATCH -->
          <section
            v-if="fields.dobFailed"
            class="w-full bg-oneWarn/10 flex items-start gap-2.5 mt-4 lg:mt-5 rounded-lg p-4"
          >
            <img
              src="@/assets/images/icons/bvnwarn.svg"
              alt="icons"
              class="w-10 h-10 lg:w-10 lg:h-10"
            />

            <div class="flex flex-col gap-4">
              <p class="text-[#484848] font-medium text-xs lg:text-sm">
                A different Date of Birth has been detected from your BVN
                records,
                <span class="font-bold">
                  The staff declined to update it.
                </span>
              </p>
              <div class="text-xs lg:text-sm font-medium text-[#8D8D8D]">
                <p>
                  BVN Date of Birth:
                  <span>{{ fields.dateOfBirth | moment("Do MMMM YYYY") }}</span>
                </p>
                <p>
                  Payroll Date of Birth:
                  <span>{{
                    fields.employeeDateOfBirth | moment("Do MMMM YYYY")
                  }}</span>
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>
      <div v-else class="h-[200px] flex items-center">
        <section
          class="w-full flex items-center justify-center gap-2.5 mt-4 lg:mt-5 rounded-lg p-4"
        >
          <img
            src="@/assets/images/icons/bvnwarn.svg"
            alt="icons"
            class="w-10 h-10 lg:w-10 lg:h-10"
          />

          <div>
            <p class="text-[#484848] font-medium text-xs lg:text-sm">
              BVN Record Not Found
            </p>
            <p>Click on the button below to continue</p>
          </div>
        </section>
      </div>
      <section>
        <div
          class="flex flex-col lg:flex-row flex-wrap gap-7 justify-end mt-10 mb-3"
        >
          <button
            @click="next()"
            class="bg-oneGreen w-[150px] text-sm p-3 rounded-lg text-white"
          >
            Continue
          </button>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: "BvnVerify",

  data() {
    return {
      showValid: true,
      userId: null,
      isRecord: true,
      bvn: {
        dobFailed: true,
        nameFailed: true,
      },
      bvnVerified: true,
      fields: {
        message: null,
        dobFailed: null,
        nameFailed: null,
        accountFailed: null,
        photograph: null,
        lastName: null,
        middleName: null,
        firstName: null,
        stateOfOrigin: null,
        dateOfBirth: null,
        bvnCode: null,
        employeeDateOfBirth: null,
        gender: null,
        accountNumber: null,
      },
      getting: false,
    };
  },
  async created() {
    this.$store.commit("staff/updateBStep", 6);
    this.userId = this.$route.params.id;
    await this.getData();
  },
  methods: {
    next() {
      this.$router.push({ name: "Admin Document Upload" });
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(`process/employee/${this.userId}/bvn`);

        if (!res) {
          this.getting = false;
          this.isRecord = false;
          return;
        }

        const { data } = res.data;

        console.log(data);

        for (const fld in this.fields) {
          if (Object.hasOwnProperty.call(this.fields, fld)) {
            this.fields[fld] = data[fld];
          }
        }

        this.getting = false;
      } catch (error) {
        this.getting = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
.panel {
  @apply p-2.5 w-full flex items-center gap-2.5 mt-4 lg:mt-5 rounded-lg;
}
.panel-pass {
  @apply bg-oneGreen/10;
}
.panel-fail {
  @apply bg-[#FDEDEF];
}
</style>
