<template>
  <div
    class="py-8 px-6 flex flex-col justify-start items-center gap-4 border border-dtgray rounded-xl"
  >
    <h3 class="font-bold text-xl text-idBlued">LIVE IMAGE</h3>

    <div v-show="isCameraOpen && isLoading" class="camera-loading">
      <ul class="loader-circle">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <!-- <div v-show="!isLoading" class="camera-box" :class="{ flash: isShotPhoto }"> -->
    <div class="camera-box" :class="{ flash: isShotPhoto }">
      <video
        v-show="isCameraOpen && !isPhotoTaken"
        ref="camera"
        autoplay
        class="w-36 h-36 rounded-full"
        id="videoElement"
        style="object-fit: cover"
      ></video>

      <img
        alt="captured image"
        v-show="isPhotoTaken"
        id="imgId"
        class="rounded-full w-36 h-36 object-cover"
      />

      <p v-if="isCameraOpen" class="text-center text-red-900 font-bold mt-2">
        Make sure the face is focused within the circle
      </p>
    </div>

    <template v-if="!estimatedData">
      <div
        v-if="isCameraOpen && !isLoading && !isPhotoTaken"
        class="camera-shoot my-5"
      >
        <button type="button" class="button bg-idBlued" @click="takePhoto">
          <span>Capture</span>
          <Icon icon="heroicons:camera" class="w-7 h-7" :inline="true" />
        </button>
      </div>

      <p
        v-if="!isCameraOpen && isPhotoTaken"
        class="text-purple-600 font-bold text-sm"
      >
        Image recorded
      </p>

      <div class="camera-button">
        <small v-if="!isCameraOpen && isPhotoTaken" class="font-bold">
          Do you not like the image?
        </small>
        <button
          v-if="!reportSubmitted"
          type="button"
          class="button"
          :class="{
            'bg-idBlued': !isCameraOpen && !isPhotoTaken,
            'bg-gray-400': !isCameraOpen && isPhotoTaken,
          }"
          @click="toggleCamera"
        >
          <span v-if="!isCameraOpen && !isPhotoTaken">Open Camera</span>

          <span v-if="!isCameraOpen && isPhotoTaken"> Re-start Camera </span>
        </button>
      </div>
    </template>

    <template v-else>
      <div class="w-full">
        <div class="infos">
          <p>Gender</p>
          <p class="uppercase">{{ estimatedData?.details?.gender }}</p>
        </div>

        <div class="infos">
          <p>Age</p>
          <p class="uppercase">{{ estimatedData?.details?.age }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CameraPhoto, {
  FACING_MODES,
  // IMAGE_TYPES,
} from "jslib-html5-camera-photo";
import { Icon } from "@iconify/vue2";

export default {
  name: "CaptureImageCard",

  components: { Icon },

  props: ["value", "userData", "reportSubmitted", "estimatedData"],

  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      cameraPhoto: null,
    };
  },

  methods: {
    toggleCamera() {
      if (!this.isCameraOpen) {
        this.isPhotoTaken = false;
        this.isCameraOpen = true;
      }
      // get your video element with his corresponding id from the html
      const videoElement = document.getElementById("videoElement");

      // pass the video element to the constructor.
      this.cameraPhoto = new CameraPhoto(videoElement);
      this.cameraPhoto.startCamera(FACING_MODES.USER);
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoading = false;
          this.$swal({
            icon: "error",
            text: "Maybe the browser didn't support or there is some errors.",
          });
        });
    },

    stopCameraStream() {
      this.isCameraOpen = false;
      this.isShotPhoto = false;
      this.cameraPhoto.stopCamera();
    },

    async takePhoto() {
      const imgElement = document.getElementById("imgId");
      const config = {
        isImageMirror: true,
      };
      const dataUri = this.cameraPhoto.getDataUri(config);
      imgElement.src = dataUri;
      this.isPhotoTaken = true;

      setTimeout(() => {
        this.$emit("input", dataUri);
        this.stopCameraStream();
      }, 70);
    },
  },
};
</script>

<style scoped>
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
}
.camera-box {
  @apply flex flex-col items-center;
}
.camera-button,
.camera-shoot {
  @apply w-full;
}
.flash {
  opacity: 1;
}
#photoTaken,
#videoElement {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
  z-index: 0;
}
button {
  @apply w-full py-2.5 px-5 text-white text-sm font-bold rounded-lg flex justify-center gap-4 items-center;
}

.camera-loading {
  @apply w-full;
  min-height: 170px;
}
ul {
  height: 100%;
  width: 100%;
  z-index: 8;
  margin: 0;
}
.loader-circle {
  @apply flex justify-center items-center w-full;
}
li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #747272;
  animation: preload 1s infinite;
  border-radius: 100%;
}
li:nth-child(2) {
  animation-delay: 0.2s;
}

li:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.infos {
  @apply border border-litgray rounded-lg flex justify-between px-4 py-2 bg-white w-full mt-2;
}
.infos p:first-of-type {
  @apply italic text-mdgray;
}
.infos p:last-of-type {
  @apply font-bold text-dkgray;
}
</style>
