<template>
  <section class="px-4 w-full max-w-[750px] mx-auto py-5">
    <div class="div-data">
      <p class="text-dkgray">User Profile Picture</p>

      <div class="image-upload">
        <label for="file-input" class="relative">
          <img
            v-if="isPersonal"
            src="@/assets/images/icons/changeProfilepixicon.svg"
            alt=""
            class="absolute top-12 left-12 w-auto h-auto"
          />

          <div
            class="w-28 h-28 rounded-full object-contain border border-litgray"
          >
            <img
              :src="profilePhoto"
              width="120px"
              class="opacity-100 w-full h-full object-cover rounded-full"
            />
          </div>
        </label>

        <input
          v-if="isPersonal"
          id="file-input"
          type="file"
          @change="onFileChange"
        />
      </div>
    </div>

    <div class="div-data">
      <p>First Name</p>
      <p>{{ userData.firstName }}</p>
    </div>

    <div class="div-data">
      <p>Last Name</p>
      <p>{{ userData.lastName }}</p>
    </div>

    <div class="div-data">
      <p>Email</p>
      <p>{{ userData.email }}</p>
    </div>

    <div class="div-data">
      <p>Phone number</p>
      <p>{{ userData.phoneNumber }}</p>
    </div>

    <div class="flex justify-center items-center mt-3">
      <hr class="my-3 w-full bg-dkgray" />
    </div>

    <div class="div-data">
      <p>Admin Role</p>
      <p class="blue">{{ userData.roleName }}</p>
    </div>

    <div class="div-data">
      <p class="w-3/12">MDA(s)</p>
      <p class="blue text-xxs w-9/12 break-words text-right" :title="mdaNames">
        {{ mdaNames }}
      </p>
    </div>

    <div class="div-data">
      <p class="w-3/12">Permission(s)</p>
      <p
        class="blue text-xxs w-9/12 break-words text-right"
        :title="permissionName"
      >
        {{ permissionName }}
      </p>
    </div>

    <div class="div-data">
      <p>Role Created By</p>
      <p>
        {{ userData.createdBy }}
      </p>
    </div>

    <div class="div-data">
      <p>User created on</p>
      <p>
        {{ userData.createdAt | moment("Do MMMM YYYY") }}
      </p>
    </div>

    <div class="flex justify-center items-center mt-3">
      <hr class="my-3 w-full bg-dkgray" />
    </div>
  </section>
</template>

<script>
export default {
  name: "ProfileDetails",
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPersonal: {
      type: Boolean,
      required: true,
    },
    permissionName: {
      type: String,
      required: true,
    },
    mdaNames: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      profilePhoto: null,
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadProfilePicture(file);
      }
    },
    async uploadProfilePicture(file) {
      this.profilePhoto = file;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.profilePhoto = event.target.result;
      };
      reader.readAsDataURL(file);
      await this.changeImg();
    },
    async changeImg() {
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();
      formData.append("profilePhoto", this.profilePhoto);
      try {
        this.getting = true;
        const res = await this.$http.post(
          "/users/profile/upload-picture",
          formData,
          { headers }
        );
        this.getting = false;
        if (!res) {
          return;
        }
        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.image-upload > input {
  display: none;
}
.div-data {
  @apply border-2 border-litgray bg-white w-full mt-4 rounded-lg flex justify-between items-center px-2 lg:px-6 py-2;
}
.div-data > p:first-child {
  @apply text-dkgray italic;
}
.div-data > p:last-child {
  @apply text-dkgray font-bold;
}
.blue {
  color: #0090ff !important;
}
.text-xxs {
  font-size: 12px !important;
}
</style>
