<template>
  <div class="w-full px-5 lg:px-10 mt-5">
    <div class="flex justify-between items-center mb-5">
      <h2 class="text-dktgray text-2xl mb-5">New Staff Records</h2>
    </div>

    <api-table
      apiUrl="/signatories/all-new-staff"
      :fields="fields"
      :show-page="true"
      :show-search="true"
      placeholder="Search by name, phone number or email"
    >
      <template slot="signatureStatus" slot-scope="props">
        <span
          v-if="props.rowData.signatureStatus === 'pending'"
          class="text-gray-500 font-bold uppercase text-xs"
        >
          {{ props.rowData.signatureStatus }}
        </span>
        <span
          v-else-if="props.rowData.signatureStatus === 'requested'"
          class="text-yellow-600 font-bold uppercase text-xs"
        >
          {{ props.rowData.signatureStatus }}
        </span>

        <span v-else class="text-oneGreen font-bold uppercase">
          {{ props.rowData.signatureStatus }}
        </span>
      </template>

      <template slot="action" slot-scope="props">
        <span class="flex gap-2 pr-2">
          <!-- v-can="'get-one-user'" -->
          <button
            @click="viewPrintout(props.rowData.id)"
            class="bg-oneGreen text-center text-white text-xs rounded py-1 px-3"
          >
            View
          </button>
        </span>
      </template>
    </api-table>

    <ActionModal :open="openPrintout" @close="openPrintout = false">
      <template #content>
        <section id="printoutt">
          <StaffSummary :staff-data="staffData" :getting="getting" />
        </section>
      </template>

      <template #action>
        <button
          v-if="signStatus === 'pending'"
          @click="editStaff"
          class="border border-dkgray text-sm p-3 rounded-lg"
        >
          Edit
        </button>

        <button
          @click="printOut"
          class="border border-dkgray text-sm p-3 rounded-lg"
        >
          Print
        </button>

        <button
          v-if="signStatus === 'signed'"
          @click="viewGen()"
          class="bg-idBlued text-sm p-3 rounded-lg text-white"
        >
          View GEN 69
        </button>

        <p
          v-else-if="signStatus === 'requested'"
          class="bg-gray-500 text-sm py-2 px-3 rounded-lg text-white"
        >
          Awaiting GEN 69 Signature
        </p>

        <button
          v-if="signStatus === 'pending'"
          @click="requestGen()"
          :disabled="requesting"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white flex justify-center gap-3"
        >
          <span>Request GEN 69</span>
          <spinner v-if="requesting" class="w-5 h-5"></spinner>
        </button>
      </template>
    </ActionModal>

    <ActionModal :open="openGen" @close="openGen = false">
      <template #content>
        <section id="genPrintout">
          <Gen69 :staffData="staffData" />
        </section>
      </template>

      <template #action>
        <button
          @click="printOutGen"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white"
        >
          Print
        </button>
      </template>
    </ActionModal>
  </div>
</template>

<script>
import Vue from "vue";
import ActionModal from "@/components/admin/records/ActionModal.vue";
import StaffSummary from "@/components/admin/staff/StaffSummary.vue";
import Gen69 from "@/components/admin/staff/Gen69.vue";
export default {
  name: "NewStaffRecords",

  components: {
    ActionModal,
    StaffSummary,
    Gen69,
  },

  data() {
    // let sv = "hidden lg:table-cell";
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      getting: false,
      requesting: false,
      openPrintout: false,
      openGen: false,
      staffData: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "staffName",
          title: "Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 uppercase`,
        },
        {
          name: "mdaName",
          title: "MDA",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        // {
        //   name: "verificationCode",
        //   title: "Verification Number",
        //   titleClass: `${tc} text-left pl-4 `,
        //   dataClass: `${dc} text-left pl-4`,
        // },
        {
          name: "cretedAt",
          title: "Date Created",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "signatureStatus",
          title: "GEN 69 Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 uppercase`,
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  computed: {
    signStatus() {
      return this.staffData?.signatureStatus;
    },
  },

  methods: {
    onCellClicked() {
      this.$router.push({ name: "Bio Data" });
    },

    async viewPrintout(dt) {
      try {
        this.getting = true;
        this.openGen = false;
        this.openPrintout = true;
        const res = await this.$http.post(`service-staff/print-out/${dt}`, {
          baseUrl: `${window.location.origin}/gen69`,
        });

        this.getting = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.staffData = data.data;
      } catch (error) {
        this.getting = false;
        console.log({ error });
      }
    },

    viewGen() {
      this.openPrintout = false;
      this.openGen = true;
    },

    editStaff() {
      this.$store.commit("admin/saveNewStaff", this.staffData);
      this.$router.push({ name: "New Staff Bio Data" });
    },

    printOut() {
      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("printoutt").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );

      mywindow.document.write("</head><body>");
      mywindow.document.write("<div style='margin-top:20px;'></div>");
      mywindow.document.write(divContents);
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1000);
    },
    printOutGen() {
      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("genPrintout").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );
      mywindow.document.write("</head><body>");

      const links = document.querySelectorAll("style");
      links.forEach(function (link) {
        mywindow.document.head.appendChild(link.cloneNode(true));
      });

      mywindow.document.write("<div style='margin-top:20px;'></div>");
      mywindow.document.write(divContents);
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1000);
    },

    async requestGen() {
      try {
        this.requesting = true;

        const res = await this.$http.post(
          `/service-staff/request-signatory/${this.staffData.id}`
        );

        this.requesting = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.staffData.signatureStatus = "requested";
        this.$events.fire("table-refresh");
      } catch (error) {
        this.requesting = false;
      }
    },
  },
};
</script>
