<template>
  <div class="py-9 px-28 w-full m-auto">
    <div class="flex items-center justify-between">
      <h2 class="text-dkgray text-2xl font-bold">All users</h2>

      <router-link
        v-can="'create-user'"
        :to="{ name: 'Admin Create User' }"
        class="bg-idBlued px-3 py-2 rounded flex justify-between items-center text-white text-xs font-mullish"
      >
        <img src="@/assets/images/icons/add-user.svg" alt="" />
        <span class="ml-2">Create new user</span>
      </router-link>
    </div>

    <div class="mt-6">
      <api-table
        apiUrl="/users"
        :fields="fields"
        :show-page="true"
        :show-search="true"
        placeholder="Search by name, phone number or email"
      >
        <template slot="name" slot-scope="props">
          <div class="">
            <p
              v-if="props.rowData.firstName || props.rowData.lastName"
              class="font-bold text-dkgray uppercase"
            >
              {{ props.rowData.firstName }} {{ props.rowData.lastName }}
            </p>
            <p v-else class="font-bold text-dkgray">New User</p>
            <p class="text-xs text-mdgray">{{ props.rowData.email }}</p>
          </div>
        </template>

        <template slot="status" slot-scope="props">
          <span v-if="!props.rowData.onboarded" class="text-gray-500 font-bold">
            Not Onboarded
          </span>
          <span
            v-else-if="props.rowData.isActive"
            class="text-oneGreen font-bold"
          >
            Active
          </span>
          <span v-else class="text-red-700 font-bold"> Not Active </span>
        </template>

        <template slot="action" slot-scope="props">
          <router-link
            v-can="'get-one-user'"
            :to="{ name: 'Admin Profile', params: { id: props.rowData.id } }"
            class="bg-oneGreen text-center text-white text-xs rounded py-1 px-2 mr-2"
          >
            View
          </router-link>

          <button
            v-can="'delete-user'"
            v-if="props.rowData.isActive"
            @click="setUser('Deactivate', props.rowData.id)"
            class="bg-dkgray text-white text-xs rounded py-1 px-2 w-[76px]"
          >
            Deactivate
          </button>

          <button
            v-can="'update-user'"
            v-else-if="props.rowData.onboarded && !props.rowData.isActive"
            @click="setUser('Activate', props.rowData.id)"
            class="bg-blue-500 text-white text-xs rounded py-1 px-2 w-[76px]"
          >
            Activate
          </button>
        </template>
      </api-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Users",

  data() {
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "name",
          title: "User Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "status",
          title: "Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "roleName",
          title: "Roles",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 text-blue-500 font-bold`,
        },
        {
          name: "action",
          title: "Actions",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  methods: {
    async setUser(acn, id) {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: `Are you sure you want to ${acn} this user?`,
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        const res = await this.$http.get(`users/${id}/toggle-status`);

        if (!res) {
          return;
        }

        this.$events.fire("table-refresh");

        this.$swal({
          icon: "success",
          text: `${acn} Successfully`,
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>
