var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_vm._m(0),_c('api-table',{attrs:{"apiUrl":`/setup/office/${this.$route.params.id}`,"fields":_vm.fields,"show-page":true,"show-search":true,"placeholder":"Search by name, phone number or email"},scopedSlots:_vm._u([{key:"name",fn:function(props){return [_vm._v(" "+_vm._s(props.rowData.firstName)+" "+_vm._s(props.rowData.middleName)+" "+_vm._s(props.rowData.lastName)+" ")]}},{key:"action",fn:function(props){return [_c('span',{staticClass:"flex gap-4 pr-2"},[_c('button',{staticClass:"px-4 rounded bg-red-500 text-white text-xs",on:{"click":function($event){return _vm.deleteStaff([props.rowData.employeeId])}}},[_vm._v(" Remove ")]),_c('router-link',{staticClass:"bg-oneGreen text-center text-white text-xs rounded py-1 px-3",attrs:{"to":{
            name: 'Staff Clockin History',
            params: { lid: _vm.$route.params.id, sid: props.rowData.employeeId },
          }}},[_vm._v(" Clockin Stats ")]),_c('router-link',{staticClass:"bg-idBlued text-center text-white text-xs rounded py-1 px-3",attrs:{"to":{
            name: 'List Staff Clockin History',
            params: {
              sid: props.rowData.employeeId,
              sname: props.rowData.firstName + ' ' + props.rowData.lastName,
            },
          }}},[_vm._v(" View History ")])],1)]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between items-center mb-5"},[_c('h2',{staticClass:"text-dktgray text-2xl mb-5"},[_vm._v("Location Staff Records")])])
}]

export { render, staticRenderFns }