<template>
  <div v-if="getting" class="flex justify-center items-center w-full h-screen">
    <spinner class="h-20 w-20" color="ansGreen"></spinner>
  </div>

  <div v-else class="max-w-[750px] mx-auto pt-5 pb-14">
    <div class="div-data">
      <p>MDA</p>
      <p>{{ location.ministry }}</p>
    </div>

    <div class="div-data">
      <p>Office Name</p>
      <p class="uppercase">{{ location.officeName }}</p>
    </div>

    <div class="flex justify-center items-center mt-3">
      <hr class="my-3 w-full bg-dkgray" />
    </div>

    <div class="div-data">
      <p>Setup By</p>
      <p>{{ location.createdBy }}</p>
    </div>

    <div class="div-data">
      <p>Admin Role</p>
      <p>{{ location.adminRole }}</p>
    </div>

    <div class="div-data">
      <p>Date of Setup</p>
      <p>{{ location.createdAt | moment("MMMM Do, YYYY") }}</p>
    </div>

    <div class="flex justify-center items-center mt-3">
      <hr class="my-3 w-full bg-dkgray" />
    </div>

    <div class="flex justify-between gap-9 mt-4">
      <div
        class="p-5 rounded flex flex-col justify-center items-center gap-3 bg-litergray w-1/2"
      >
        <h6 class="text-dkgray font-bold">Location Coordinates</h6>
        <p
          class="border border-litgray w-10/12 text-center rounded-lg py-1 text-sm"
        >
          {{ location.cordinates }}
        </p>
      </div>
      <div
        class="p-5 rounded flex flex-col justify-center items-center gap-3 bg-sideBlack w-1/2"
      >
        <h6 class="text-white font-bold">Number of Assigned Staff</h6>
        <p
          class="border border-white w-10/12 text-white text-center rounded-lg py-1 text-sm"
        >
          {{ location.noOfStaff }}
        </p>
      </div>
    </div>

    <div class="mt-4">
      <gmap-map
        :zoom="14"
        :center="center"
        class="w-full h-[400px] rounded mt-6 border border-litgray"
        style=""
      >
        <gmap-marker
          :position="location.position"
          @click="center = location.position"
        ></gmap-marker>
      </gmap-map>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationDetails",

  data() {
    return {
      getting: false,
      location: {
        ministry: null,
        officeName: null,
        officeAddress: null,
        createdBy: null,
        adminRole: null,
        createdAt: null,
        cordinates: null,
        noOfStaff: 0,
        position: null,
      },
      center: {
        lat: 8.96596,
        lng: 7.45433,
      },
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          `setup/location/${this.$route.params.id}`
        );

        if (!res) {
          this.getting = false;
          return;
        }

        const { data } = res;

        this.location.officeName = data.data.approvedOfficeName;
        this.location.ministry = data.data.mdaName;
        this.location.officeAddress = data.data.officeAddress;
        this.location.createdBy = data.data.userName;
        this.location.createdAt = data.data.createdAt;
        this.location.adminRole = data.data.userRole;
        this.location.noOfStaff = data.data.totalStaff;

        const [lng, lat] = data.data.officeLocation.coordinates;
        this.location.cordinates = `LAT: ${lat.toFixed(5)}, LNG: ${lng.toFixed(
          5
        )}`;

        this.center = { lat, lng };
        this.location.position = this.center;
        this.getting = false;
      } catch (err) {
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.div-data {
  @apply border-2 border-litgray bg-white w-full mt-4 rounded-lg flex justify-between items-center px-2 lg:px-6 py-2;
}
.div-data > p:first-child {
  @apply text-dkgray italic;
}
.div-data > p:last-child {
  @apply text-dkgray font-bold;
}
</style>
