var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_vm._m(0),_c('api-table',{attrs:{"apiUrl":"/records","fields":_vm.fields,"show-page":true,"show-search":true,"placeholder":"Search by name, phone number or email"},scopedSlots:_vm._u([{key:"approvalStatus",fn:function(props){return [(
          props.rowData.approvalStatus === 'pending' ||
          props.rowData.approvalStep < 2
        )?_c('span',{staticClass:"text-gray-500 font-bold uppercase"},[_vm._v(" Pending ")]):_c('span',{staticClass:"text-oneGreen font-bold uppercase"},[_vm._v(" "+_vm._s(props.rowData.approvalStatus)+" ")])]}},{key:"action",fn:function(props){return [_c('span',{staticClass:"flex gap-2 pr-2"},[(
            props.rowData.verificationStepStatus === 'completed' &&
            !props.rowData.accredited
          )?_c('router-link',{staticClass:"bg-purple-500 text-center text-white text-xs rounded py-1 px-2",attrs:{"to":{
            name: 'Admin Accreditation Start',
            params: { id: props.rowData.verificationCode },
          }}},[_vm._v(" Accredit ")]):(props.rowData.accredited)?_c('router-link',{staticClass:"bg-oneGreen text-center text-white text-xs rounded py-1 px-3",attrs:{"to":{
            name: 'Admin Bio Data',
            params: { id: props.rowData.id },
            query: { xwd: 'iopyt' },
          }}},[_vm._v(" View ")]):(props.rowData.submissionDate)?_c('router-link',{staticClass:"bg-gray-700 text-center text-white text-xs rounded py-1 px-3",attrs:{"to":{
            name: 'Admin Bio Data',
            params: { id: props.rowData.id },
            query: { xwd: 'iopyt' },
          }}},[_vm._v(" View pending ")]):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between items-center mb-5"},[_c('h2',{staticClass:"text-dktgray text-2xl mb-5"},[_vm._v("All Records")])])
}]

export { render, staticRenderFns }