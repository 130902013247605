<template>
  <div class="font-mulish">
    <section
      class="w-full bg-litergray flex md:justify-between items-center px-3 lg:px-6 py-2 flex-col md:flex-row sticky overflow-x-scroll top-0 right-0 left-0 z-100 shadow-sm"
    >
      <div class="flex items-center gap-2">
        <button
          @click="$router.go(-1)"
          class="px-3 py-1 text-gray-500 text-center text-lg"
        >
          &#9204;
        </button>
        <h4 class="font-bold text-xl text-dkgray">Staff Clock-in History</h4>
      </div>
    </section>

    <section
      class="flex justify-between items-start gap-7 section mt-14 min-h-[460px]"
    >
      <div class="w-full lg:w-7/12 rounded-lg h-[460px] p-px">
        <!-- hide-view-selector -->
        <vue-cal
          class="vuecal--green-theme rounded-lg"
          small
          :time="true"
          active-view="month"
          :disable-views="['years', 'year']"
          :events="events"
          events-on-month-view="short"
          :show-all-day-events="true"
          @view-change="fetchChangeEvents($event)"
        >
        </vue-cal>
      </div>

      <div class="w-full lg:w-5/12 p-4 border border-mdgray rounded-lg">
        <h6 class="text-xs font-bold">Last Clock-In Details</h6>

        <div
          v-if="loading"
          class="min-h-[400px] flex items-center justify-center"
        >
          <spinner class="w-12 h-12" color="gray-500"></spinner>
        </div>

        <template v-else>
          <div class="two-info gray">
            <p>Clock-in Time</p>
            <p>
              {{
                lastClockInDetails.lastClockInTime
                  | moment("YYYY-MM-DD HH:MM:SS a")
              }}
            </p>
          </div>

          <div class="two-info gray">
            <p>Clock-Out Time</p>
            <p>
              {{
                lastClockInDetails.lastClockOutTime
                  | moment("YYYY-MM-DD HH:MM:SS a")
              }}
            </p>
          </div>

          <div class="two-info gray">
            <p>Location status</p>
            <p>{{ lastClockInDetails.locationStatus }}</p>
          </div>

          <div class="two-info gray">
            <p>Address</p>
            <p
              class="truncate w-2/3"
              title="IPhone 12 pro IPhone 12 pro IPhone 12 pro IPhone 12 pro IPhone 12
              pro IPhone 12 pro"
            >
              {{ lastClockInDetails.address }}
            </p>
          </div>

          <h6 class="text-xs font-bold mt-4">Clock-ins Stats for the month</h6>

          <div class="two-info gray">
            <p>Total</p>
            <p>{{ clockInStatsForTheMonth.totalClockIns }}</p>
          </div>

          <div class="two-info bg-green-100">
            <p>Prompt Clock-ins</p>
            <p>{{ clockInStatsForTheMonth.promptClockIns }}</p>
          </div>

          <div class="two-info bg-yellow-100">
            <p>Late Clock-ins</p>
            <p>{{ clockInStatsForTheMonth.lateClockIns }}</p>
          </div>

          <div class="two-info gray">
            <p>Number of Clock-ins</p>
            <p>{{ clockInStatsForTheMonth.totalClockIns }}</p>
          </div>

          <div class="two-info gray">
            <p>Number of Clock-outs</p>
            <p>{{ clockInStatsForTheMonth.totalClockOuts }}</p>
          </div>

          <!-- <div class="two-info bg-red-200">
            <p>Absent Days</p>
            <p>1</p>
          </div> -->
        </template>
      </div>
    </section>

    <section class="section mt-10">
      <div class="flex justify-center items-center mt-3">
        <hr class="mb-3 w-full bg-dkgray" />
      </div>

      <div
        v-if="loading"
        class="min-h-[300px] flex items-center justify-center"
      >
        <spinner class="w-12 h-12" color="gray-500"></spinner>
      </div>

      <template v-else>
        <div class="div-data">
          <p>First Name</p>
          <p>{{ staff.firstName }}</p>
        </div>

        <div class="div-data">
          <p>Last Name</p>
          <p>{{ staff.lastName }}</p>
        </div>

        <div class="div-data">
          <p>Email</p>
          <p>{{ staff.email }}</p>
        </div>

        <div class="div-data">
          <p>Phone Number</p>
          <p>{{ staff.phoneNumber }}</p>
        </div>

        <div class="div-data">
          <p>Level</p>
          <p>{{ staff.salaryGradeLevel }}</p>
        </div>

        <div class="div-data">
          <p>MDA</p>
          <p>{{ staff.mdaName }}</p>
        </div>

        <div class="flex justify-center items-center mt-3">
          <hr class="my-3 w-full bg-dkgray" />
        </div>

        <div class="div-data">
          <p>Location status</p>
          <p class="text-blue-300">{{ staff.locationStatus }}</p>
        </div>

        <div class="div-data">
          <p>Office Name</p>
          <p>{{ staff.approvedOfficeName }}</p>
        </div>

        <div class="div-data">
          <p>Office Official Address</p>
          <p>{{ staff.address }}</p>
        </div>

        <div class="flex justify-center items-center mt-3">
          <hr class="my-3 w-full bg-dkgray" />
        </div>

        <div class="div-data">
          <p>Assigned on</p>
          <p>{{ staff.assignedOn | moment("MMMM Do YYYY") }}</p>
        </div>

        <div class="div-data">
          <p>Assigned by</p>
          <p>{{ staff.assignedBy }}</p>
        </div>

        <div class="div-data">
          <p>Admin Role</p>
          <p>{{ staff.userRole }}</p>
        </div>
      </template>

      <div class="flex justify-center items-center mt-3">
        <hr class="my-3 w-full bg-dkgray" />
      </div>
    </section>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
export default {
  name: "StaffHistory",

  components: {
    VueCal,
  },

  data() {
    return {
      loading: false,
      staff: {
        name: "Abengowe Clement Chuwuemeka",
        firstName: "Abengowe",
        lastName: "Clement Chuwuemeka",
        email: "Johnokor2016@gmail.com",
        phoneNumber: "09036587946",
        salaryGradeLevel: "4",
        mdaName: "Clement Chuwuemeka",
        locationStatus: "Onsite",
        approvedOfficeName: "Ministry of Works Uhuahia",
        address:
          "Prince and Princess Estate, House 46 2nd Dr, Duboyi District 420053, Abuja, Federal Capital Territory",
        noOClockins: "54",
        officeCordinates: "41°24'12.2'N; 2°10'26.5'E",
        assignedOn: "August 12th, 2023",
        assignedBy: "John Okor",
        userRole: "Super Admin",
      },
      events: [
        {
          class: "late",
          createdAt: "2024-03-25 16:04",
          end: "2024-03-25 17:22",
          start: "2024-03-25 17:04",
          title:
            "39 2nd Dr, Kaura, Abuja 900103, Federal Capital Territory, Nigeria",
          updatedAt: "2024-03-25 16:22",
        },
      ],
      clockInStatsForTheMonth: {
        lateClockIns: 1,
        promptClockIns: 1,
        totalClockIns: 2,
        totalClockOuts: 0,
      },
      lastClockInDetails: {
        address:
          "House 46 2nd Dr, Duboyi District, Abuja 420053, Federal Capital Territory, Nigeria",
        lastClockInTime: "08:59:34",
        lastClockOutTime: null,
        locationStatus: "Onsite",
      },
    };
  },

  async created() {
    const startDate = this.moment().startOf("month").format("YYYY-MM-DD");
    const endDate = this.moment().endOf("month").format("YYYY-MM-DD");
    await this.getStaffClockin(startDate, endDate);
  },

  methods: {
    async fetchChangeEvents(events) {
      if (events.view !== "month") return;
      const startDate = this.moment(events.startDate).format("YYYY-MM-DD");
      const endDate = this.moment(events.endDate).format("YYYY-MM-DD");
      await this.getStaffClockin(startDate, endDate);
    },

    async getStaffClockin(startDate, endDate) {
      this.loading = true;

      try {
        const res = await this.$http.get(
          `/setup/office/${this.$route.params.lid}/clockin-record/${this.$route.params.sid}?startDate=${startDate}&endDate=${endDate}`
        );

        if (!res) {
          this.loading = false;
          return;
        }
        const { data } = res;

        this.staff = data.data;
        this.events = data.data.calendarData;
        this.clockInStatsForTheMonth = data.data.clockInStatsForTheMonth;
        this.lastClockInDetails = data.data.lastClockInDetails;
        this.loading = false;
      } catch (error) {
        console.log({ error });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.section {
  @apply w-full max-w-[800px] mx-auto;
}
.two-info {
  @apply flex justify-between items-center mt-2 py-2 px-1 border border-litgray rounded-lg text-xs text-dkgray;
}
.two-info.gray {
  @apply bg-litergray;
}
.two-info p:first-of-type {
  @apply italic font-medium;
}
.two-info p:last-of-type {
  @apply font-bold text-right;
}
.div-data {
  @apply border-2 border-litgray bg-white w-full mt-4 rounded-lg flex justify-between items-center px-2 lg:px-6 py-2;
}
.div-data > p:first-child {
  @apply text-dkgray italic w-4/12;
}
.div-data > p:last-child {
  @apply text-dkgray font-bold text-right;
}
</style>
