<template>
  <div>
    <div class="relative border rounded-lg bg-[#fff]">
      <div class="cursor-pointer w-full" @click="toggleDropdown">
        <slot></slot>
      </div>

      <div
        v-if="showHideDropdown"
        :class="`w-full ${
          showHideDropdown ? 'absolute -bottom-15 left-0' : ''
        }  shadow rounded mt-1 z-100 bg-[#fff]`"
      >
        <div class="w-full p-3 bg-[#F9F9F9]">
          <FilterBar
            @filter-search="filterList"
            placeholder="Search"
            width="w-full"
          />
          <div
            class="border rounded p-1 px-2 mt-1 flex justify-between items-center bg-[#fff]"
          >
            <label class="text-[10px]" for="filter"> Select All</label>
            <input
              @click="selectAll($event)"
              :checked="selectedList.length === listHolder.length"
              type="checkbox"
              id="filter"
            />
          </div>
        </div>
        <hr />
        <div class="max-h-[200px] bg-[#fff] overflow-y-scroll mdas">
          <div
            v-for="(val, i) in listHolder"
            :key="i + 1"
            class="py-1.5 px-5 text-[8px] w-full flex justify-between items-center"
          >
            <label class="text-[10px]" :for="`mda${i + 1}}`">
              {{ val.name }}</label
            >
            <input
              v-model="selectedList"
              :value="val._id || val.id"
              type="checkbox"
              @click="addList(val._id || val.id, $event)"
              :id="`mda${i + 1}}`"
            />
          </div>
        </div>
        <div class="p-3 flex justify-between items-center">
          <span class="font-bold text-[10px]"
            >{{ selectedList.length }} Selected</span
          >
          <div class="">
            <button
              type="button"
              @click="resetFilter"
              :disabled="listHolder.length === list.length"
              class="bg-dkgray py-1 px-3 text-sm text-[#fff] rounded mr-1"
            >
              Reset
            </button>
            <button
              type="button"
              @click="closeDropdown"
              class="bg-oneGreen py-1 text-sm px-3 text-[#fff] rounded"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterBar from "@/components/table/FilterBar.vue";

export default {
  name: "SearchableSelect",
  props: {
    list: {
      type: Array,
      default: new Array([]),
      required: true,
    },
    prevValues: {
      type: Array,
      default: new Array([]),
    },
  },
  components: {
    FilterBar,
  },
  data() {
    return {
      selectedList: [],
      listHolder: [],
      showHideDropdown: false,
    };
  },

  computed: {
    lists() {
      return this.list.map((el) => el._id || el.id);
    },
  },

  mounted() {
    this.listHolder = this.list.sort();
    this.selectedList = [...this.prevValues];
  },

  methods: {
    toggleDropdown() {
      this.showHideDropdown = !this.showHideDropdown;
    },
    isEven(i) {
      return i % 2 === 0;
    },

    addList(val, e) {
      if (e.target.checked) {
        const payload = [val, ...this.selectedList];
        this.$emit("add-list", payload);

        this.selectedList = payload;
      } else {
        const payload = this.selectedList.filter((el) => el !== val);
        this.$emit("add-list", payload);

        this.selectedList = payload;
      }
    },

    closeDropdown() {
      this.$emit("add-all", this.selectedList);
      this.showHideDropdown = false;
    },

    filterList(val) {
      this.listHolder = this.list.filter((el) =>
        el.name.includes(val.toUpperCase())
      );
    },

    resetFilter() {
      this.listHolder = this.list;
    },

    selectAll(e) {
      if (e.target.checked) {
        this.selectedList = this.lists;
        this.$emit("add-list", this.selectedList);
      } else {
        this.selectedList = [];
        this.$emit("add-list", this.selectedList);
      }
    },
  },
};
</script>

<style scoped>
.input-search[type="text"] {
  @apply text-sm outline-none border-none focus:outline-none focus:border-none w-full;
}
.input-search::placeholder {
  @apply text-xs text-[#8D8D8D] italic font-normal font-mulish;
}
.mdas:nth-child(even) {
  background: "red" !important;
}

input[type="checkbox"] {
  accent-color: #1a8103;
}
</style>
