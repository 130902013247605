<template>
  <div>
    <section
      class="w-full sticky bg-litergray flex md:justify-between items-center px-3 lg:px-6 py-2 flex-col md:flex-row overflow-x-scroll top-0 right-0 left-0 z-100 shadow-sm"
    >
      <div class="flex items-center gap-2">
        <button
          @click="$router.push({ name: 'Admin Dashboard' })"
          class="px-3 py-1 text-gray-500 text-center text-lg"
        >
          &#9204;
        </button>
        <h4 class="font-bold text-xl text-dkgray">Staff Clock- In Setup</h4>
      </div>
    </section>

    <section v-if="step === 1">
      <SetupStart @done="doneStep1" :operator="operator" />
    </section>

    <section v-if="step === 2 && userData">
      <SetupCompare :userData="userData" @prev="prev" />
    </section>
  </div>
</template>

<script>
import SetupStart from "@/components/admin/clockin/operator/SetupStart.vue";
import SetupCompare from "@/components/admin/clockin/operator/SetupCompare.vue";
const defaultData = null;

export default {
  name: "SetupClockIn",

  data() {
    return {
      userData: defaultData,
      step: 1,
      operator: null,
    };
  },

  components: {
    SetupStart,
    SetupCompare,
  },

  async created() {
    try {
      const res = await this.$http.get("/profile");

      this.proceeding = false;
      if (!res) {
        return;
      }

      const { data } = res;

      this.operator = data?.data;
    } catch (error) {
      console.log({ error });
    }
  },

  methods: {
    next() {
      this.step = this.step + 1;
    },
    prev() {
      this.step = this.step - 1;
      this.userData = defaultData;
    },
    doneStep1(d) {
      this.userData = d;
      this.next();
    },
  },
};
</script>

<style scoped></style>
