<template>
  <div class="relative">
    <div class="">
      <input v-if="showPassword" type="text" class="input" v-model="password" />
      <input v-else type="password" class="input" v-model="password" />
    </div>

    <div class="absolute right-2 top-2">
      <button
        type="button"
        class="button"
        @click="showPassword = !showPassword"
      >
        <span class="icon is-small is-right">
          <img
            v-if="showPassword"
            src="@/assets/images/icons/eye-on.svg"
            alt=""
          />
          <img v-else src="@/assets/images/icons/eye-off.svg" alt="" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewHidePassword",

  props: ["value"],

  watch: {
    password(nv) {
      this.$emit("input", nv);
    },
  },

  data() {
    return {
      showPassword: false,
      password: null,
    };
  },
};
</script>

<style scoped>
/* input {
  height: 40px;
} */
</style>
