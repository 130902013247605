<template>
  <div class="w-full">
    <div class="flex justify-between items-center mb-5">
      <h2 class="text-dktgray text-2xl mb-5">Clock-in Locations</h2>
    </div>

    <api-table
      apiUrl="/setup/locations"
      :fields="fields"
      :show-page="true"
      :show-search="true"
      placeholder="Search by name, phone number or email"
    >
      <template slot="action" slot-scope="props">
        <span class="flex gap-2 pr-2">
          <button
            v-can="'get-location-details'"
            @click="storeLocation(props.rowData)"
            class="bg-oneGreen text-center text-white text-xs rounded py-1 px-3"
          >
            View
          </button>
        </span>
      </template>
    </api-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "AllLocations",

  data() {
    // let sv = "hidden lg:table-cell";
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "approvedOfficeName",
          title: "Office Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 uppercase`,
        },
        {
          name: "officeAddress",
          title: "Office Address",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "mdaName",
          title: "MDA",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "totalStaff",
          title: "Number of Staff",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  methods: {
    onCellClicked() {
      this.$router.push({ name: "Bio Data" });
    },
    storeLocation(d) {
      this.$store.commit("admin/saveData", {
        key: "locationName",
        data: d.approvedOfficeName,
      });
      this.$router.push({
        name: "Location Details",
        params: { id: d.id },
      });
    },
  },
};
</script>
