<template>
  <div>
    <div
      v-if="proceeding"
      class="flex items-center h-full w-full justify-center"
    >
      <spinner
        class="h-[150px] w-[150px]"
        color="green"
        v-if="proceeding"
      ></spinner>
    </div>
    <div v-else>
      <section
        class="w-full sticky bg-litergray flex md:justify-between items-center px-3 lg:px-6 py-2 flex-col md:flex-row overflow-x-scroll top-0 right-0 left-0 z-100 shadow-sm"
      >
        <div class="flex items-center gap-2">
          <button
            @click="$router.go(-1)"
            class="px-3 py-1 text-gray-500 text-center text-lg"
          >
            &#9204;
          </button>
          <h4 class="font-bold text-xl text-dkgray">
            {{ signatoryData.signatureTitle }}
          </h4>
        </div>
      </section>
      <div class="my-10 py-10 px-4 m-auto w-full lg:w-[700px] space-y-8">
        <BiometricCard :userData="userData" class="bg-[#F0FFF0]" />
        <form @submit.prevent="proceed">
          <div>
            <label for="officerName">
              Name of Officer<span class="req">*</span>
            </label>
            <input
              readonly
              id="officerName"
              v-model="officerName"
              type="text"
              v-uppercase
              class="input"
            />
          </div>
          <div>
            <label for="officerPosition">
              Position Of Officer<span class="req">*</span>
            </label>
            <input
              v-model="form.signatoryOfficerPosition"
              id="officerPosition"
              type="text"
              readonly
              v-uppercase
              class="input"
            />
          </div>
          <div>
            <label for="signatureTitle">
              Signature Title<span class="req">*</span>
            </label>
            <input
              v-model="form.signatureTitle"
              id="signatureTitle"
              type="text"
              readonly
              v-uppercase
              class="input"
            />
          </div>

          <div>
            <label for="mda"> Recruiting MDA<span class="req">*</span> </label>
            <v-select
              id="mda"
              disabled
              label="name"
              v-model="form.recrutingMdaId"
              v-uppercase
              :reduce="(b) => b.id"
              :options="$store.state.staff.mdas"
              placeholder="-- select --"
            ></v-select>
          </div>

          <div>
            <label for="staffType">
              Sector (Staff Type)<span class="req">*</span>
            </label>
            <v-select
              id="staffType"
              label="name"
              disabled
              v-model="form.sectorStaffTypeId"
              :reduce="(b) => b._id"
              :options="$store.state.admin.staffTypes"
              placeholder="-- select --"
            ></v-select>
          </div>

          <div class="text-black">
            <label>
              MDA where the Signature Covers <span class="req">*</span>
            </label>
            <multiselect
              v-model="form.approvedSignatoryMdaId"
              class="text-black"
              :options="$store.state.staff.mdas"
              :searchable="true"
              :close-on-select="false"
              :multiple="true"
              label="name"
              disabled
              track-by="_id"
              :show-labels="false"
              placeholder="-- select --"
            >
            </multiselect>
          </div>
          <div>
            <label> Grades <span class="req">*</span> </label>
            <multiselect
              class="text-black"
              v-model="form.approvedSignatoryGradeId"
              id="grade"
              :reduce="(b) => b.grade"
              :options="$store.state.staff.salaryGradeLevels"
              :searchable="true"
              :close-on-select="false"
              :multiple="true"
              label="grade"
              disabled
              track-by="_id"
              :show-labels="false"
              placeholder="-- select --"
            >
            </multiselect>
          </div>
          <div>
            <label> Signature <span class="req"></span> </label>
            <div class="w-full py-1 px-3 border-2 border-dashed rounded-lg">
              <img
                class="h-[40px] w-[40px]"
                :src="signatoryData.signature"
                alt="Signature"
              />
            </div>
          </div>
          <div>
            <label for="authenticationMethod">
              Authentication Method <span class="req">*</span>
            </label>
            <v-select
              id="authenticationMethod"
              disabled
              label="name"
              v-model="form.authenticationMethod"
              :reduce="(b) => b.value"
              :options="authTypes"
              placeholder="-- select --"
            ></v-select>
          </div>
        </form>

        <div
          class="my-4 flex justify-between items-center rounded-lg bg-litergray p-6"
        >
          <button
            @click="diactivateSignatory"
            class="bg-[#FFECEC] text-[#484848]"
            type="submit"
          >
            <span>Deactivate Signature</span>
            <spinner v-if="diactivating"></spinner>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BiometricCard from "@/components/admin/clockin/operator/BiometricCard.vue";
// import UploadInput from "@/components/form/UploadInput.vue";
export default {
  name: "ViewSignatureSetup",
  components: { BiometricCard },
  data() {
    return {
      form: {
        baseUrl: `${window.location.origin}/onboard`,
        signatoryOfficerPosition: null,
        signatureTitle: null,
        recrutingMdaId: null,
        sectorStaffTypeId: null,
        approvedSignatoryMdaId: [],
        approvedSignatoryGradeId: [],
        signature: null,
        authenticationMethod: null,
      },
      proceeding: false,
      diactivating: false,
      signatoryData: {},
      requiredForm: [],
      authTypes: [
        { name: "OTP Authentication", value: "otp-authentication" },
        { name: "Biometric Authentication", value: "biometric-authentication" },
        { name: "All of the above", value: "all" },
      ],
    };
  },
  computed: {
    userData() {
      return {
        city: this.signatoryData.city,
        firstName: this.signatoryData.firstName,
        lastName: this.signatoryData.lastName,
        middleName: this.signatoryData.middleName,
        town: this.signatoryData.town,
        phoneNumber: this.signatoryData.phoneNumber,
        email: this.signatoryData.email,
      };
    },
    officerName() {
      return `${this.signatoryData.firstName} ${this.signatoryData.middleName} ${this.signatoryData.lastName}`;
    },
  },
  beforeMount() {
    this.getSignature();
  },
  methods: {
    handleFileInput(file) {
      console.log({ file });
    },
    async getSignature() {
      this.proceeding = true;
      try {
        const res = await this.$http.get(
          "/signatories/signatory/" + this.$route.params.id
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.signatoryData = data.data;
        this.form.approvedSignatoryGradeId =
          this.$store.state.staff.salaryGradeLevels.filter((dt) =>
            this.signatoryData.approvedSignatoryGradeId.includes(dt._id)
          );

        this.form.approvedSignatoryMdaId = this.$store.state.staff.mdas.filter(
          (dt) => this.signatoryData.approvedSignatoryMdaId.includes(dt._id)
        );

        this.form.signatureTitle = this.signatoryData.signatureTitle;
        this.form.recrutingMdaId = this.signatoryData.recrutingMdaId;
        this.form.sectorStaffTypeId = this.signatoryData.sectorStaffTypeId;
        this.form.authenticationMethod =
          this.signatoryData.authenticationMethod;
        this.form.signatoryOfficerPosition =
          this.signatoryData.signatoryOfficerPosition;
      } catch (error) {
        console.log({ error });
      }
    },

    async diactivateSignatory() {
      try {
        this.diactivating = true;
        const res = await this.$http.patch(
          "/signatories/deactivate-signatory/" + this.$route.params.id
        );

        this.diactivating = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.go(-1);
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>
<style scoped>
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
button[type="submit"] {
  @apply text-xs font-semibold p-3 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
