<template>
  <div>
    <div class="my-10 py-10 px-4 m-auto w-full lg:w-[700px] space-y-8">
      <div class="space-y-8">
        <div class="space-y-3">
          <h3 class="text-idBlued text-2xl font-bold">
            GEN 69 Signature Setup
          </h3>
          <p class="text-idBluedLight text-xs">
            You are about to setup the signature for a high ranking officer
            whose signature will appear on at the bottom of the GEN 69 Document
            generated after a new staff appointment.
          </p>
        </div>

        <div class="p-4 rounded-lg bg-[#F9F9F9]">
          <p class="font-semibold text-sm text-[#484848]">
            Input the Verification ID of the Officer you want to setup this
            signature for.
          </p>
          <form
            @submit.prevent="proceed"
            class="flex justify-between items-end gap-2 mt-6"
          >
            <div class="w-full">
              <label for="verificationCode">Verification ID</label>
              <input
                type="text"
                class="input"
                autofocus
                placeholder="Input verification ID"
                v-model="form.verificationCode"
              />
            </div>
            <button
              type="submit"
              class="bg-oneGreen text-white rounded-lg px-6 font-semibold py-[13px] flex gap-2"
              :disabled="proceeding || !formReady"
            >
              <span>Start</span>
              <spinner v-if="proceeding"></spinner>
            </button>
          </form>
        </div>
      </div>
      <div class="space-y-8" v-if="showBio">
        <hr />
        <BiometricCard :userData="userData" class="bg-[#F0FFF0]" />
        <div class="p-5 flex justify-end items-center rounded-lg bg-gray-100">
          <button
            @click="$emit('next')"
            class="bg-oneGreen text-white rounded-lg px-8 font-semibold py-[10px] flex gap-2"
          >
            <span>Proceed</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BiometricCard from "@/components/admin/clockin/operator/BiometricCard.vue";
export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },

    proceeding: {
      type: Boolean,
      default: false,
    },

    showBio: {
      type: Boolean,
      default: false,
    },
  },
  name: "VerifySignatory",
  components: {
    BiometricCard,
  },
  data() {
    return {
      form: { verificationCode: null },
    };
  },
  computed: {
    formReady() {
      return (
        this.form.verificationCode && this.form.verificationCode.length >= 5
      );
    },
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please Enter Verification ID",
        });
        return;
      }

      this.$emit("verify", { verificationCode: this.form.verificationCode });
      this.form.verificationCode = null;
    },
  },
};
</script>

<style></style>
