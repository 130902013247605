<template>
  <div class="w-full">
    <div class="flex justify-between items-center mb-5">
      <h2 class="text-dktgray text-2xl mb-5">All Records</h2>
    </div>

    <api-table
      apiUrl="/records"
      :fields="fields"
      :show-page="true"
      :show-search="true"
      placeholder="Search by name, phone number or email"
    >
      <template slot="approvalStatus" slot-scope="props">
        <span
          v-if="
            props.rowData.approvalStatus === 'pending' ||
            props.rowData.approvalStep < 2
          "
          class="text-gray-500 font-bold uppercase"
        >
          Pending
        </span>

        <span v-else class="text-oneGreen font-bold uppercase">
          {{ props.rowData.approvalStatus }}
        </span>
      </template>

      <template slot="action" slot-scope="props">
        <span class="flex gap-2 pr-2">
          <router-link
            v-if="
              props.rowData.verificationStepStatus === 'completed' &&
              !props.rowData.accredited
            "
            :to="{
              name: 'Admin Accreditation Start',
              params: { id: props.rowData.verificationCode },
            }"
            class="bg-purple-500 text-center text-white text-xs rounded py-1 px-2"
          >
            Accredit
          </router-link>

          <!-- v-can="'get-one-user'" -->
          <router-link
            v-else-if="props.rowData.accredited"
            :to="{
              name: 'Admin Bio Data',
              params: { id: props.rowData.id },
              query: { xwd: 'iopyt' },
            }"
            class="bg-oneGreen text-center text-white text-xs rounded py-1 px-3"
          >
            View
          </router-link>

          <router-link
            v-else-if="props.rowData.submissionDate"
            :to="{
              name: 'Admin Bio Data',
              params: { id: props.rowData.id },
              query: { xwd: 'iopyt' },
            }"
            class="bg-gray-700 text-center text-white text-xs rounded py-1 px-3"
          >
            View pending
          </router-link>
        </span>
      </template>
    </api-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "AllRecords",

  data() {
    // let sv = "hidden lg:table-cell";
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "name",
          title: "Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 uppercase`,
        },
        {
          name: "salaryGradeLevel",
          title: "Grade Level",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "verificationCode",
          title: "Verification Number",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },

        {
          name: "submissionDate",
          title: "Date of submission",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "approvalStatus",
          title: "Approval Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  methods: {
    onCellClicked() {
      this.$router.push({ name: "Bio Data" });
    },
  },
};
</script>
