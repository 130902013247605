<template>
  <div class="bg-litergray rounded-lg p-6 font-mulish">
    <p class="font-bold text-dkgray">
      Every field must be correctly filled to complete this process
    </p>

    <div>
      <div class="mt-6">
        <label for="mda">
          MDA
          <span class="req">*</span>
        </label>

        <v-select
          :reduce="(b) => b._id"
          label="name"
          id="mda"
          :options="mdas"
          v-model="form.mda"
          uid="id"
          placeholder="-- select --"
          class="w-full"
        ></v-select>
      </div>

      <div class="mt-6">
        <label for="officeName">
          Office Approved Name
          <span class="req">*</span>
        </label>

        <input
          type="text"
          placeholder="enter office name"
          v-model="form.officeName"
          id="officeName"
          class="input"
          v-uppercase
        />
      </div>

      <div class="mt-6">
        <label for="location">
          Pick GPS Location
          <span class="req">*</span>
        </label>

        <div
          class="flex items-center border border-litgray rounded-md px-2 py-1.5 bg-white"
        >
          <p id="location" class="text-xs lg:text-base w-full bg-transparent">
            <span v-if="gpsLocaction">{{ gpsLocaction }}</span>
            <span v-else class="text-gray-400 text-sm">
              Click the map icon to begin
            </span>
          </p>

          <button @click="$emit('showmap')" class="bg-oneGreen p-2 rounded">
            <img src="@/assets/images/icons/gps.svg" alt="icons" />
          </button>
        </div>
      </div>

      <div class="mt-6">
        <label for="radius">
          Perimeter Radius
          <span class="req">*</span>
        </label>

        <div
          class="flex gap-1 border border-litgray rounded-full px-3 py-3 bg-white"
        >
          <input
            id="radius"
            type="range"
            min="1000"
            max="10000"
            class="w-full"
            v-model="form.radius"
          />

          <div class="bg-oneGreen p-2 text-white text-xs rounded-xl">
            {{ form.radius }}m
          </div>
        </div>
      </div>

      <div class="mt-7">
        <button
          @click="saveLocation"
          :disabled="saving || !formReady"
          class="flex justify-center gap-3 items-center text-white bg-oneGreen rounded-lg py-3 w-full"
        >
          <span>Save New Location</span>
          <spinner v-if="saving"></spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddLocationStep1",

  props: {
    gpsLocaction: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      saving: false,
      form: {
        mda: null,
        officeName: null,
        radius: 1000,
      },
      staffTypes: [...this.$store.state.admin.staffTypes],
      mdas: this.$store.state.staff.mdas,
    };
  },

  computed: {
    formReady() {
      return (
        this.form.mda &&
        this.form.officeName &&
        this.gpsLocaction &&
        this.form.radius
      );
    },
  },

  methods: {
    async saveLocation() {
      try {
        if (!this.formReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }

        this.saving = true;

        const res = await this.$http.post("/setup/location", {
          mdaId: this.form.mda,
          approvedOfficeName: this.form.officeName,
          officeAddress: this.gpsLocaction.toUpperCase(),
          perimeterRadius: this.form.radius,
        });

        this.saving = false;
        if (!res) {
          return;
        }

        this.form.mda = null;
        this.form.officeName = null;

        this.$swal({
          icon: "success",
          text: "Location saved",
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
.input[type="range"] {
  cursor: pointer;
  accent-color: #0090ff;
}
</style>
