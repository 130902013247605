<template>
  <div class="my-10 py-10 px-4 m-auto w-full lg:w-[700px]">
    <section class="text-idBlued">
      <h2>SIGNATURE SETUP</h2>
    </section>

    <form @submit.prevent="proceed">
      <div>
        <label for="officerName">
          Name of Officer<span class="req">*</span>
        </label>
        <input
          readonly
          id="officerName"
          v-model="officerName"
          type="text"
          v-uppercase
          class="input"
        />
      </div>
      <div>
        <label for="officerPosition">
          Position Of Officer<span class="req">*</span>
        </label>
        <input
          v-model="form.signatoryOfficerPosition"
          id="officerPosition"
          type="text"
          v-uppercase
          class="input"
        />
      </div>
      <div>
        <label for="signatureTitle">
          Signature Title<span class="req">*</span>
        </label>
        <input
          v-model="form.signatureTitle"
          id="signatureTitle"
          type="text"
          v-uppercase
          class="input"
        />
      </div>

      <div>
        <label for="mda"> Recruiting MDA<span class="req">*</span> </label>
        <v-select
          id="mda"
          label="name"
          v-model="form.recrutingMdaId"
          v-uppercase
          :reduce="(b) => b.id"
          :options="$store.state.staff.mdas"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <label for="staffType">
          Sector (Staff Type)<span class="req">*</span>
        </label>
        <v-select
          id="staffType"
          label="name"
          v-model="form.sectorStaffTypeId"
          :reduce="(b) => b._id"
          :options="$store.state.admin.staffTypes"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div class="text-black">
        <label>
          MDA where the Signature Covers <span class="req">*</span>
        </label>
        <multiselect
          v-model="form.approvedSignatoryMdaId"
          class="text-black"
          :options="$store.state.staff.mdas"
          :searchable="true"
          :close-on-select="false"
          :multiple="true"
          label="name"
          track-by="_id"
          :show-labels="false"
          placeholder="-- select --"
        >
        </multiselect>
      </div>
      <div>
        <label> Grades <span class="req">*</span> </label>
        <multiselect
          class="text-black"
          v-model="form.approvedSignatoryGradeId"
          id="grade"
          :reduce="(b) => b.grade"
          :options="$store.state.staff.salaryGradeLevels"
          :searchable="true"
          :close-on-select="false"
          :multiple="true"
          label="grade"
          track-by="_id"
          :show-labels="false"
          placeholder="-- select --"
        >
        </multiselect>
      </div>
      <div>
        <label> Signature Sample <span class="req">*</span> </label>
        <upload-input
          v-model="form.signature"
          id="signature-upload"
          @input="handleFileInput"
        />
      </div>
      <div>
        <label for="authenticationMethod">
          Authentication Method <span class="req">*</span>
        </label>
        <v-select
          id="authenticationMethod"
          label="name"
          v-model="form.authenticationMethod"
          :reduce="(b) => b.value"
          :options="authTypes"
          placeholder="-- select --"
        ></v-select>
      </div>

      <div>
        <button :disabled="proceeding || !formReady" type="submit">
          <span>Save and Proceed</span>
          <spinner v-if="proceeding"></spinner>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import UploadInput from "@/components/form/UploadInput.vue";
export default {
  name: "CreateSignature",
  props: {
    officerName: {
      type: String,
      required: true,
    },
    proceeding: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    UploadInput,
  },
  data() {
    return {
      form: {
        baseUrl: `${window.location.origin}/onboard`,
        signatoryOfficerPosition: null,
        signatureTitle: null,
        recrutingMdaId: null,
        sectorStaffTypeId: null,
        approvedSignatoryMdaId: [],
        approvedSignatoryGradeId: [],
        signature: null,
        authenticationMethod: null,
      },
      requiredForm: [
        "baseUrl",
        "signatoryOfficerPosition",
        "signatureTitle",
        "recrutingMdaId",
        "sectorStaffTypeId",
        "approvedSignatoryMdaId",
        "approvedSignatoryGradeId",
        "signature",
        "authenticationMethod",
      ],
      authTypes: [
        { name: "OTP Authentication", value: "otp" },
        { name: "Biometric Authentication", value: "biometric" },
        { name: "All of the above", value: "all" },
      ],
    };
  },
  computed: {
    formReady() {
      let ready = true;

      for (const fd of this.requiredForm) {
        if (this.form[fd] === null || this.form[fd] === "") {
          ready = false;
        }
      }

      return ready;
    },
  },
  methods: {
    handleFileInput(file) {
      const reader = new FileReader();

      reader.onloadend = (e) => {
        const base64Image = e.target.result;
        this.form.signature = base64Image;
      };

      reader.readAsDataURL(file);
    },
    proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please Enter Required Fields ",
        });
        return;
      }
      this.$emit("create-signature", this.form);
    },
  },
};
</script>

<style scoped>
h2 {
  @apply text-xl lg:text-3pxl uppercase;
}
form {
  @apply bg-litergray rounded-lg p-6 mt-7;
}
form > div:not(:first-child) {
  @apply mt-6;
}
.req {
  @apply text-red-400;
}
form button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2;
}
input {
  @apply uppercase;
}
</style>
