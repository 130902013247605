<template>
  <div class="pt-12">
    <LoadOverlay v-if="downloading" class="absolute inset-0 h-full w-full" />
    <div v-if="loading" class="w-full h-full flex justify-center items-center">
      <spinner color="bg-gray-800" class="w-12 h-12"></spinner>
    </div>
    <div v-else>
      <NominalRollForm
        v-if="!Object.keys(reports).length"
        :proceeding="proceeding"
        @searchReport="proceed"
      />
      <NominalRollReport
        @filter="filterReport"
        v-else
        :reports="reports"
        :proceeding="proceeding"
        @downloadExcel="handledDownload"
        @goBack="goBack"
      />
    </div>
  </div>
</template>

<script>
import NominalRollForm from "./NominalRollForm.vue";
import NominalRollReport from "./NominalRollReport.vue";
import LoadOverlay from "@/components/app/LoadOverlay.vue";
export default {
  name: "NominalRoll",
  components: {
    NominalRollForm,
    NominalRollReport,
    LoadOverlay,
  },
  data() {
    return {
      reports: {},
      proceeding: false,
      loading: true,
      downloading: false,
    };
  },
  async beforeMount() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.loading = true;
      await this.proceed(this.$route.query);
    }
    this.loading = false;
  },
  methods: {
    async handledDownload(query) {
      try {
        this.downloading = true;
        console.log("loading...");
        // const query = this.clean();

        const res = await this.$http.get("/report/excel-download", {
          params: query,
        });

        this.downloading = false;
        if (!res) {
          return;
        }

        const data = res.data;

        console.log(data);
        this.$swal({
          icon: "success",
          text: data.message,
        });

        // const fileUrl = window.URL.createObjectURL(data);
        // const fileLink = document.createElement("a");

        // fileLink.href = fileUrl;
        // fileLink.setAttribute(
        //   "download",
        //   `${process.env.VUE_APP_PROJECT_NAME}-report.xlsx`
        // );
        // document.body.appendChild(fileLink);

        // fileLink.click();

        // this.downloading = false;
        // this.form = {};
      } catch (error) {
        console.log({ error });
      }
    },
    async proceed(form) {
      // this.$router.push({ name: "Report Printout" });
      // const requestPayload = this.clean(form);

      try {
        this.proceeding = true;
        const res = await this.$http.get("/report", {
          params: form,
        });
        this.proceeding = false;

        if (!res) {
          return;
        }

        const data = res.data;

        if (data.totalCount === 0) {
          this.$swal({
            icon: "error",
            text: "Report not found.",
          });
          return;
        }
        this.reports = data;
      } catch (error) {
        console.log({ error });
      }
    },
    goBack() {
      this.reports = {};
    },
    async filterReport(query) {
      await this.proceed(query);
    },
    clean(obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
};
</script>

<style scoped></style>
