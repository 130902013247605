<template>
  <div>
    <div
      v-if="hideShowForm"
      class="w-full lg:w-[720px] lg:m-10 p-2 lg:ml-[116px] lg:mt-[50px] flex flex-col item-start justify-start"
    >
      <h1 class="font-mullish text-[#484848] text-[24px] font-bold m-0">
        Direct Image Upload
      </h1>
      <span class="text-[#484848] font-mullish text-[11px] m-0 p-0">
        Upload image for accreditation
      </span>

      <div class="mt-4">
        <p class="text-[12px] text-[#484848] mb-2 font-bold text-sm">
          Enter Verification number
        </p>
        <div class="flex gap-2 items-end">
          <input type="text" class="input grow" v-model="form.UserID" />
          <button
            @click="fetchDetails"
            :disabled="!formReady || processing"
            class="bg-oneGreen text-[12px] h-[49.62px] w-[111px] rounded text-[#fff] flex items-center justify-center"
          >
            Proceed <spinner v-if="processing"></spinner>
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-full lg:w-[720px] p-2 lg:m-10 lg:ml-[116px] lg:mt-[50px] flex flex-col item-start justify-start"
      v-else
    >
      <h1 class="font-mullish text-[#484848] text-[24px] font-bold m-0">
        Staff Details
      </h1>
      <div
        class="border border-gray-100 rounded w-full min-h-[500px] flex flex-col p-4"
      >
        <div>
          <button class="flex items-center" @click="goBack">
            <img
              class="mr-2 w-3"
              src="@/assets/images/icons/arrows/left-angle.svg"
              alt=""
            />
            <span class="text-sm font-bold">Back</span>
          </button>
        </div>
        <div
          class="flex flex-col lg:flex-row w-full items-center justify-between"
        >
          <div class="flex items-center justify-start bg-grey-lighter w-full">
            <label
              class="w-full lg:w-64 h-[140px] lg:mt-6 px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white flex flex-col items-center"
            >
              <svg
                class="w-8 h-8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                />
              </svg>
              <span class="mt-2 text-base leading-normal"
                >Select staff image</span
              >
              <input
                @change="onUpload"
                class="hidden"
                type="file"
                id="file"
                accept=".png, .jpg, .jpeg"
              />
            </label>
          </div>

          <div
            class="mt-4 flex lg:flex-row gap-4 justify-start lg:justify-end items-start w-full"
          >
            <div>
              <p class="font-semibold">Upload Image</p>

              <div
                v-if="uploading"
                class="h-[140px] w-[140px] flex items-center justify-center"
              >
                <spinner class="w-[40px] h-[40px]" color="gray"></spinner>
              </div>

              <div v-else class="h-[140px] w-[140px]">
                <img
                  v-if="!imageData"
                  class="border border-gray-300 w-full h-full"
                  src="@/assets/images/null_female.png"
                />
                <img
                  v-else
                  class="border border-gray-300 w-full h-full"
                  :src="imageData"
                />
              </div>
            </div>
            <div>
              <p class="font-semibold">BVN Image</p>
              <div class="h-[140px] w-[140px]">
                <img
                  class="w-full h-full border border-gray-300"
                  :src="staffInfo.photograph"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="text-red-300 text-xs" v-if="showImageUploadError">
            Maximum file size: 2MB
          </p>

          <p class="text-xs text-gray-500" id="file_input_help">
            PNG, JPG (MAX. 2MB).
          </p>
        </div>

        <div class="divide-y">
          <div class="flex justify-between py-2">
            <strong>Verification Code:</strong>
            <span>{{ staffInfo.verificationCode }}</span>
          </div>
          <div class="flex justify-between py-2">
            <strong>First Name:</strong>
            <span>{{ staffInfo.firstName }}</span>
          </div>
          <div class="flex justify-between py-2">
            <strong>Last Name:</strong>
            <span>{{ staffInfo.lastName }}</span>
          </div>
          <div class="flex justify-between py-2">
            <strong>Middle Name:</strong>
            <span>{{ staffInfo.middleName }}</span>
          </div>
          <div class="flex justify-between py-2">
            <strong>Gender:</strong>
            <span>{{ staffInfo.gender }}</span>
          </div>

          <div class="flex justify-between py-2">
            <strong>Email:</strong>
            <span>{{ staffInfo.email }}</span>
          </div>
          <div class="flex justify-between py-2">
            <strong>Phone:</strong>
            <span>{{ staffInfo.phoneNumber }}</span>
          </div>
          <div class="flex justify-between py-2">
            <strong>BVN:</strong>
            <span>{{ staffInfo.bvn }}</span>
          </div>
          <div class="flex justify-between py-2">
            <strong>MDA:</strong>
            <span>{{ staffInfo.mdaName }}</span>
          </div>
        </div>

        <button
          class="bg-oneGreen text-[12px] h-[40.62px] w-[250px] mt-4 rounded m-auto text-[#fff] flex items-center justify-center"
          @click="upload"
          :disabled="!form.DataUrl || processing"
        >
          Procceed <spinner v-if="processing"></spinner>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DirectImageUpload",
  data() {
    return {
      hideShowForm: true,
      showImageUploadError: false,
      imageData: null,
      processing: false,
      uploading: false,
      staffInfo: {
        firstName: null,
        lastName: null,
        middleName: null,
        email: null,
        profilePhoto: null,
        phoneNumber: null,
        verificationCode: null,
        gender: null,
        mdaName: null,
        bvn: null,
      },

      form: {
        key: "yh8qlQN0sv576u5RT0WfsmxHl",
        UserID: null,
        DataUrl: null,
        ImageUrl2: null,
      },
    };
  },

  computed: {
    formReady() {
      return this.form.UserID && this.form.UserID.length >= 5;
    },
  },

  methods: {
    async fetchDetails() {
      try {
        this.processing = true;
        const res = await this.$http.post("/users/employee", {
          verificationCode: this.form.UserID,
        });

        if (!res) {
          this.processing = false;
          return;
        }

        const { data } = res;

        //check if staff has finished online verification
        if (data.data.verificationStepStatus !== "completed") {
          this.processing = false;
          this.$swal({
            text: "This staff has not completed online verification",
            icon: "info",
          });
          return;
        }

        if (data.data.accredited) {
          this.processing = false;
          this.$swal({
            text: "This staff has already been accredited.",
            icon: "info",
          });
          return;
        }
        this.staffInfo = data.data;
        this.processing = false;
        this.form.ImageUrl2 = data.data.photograph;
        this.hideShowForm = false;
      } catch (error) {
        console.log({ error });
      }
    },
    goBack() {
      this.hideShowForm = true;
      this.processing = false;
      this.form = {
        key: "yh8qlQN0sv576u5RT0WfsmxHl",
        UserID: null,
        DataUrl: null,
        ImageUrl2: null,
      };
    },
    async upload() {
      try {
        this.processing = true;
        const formData = new FormData();
        formData.append("DataUrl", this.form.DataUrl);
        formData.append("ImageUrl2", this.form.ImageUrl2);
        formData.append("UserID", this.form.UserID);
        formData.append("key", this.form.key);

        console.log({ form: this.form });
        console.log({ formData });

        const res = await this.$http.post("/biometric/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (!res) {
          this.processing = false;
          return;
        }

        this.$swal({
          icon: "success",
          text: "Upload successful",
        });

        this.processing = false;
        this.hideShowForm = true;
        console.log({ form: this.form });
        this.form = {
          key: "yh8qlQN0sv576u5RT0WfsmxHl",
          UserID: null,
          DataUrl: null,
          ImageUrl2: null,
        };
        this.imageData = null;
      } catch (error) {
        this.processing = false;
        console.log({ error });
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },
    async onUpload(event) {
      console.log(event.target.files);
      this.showImageUploadError = false;
      const target = event.target;
      if (target.files && target.files[0]) {
        const maxAllowedSize = 2 * 1024 * 1024;
        if (target.files[0].size > maxAllowedSize) {
          this.showImageUploadError = true;
          target.value = null;
          return;
        }

        this.uploading = true;
        const img = await this.convertToBase64(target.files[0]);
        this.uploading = false;

        this.form.DataUrl = target.files[0];
        this.imageData = img;
      }
    },
  },
};
</script>

<style></style>
