<template>
  <div
    class="w-full bg-litergray flex md:justify-between items-center px-3 lg:px-6 py-3 flex-col md:flex-row sticky overflow-x-scroll top-0 right-0 left-0 z-100"
  >
    <h4 class="text-dkgray lg:text-2xl sm:mb-3 text-lg font-bold w-full">
      {{ userData.firstName }} {{ userData.lastName }} Profile
    </h4>

    <div
      v-if="userData.isActive && userData.lastLogin"
      class="flex w-full md:justify-end"
    >
      <button
        v-can="'delete-user'"
        class="px-6 py-1.5 w-6/12 md:w-4/12 bg-dkgray text-white rounded-lg mr-2"
      >
        De-activate
      </button>

      <button
        @click="openModal"
        v-can="'update-user'"
        class="px-6 py-1.5 w-6/12 md:w-3/12 border-dkgray border-[1px] rounded-lg"
      >
        Edit
      </button>
    </div>

    <div class="font-semibold text-dkgray w-full text-right" v-else>
      User not Onboarded
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileActions",
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$emit("open-modal");
    },
  },
};
</script>

<style></style>
