<template>
  <ActionModal :open="open" @close="$emit('close')">
    <template #content>
      <div class="min-h-[47vh]">
        <label for="record">
          Select a Record
          <span class="req">*</span>
        </label>

        <v-select
          id="record"
          label="label"
          :reduce="(b) => b"
          :options="records.filter((re) => !re.noedit)"
          v-model="form.record"
          placeholder="-- select --"
        ></v-select>

        <template v-if="form.label">
          <div class="mt-6">
            <label for="info">{{ form.label }}</label>
            <input
              id="info"
              type="text"
              class="input"
              :value="form.oldValue"
              readonly
            />
          </div>

          <div class="mt-6">
            <label for="update">
              Input New Update
              <span class="req">*</span>
            </label>
            <input
              v-uppercase
              id="update"
              :type="form.type"
              class="input"
              v-model="form.newValue"
              autofocus
            />
          </div>

          <div class="mt-6">
            <label for="update">
              Include a descriptive reason for this Update
              <span class="req">*</span>
            </label>
            <textarea
              id="updateReason"
              v-model="form.updateReason"
              cols="30"
              rows="3"
              class="border border-dashed border-litgray px-4 py-3 rounded-md"
            ></textarea>
            <small v-if="!reasonReady" class="req text-xs font-bold">
              Please enter a valid reason (min 11 characters)
            </small>
          </div>
        </template>
      </div>
    </template>

    <template #action>
      <button
        @click="$emit('updated', form)"
        :disabled="!updateReady"
        class="bg-oneGreen text-sm p-3 rounded-lg text-white"
      >
        Proceed
      </button>
    </template>
  </ActionModal>
</template>

<script>
import ActionModal from "@/components/admin/records/ActionModal.vue";
export default {
  name: "UpdateRecord",

  components: {
    ActionModal,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    records: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        record: null,
        label: null,
        type: "text",
        oldValue: null,
        newValue: null,
        updateReason: null,
      },
    };
  },

  computed: {
    reasonReady() {
      return (
        this.form.updateReason && this.form.updateReason.trim().length > 10
      );
    },
    updateReady() {
      return (
        this.form.record &&
        // this.form.oldValue &&
        this.form.newValue &&
        this.reasonReady
      );
    },
  },

  watch: {
    "form.record"(nv) {
      this.form.newValue = null;

      if (!nv) {
        this.form.label = null;
        this.form.oldValue = null;
        return;
      }

      this.form.label = nv.label;
      this.form.oldValue = nv.value;
      this.form.type = nv.type || "text";
      this.form.newValue = null;
      this.form.updateReason = null;
    },
  },
};
</script>

<style scoped></style>
