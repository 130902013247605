<template>
  <div id="page">
    <section>
      <NavRecords :step="pageTitle" />
    </section>

    <section id="holder" class="">
      <aside>
        <BioDataNav :vsteps="vsteps" />
      </aside>

      <main class="w-full lg:w-9/12">
        <router-view></router-view>
      </main>

      <router-link
        :to="{ name: 'All Records' }"
        class="absolute hidden right-2 bg-litergray text-lg border-2 border-litgray rounded h-12 w-12 lg:flex items-center justify-center"
      >
        <img src="@/assets/images/icons/close.svg" alt="icons" />
      </router-link>
    </section>
  </div>
</template>

<script>
import NavRecords from "@/components/admin/element/NavRecords.vue";
import BioDataNav from "@/components/staff/verification/BioDataNav.vue";
export default {
  name: "AdminVerification",

  components: {
    BioDataNav,
    NavRecords,
  },

  data() {
    return {
      vsteps: [
        { stp: 1, link: "Admin Bio Data", name: "Bio-Data" },
        { stp: 2, link: "Admin NextOfKin", name: "Next of Kin" },
        { stp: 3, link: "Admin Education", name: "Education" },
        { stp: 4, link: "Admin Employment Data", name: "Employment Data" },
        { stp: 5, link: "Admin Bank Information", name: "Bank Information" },
        { stp: 6, link: "Admin BVN Verify", name: "BVN Verification" },
        { stp: 7, link: "Admin Document Upload", name: "Document Upload" },
      ],
    };
  },

  computed: {
    pageTitle() {
      return this.vsteps.find((stp) => stp.link === this.$route.name)?.name;
    },
  },

  created() {
    console.log(this.$route.query.xwd);

    this.$store.commit(
      "admin/setViewMode",
      this.$route.query.xwd && this.$route.query.xwd === "iopyt"
    );
  },

  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
#page {
  @apply w-full mx-auto relative;
  height: calc(100vh - 120px);
}
section#holder {
  @apply relative flex flex-col lg:flex-row w-full px-4 lg:px-0 mx-auto max-w-[910px] pt-7 lg:pt-10 gap-y-4 gap-x-16;
}
section#holder > main {
  @apply h-auto overflow-y-scroll;
  height: calc(100vh - 180px);
}
button {
  z-index: 9;
}
</style>
