<template>
  <div>
    <section class="mb-2">
      <h2 class="text-dktgray text-2xl">Assign Staff to location</h2>

      <!-- <div class="flex gap-3 items-end w-full mt-3">
        <div class="w-4/12">
          <label for="category" class="text-dkgray text-xs font-medium">
            Staff Category
          </label>

          <v-select
            :reduce="(b) => b.slug"
            label="name"
            id="category"
            :options="staffTypes"
            v-model="form.category"
            uid="_id"
            placeholder="-- Select staff category --"
            class="w-full"
          ></v-select>
        </div>

        <div class="w-5/12">
          <label class="text-dkgray text-xs font-medium" for="mda">MDA</label>
          <v-select
            :reduce="(b) => b.slug"
            label="name"
            id="mda"
            :options="mdas"
            v-model="form.mda"
            uid="_id"
            placeholder="-- select --"
            class="w-full"
          ></v-select>
        </div>

        <button
          @click="filtered = true"
          class="bg-idBlued text-white w-2/12 py-3.5 rounded-lg"
        >
          Filter
        </button>
      </div> -->
    </section>

    <section class="mt-5">
      <api-table
        apiUrl="/setup/staff-not-assigned"
        :fields="fields"
        :show-page="true"
        :show-search="true"
        placeholder="Search by name, phone number or email"
      >
        <template #buttons>
          <button class="bg-idBlued rounded text-white px-4 text-sm py-2">
            Assign
          </button>
        </template>

        <template slot="name" slot-scope="props">
          {{ props.rowData.firstName }} {{ props.rowData.middleName }}
          {{ props.rowData.lastName }}
        </template>

        <template slot="action" slot-scope="props">
          <span class="flex gap-2 pr-2">
            <button
              @click="assignStaff([props.rowData.employeeId])"
              class="bg-idBlued text-center text-white text-xs rounded py-1 px-3"
            >
              Assign
            </button>
          </span>
        </template>
      </api-table>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "AssignStaff",

  data() {
    // let sv = "hidden lg:table-cell";
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      filtered: false,
      bus: new Vue(),
      form: {
        mda: null,
        category: null,
      },
      staffTypes: [...this.$store.state.admin.staffTypes],
      mdas: this.$store.state.staff.mdas,
      fields: [
        {
          name: "id",
          title: "<input type='checkbox'>",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "verificationCode",
          title: "Verification Number",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "name",
          title: "Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "salaryGradeLevel",
          title: "Grade Level",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "submissionDate",
          title: "Last Clock-in",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("MMM DD. YY hh:mm:ss"),
        },
        {
          name: "approvalStatus",
          title: "Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  computed: {
    formReady() {
      return this.form.mda || this.form.category;
    },
  },

  methods: {
    async assignStaff(arr) {
      try {
        const rep = await this.$swal({
          icon: "question",
          text: "Are you sure you want to add this staff(s) to this location?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        });

        if (!rep.isConfirmed) {
          return;
        }

        const res = await this.$http.post("setup/assign-single-staff", {
          employeeId: arr,
          officeAddressId: this.$route.params.id,
        });

        if (!res) {
          return;
        }

        this.$swal({
          icon: "success",
          text: "Assigned Successfully",
        });
      } catch (error) {
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped></style>
