<template>
  <div class="w-full">
    <div class="flex justify-between items-center mb-5">
      <h2 class="text-dktgray text-2xl mb-5">Clockin Operators</h2>
    </div>

    <api-table
      apiUrl="/operators"
      :fields="fields"
      :show-page="true"
      :show-search="true"
      placeholder="Search by name, phone number or email"
    >
      <template slot="name" slot-scope="props">
        <!-- <div class=""> -->
        <p v-if="props.rowData.name" class="font-bold text-dkgray uppercase">
          {{ props.rowData.name }}
        </p>
        <p v-else class="font-bold text-dkgray">New User</p>
        <p class="text-xs text-mdgray">{{ props.rowData.email }}</p>
        <!-- </div> -->
      </template>

      <template slot="action" slot-scope="props">
        <span class="flex gap-2 pr-2">
          <button
            v-can="'get-location-details'"
            @click="toggleAssignModal(props.rowData)"
            class="bg-idBlued text-center text-white text-xs rounded py-1 px-3"
          >
            Assign
          </button>
        </span>
      </template>
    </api-table>

    <!-- Add ActionModal for assigning mda and location to operator -->
    <AssignLocation
      :userData="operator"
      v-if="showModal"
      @close-modal="toggleAssignModal()"
      @done="() => $events.fire('table-refresh')"
    />
  </div>
</template>

<script>
import AssignLocation from "@/components/admin/clockin/operator/AssignLocation.vue";

export default {
  name: "AllOperators",

  components: {
    AssignLocation,
  },

  data() {
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      showModal: false,
      operator: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "name",
          title: "Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "approvedOfficeName",
          title: "Office",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "officeAddress",
          title: "Office Address",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "mdaName",
          title: "MDA",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  methods: {
    toggleAssignModal(d = null) {
      this.operator = d;
      this.showModal = !this.showModal;
    },
  },
};
</script>
