<template>
  <div class="w-full px-4 lg:px-6 m-auto my-8">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-[#484848] text-2xl font-bold font-mulish">
        GEN 69 Signatures
      </h2>
      <router-link
        class="bg-[#0090FF1A] text-[#0090FF] text-md p-3 rounded font-semibold"
        :to="{ name: 'Signature' }"
      >
        Create New Signature
      </router-link>
    </div>

    <div>
      <api-table
        apiUrl="/signatories/all-signatories"
        :fields="fields"
        :showPage="true"
        :showSearch="true"
        placeholder="Search by verification code"
        :bus="bus"
      >
        <template slot="action" slot-scope="props">
          <!-- <template slot="action" slot-scope="props"> -->
          <div class="relative">
            <button class="text-gray-600 text-xl font-bold btn-elip">
              &#x2026;
            </button>
            <div
              class="w-[200px] hidden border rounded dropdown absolute top-1 right-4 bg-white z-100 py-3"
            >
              <ul class="flex flex-col divide-y font-semibold text-[#484848]">
                <li
                  @click="openSignature(props.rowData.id)"
                  class="p-3 cursor-pointer"
                >
                  Deactivate Signature
                </li>
                <li
                  @click="openSignature(props.rowData.id)"
                  class="p-3 cursor-pointer"
                >
                  View Signature
                </li>
              </ul>
            </div>
          </div>
        </template>

        <template slot="status" slot-scope="props">
          <span
            v-if="props.rowData.status == 'Active'"
            class="text-blue-400 text-[13px] font-bold"
            >{{ props.rowData.status }}</span
          >
          <span v-else class="text-[13px] font-bold text-red-300"
            >{{ props.rowData.status }}
          </span>
        </template>
        <template slot="approvedSignatoryMdaName" slot-scope="props">
          <div class="w-full flex items-center gap-2 flex-wrap">
            <span
              class="text-xs text-[#484848] block py-0.5 px-1 border rounded bg-[#F9F9F9]"
              v-for="(mda, i) in props.rowData.approvedSignatoryMdaName"
              :key="i"
            >
              {{ mda }}
            </span>
          </div>
        </template>
      </api-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ListSignature",
  data() {
    const tc = "bg-[#F2F2F2] py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),

      fields: [
        {
          name: "signatureTitle",
          title: "Signature Title",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 w-2/12`,
        },
        {
          name: "approvedSignatoryMdaName",
          title: "MDAs Where The Signature Cover",
          titleClass: `${tc} text-left pl-2 `,
          dataClass: `${dc}  w-4/12`,
        },
        {
          name: "sectorStaffTypeName",
          title: "Sector",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "signatoryOfficerName",
          title: "Signatory Officer Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "status",
          title: "Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
      tableData: [
        {
          title: "Local Government Education Signature",
          mdas: [
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
          ],
          sector: "Civil",
          status: true,
        },

        {
          title: "Local Government Education Signature",
          mdas: ["Umuahia South", "Umuahia South"],
          sector: "Civil",
          status: true,
        },
        {
          title: "Local Government Education Signature",
          mdas: [
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
          ],
          sector: "Civil",
          status: true,
        },

        {
          title: "Local Government Education Signature",
          mdas: [
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
          ],
          sector: "Civil",
          status: true,
        },

        {
          title: "Local Government Education Signature",
          mdas: [
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
            "Umuahia South",
          ],
          sector: "Civil",
          status: true,
        },
      ],
    };
  },
  methods: {
    openSignature(id) {
      console.log(id);
      this.$router.push({
        name: "View Signature",
        params: { id },
      });
    },
  },
};
</script>

<style scoped>
.btn-elip:focus + .dropdown,
.dropdown:hover {
  @apply block;
}
</style>
