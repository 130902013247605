<template>
  <div class="relative">
    <section>
      <HorizontalNav :navs="tabs" :disabled="false" />
    </section>

    <section class="w-full max-w-[1200px] mx-auto px-4 lg:px-4 mt-5">
      <router-view></router-view>
    </section>
  </div>
</template>
<script>
import HorizontalNav from "@/components/staff/verification/HorizontalNav.vue";

export default {
  name: "Records",
  components: {
    HorizontalNav,
  },
  data() {
    return {
      tabs: [
        {
          title: "All Records",
          link: "All Records",
        },
        {
          title: "Locations",
          link: "Location Records",
          permit: "list-locations",
        },
        {
          title: "Operators",
          link: "Operator Records",
          permit: "get-user",
        },
        // {
        //   title: "New Staff",
        //   link: "New Staff Records",
        //   permit: "create-staff",
        // },
        {
          title: "Approval Needed",
          permit: "approve-report",
          link: "Approval Needed",
        },
        {
          title: "Authorization Needed",
          permit: "authorize-report",
          link: "Authorization Needed",
        },
        // {
        //   title: "Records for Signature",
        //   permit: "sign-gen69",
        //   link: "All Signatures",
        // },
      ],
    };
  },
};
</script>
