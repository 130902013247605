<template>
  <div class="mt-12 lg:mt-10 pr-6 pl-28 font-mulish relative">
    <section>
      <div class="text-idBlued">
        <h3 class="font-bold text-2xl">Setup New Office Location</h3>
        <small class="text-xs">
          This allows you to create and save a new location into the system
        </small>
      </div>
    </section>

    <!-- STEP 1 -->
    <section>
      <AddLocationStep1
        @showmap="showMap = !showMap"
        :gpsLocaction="formattedAddress"
      />
    </section>

    <!-- STEP 2 -->
    <section v-if="showMap" class="absolute right-0 top-[-45px]">
      <MapInterface @closemap="closeMap" v-model="formattedAddress" />
    </section>
  </div>
</template>

<script>
import AddLocationStep1 from "@/components/admin/clockin/AddLocationStep1.vue";
import MapInterface from "@/components/admin/clockin/MapInterface.vue";
export default {
  name: "AddLocation",

  components: {
    AddLocationStep1,
    MapInterface,
  },

  data() {
    return {
      showMap: false,
      gpsLocaction: null,
      formattedAddress: null,
    };
  },

  methods: {
    closeMap(d = null) {
      this.showMap = false;
      this.gpsLocaction = d;
      console.log({ d });
    },
  },
};
</script>

<style scoped>
section {
  @apply max-w-[700px];
}
</style>
