<template>
  <div>
    <modal @close="closeModal">
      <!-- Role -->
      <section>
        <div
          class="text-center bg-[#F9F9F9] p-1.5 rounded font-bold text-[#484848] mb-2"
        >
          Assign role
        </div>
        <div class="border rounded">
          <div class="text-[13px] p-1.5 bg-[#F9F9F9]">
            <span class="font-semibold">Present Role</span>:
            {{ userData.roleName }}
          </div>
          <div class="p-3">
            <div
              v-for="(val, i) in roleList"
              :key="i"
              class="flex items-center justify-between mb-1"
            >
              <label :for="`role${i}`" class="text-[10px] text-[#484848]">{{
                val.name
              }}</label>
              <input
                :id="`role${i}`"
                type="radio"
                v-model="form.roleId"
                name="role"
                :value="val.id"
              />
            </div>
          </div>
        </div>
      </section>

      <!-- MDA List -->
      <section>
        <div
          class="text-center bg-[#F9F9F9] p-1.5 rounded font-bold text-[#484848] my-3"
        >
          Assign MDA
        </div>
        <searchable-select
          @add-list="addMdas"
          :list="mdaList"
          :prevValues="mdaIds"
        >
          <template #default>
            <div class="w-full flex justify-between items-center py-3 px-5">
              <span class="text-[10px]"> Select MDA</span>
              <img
                src="@/assets/images/icons/arrows/down-angle.svg"
                class="h-[8px]"
                alt="chevron down"
              />
            </div>
          </template>
        </searchable-select>
      </section>
      <!-- Permissions -->
      <section>
        <div
          class="text-center bg-[#F9F9F9] p-1.5 rounded font-bold text-[#484848] my-3 z-1000"
        >
          Assigs Permissions
        </div>
        <searchable-select
          @add-list="addPermissions"
          :list="permissionsList"
          :prevValues="permissionIds"
        >
          <template #default>
            <div class="w-full flex justify-between items-center py-3 px-5">
              <span class="text-[10px]"> Select Permissions</span>
              <img
                src="@/assets/images/icons/arrows/down-angle.svg"
                class="h-[8px]"
                alt="chevron down"
              />
            </div>
          </template>
        </searchable-select>
      </section>

      <div class="my-4 mx-1">
        <button
          type="button"
          @click="updateUser"
          :disabled="!formComplete || proceeding"
          class="bg-[#1A8103] w-full flex items-center justify-center py-2 text-sm text-center text-[#fff] rounded font-bold"
        >
          <span>Confrim</span>
          <spinner class="h-4 w-4 ml-1" v-if="proceeding"></spinner>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/app/ModalOverlay.vue";
// import DatePicker from "@/components/admin/element/DatePicker.vue";
import SearchableSelect from "@/components/admin/element/SearchableSelect.vue";
export default {
  name: "ProfileEditModal",
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
    SearchableSelect,
  },

  data() {
    return {
      form: {
        roleId: this.userData.roleId,
        mdas: [],
        permissions: [],
      },
      proceeding: false,
      mdaList: this.$store.state.staff.mdas,
    };
  },

  computed: {
    permissionsList() {
      return this.$store.getters["admin/permissionsList"];
    },

    formComplete() {
      return (
        this.form.roleId &&
        (this.form.permissions.length > 0 || this.form.mdas.length > 0)
      );
    },

    mdaIds() {
      return this.userData.mdas.map((md) => md._id);
    },

    permissionIds() {
      return this.userData.permissions.map((perm) => perm._id);
    },

    roleList() {
      return this.$store.state.admin.roles.map((el) => {
        return {
          id: el._id,
          name: el.roleName.split("-").join(" ").toUpperCase(),
        };
      });
    },
  },
  created() {
    this.form.permissions = this.permissionIds;
    this.form.mdas = this.mdaIds;
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    addMdas(mdas) {
      this.form.mdas = [...new Set([...mdas])];
    },
    addPermissions(permissions) {
      this.form.permissions = [...new Set([...permissions])];
    },
    async updateUser() {
      if (!this.formComplete) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }
      try {
        this.proceeding = true;
        const res = await this.$http.patch(
          `/users/${this.$route.params.id}`,
          this.form
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.showModal = false;
        this.$emit("initialize-data");
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  accent-color: #1a8103;
}
button[type="button"] {
  @apply w-full lg:w-[80px] bg-[#484848] text-white font-semibold py-1.5 rounded flex justify-center items-center text-[12px];
}
</style>
