<template>
  <div>
    <!-- Tabs -->
    <div class="fixed w-full -m-0.3 z-12">
      <HorizontalNav :navs="tabs" :disabled="false" />
    </div>
    <div class="">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import HorizontalNav from "@/components/staff/verification/HorizontalNav.vue";

export default {
  name: "ReportType",
  components: {
    HorizontalNav,
  },
  data() {
    return {
      tabs: [
        {
          title: "Nominal Roll Report",
          link: "Nominal Roll Report",
        },
        {
          title: "Payroll Reports ",
          link: "Pay Roll Report",
        },
      ],
    };
  },
};
</script>
