<template>
  <transition name="slidex" appear="">
    <nav
      class="bg-sideBlack absolute lg:static left-0 bottom-0 top-px font-source overflow-hidden"
    >
      <div class="relative h-[calc(100vh-20px)]">
        <div class="p-3 bg-[#3B4A52] flex items-center">
          <p class="text-[#C7C7C7] text-[15px] font-semibold mr-3">ROLE</p>
          <p class="text-white text-[13px] admin-type">{{ adminType }}</p>
        </div>

        <ul
          class="mt-5 p-5 flex flex-col gap-3 h-[calc(100vh-160px)] overflow-y-scroll"
        >
          <li>
            <router-link
              :to="{ name: 'Admin Dashboard' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/dashboard.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Dashboard</span>
            </router-link>
          </li>

          <li v-can="'get-user'">
            <router-link
              :to="{ name: 'Admin List Users' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/support.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Users</span>
            </router-link>
          </li>

          <!-- Records drop down -->
          <li class="dropdown">
            <button
              class="flex items-end justify-between border-solid border border-[#8D8D8D] rounded p-2.5 text-white w-full"
              type="button"
              id="dropdownDividerButton"
              data-dropdown-toggle="dropdownDivider"
            >
              <div class="flex gap-3 text-sm">
                <img
                  src="@/assets/images/icons/document-attachment 1.svg"
                  alt="icons"
                  class="h-6 w-6"
                />
                <span>Records</span>
              </div>

              <span class="text-xs text-gray-400 pr-4 pb-1 icon">
                &#x25BC;
              </span>
            </button>

            <div
              id="dropdownDivider"
              tabindex="0"
              class="pl-2.5 dropdownDivider"
            >
              <ul class="text-sm" aria-labelledby="dropdownDividerButton">
                <li>
                  <router-link
                    :to="{ name: 'Admin Records' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Staff Records
                  </router-link>
                </li>
                <li v-can="'list-locations'">
                  <router-link
                    :to="{ name: 'Location Records' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Locations</span>
                  </router-link>
                </li>

                <li v-can="'get-user'">
                  <router-link
                    :to="{ name: 'Operator Records' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Operators</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!----- Profile -->
          <li>
            <router-link
              :to="{ name: 'Admin Profile', params: { id: adminID } }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/profile.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Profile</span>
            </router-link>
          </li>

          <!-- Reports -->
          <li class="dropdown" v-can="'report-viewer'">
            <button
              class="flex items-end justify-between border-solid border border-[#8D8D8D] rounded p-2.5 text-white w-full"
              type="button"
              id="dropdownDividerButton"
              data-dropdown-toggle="dropdownDivider"
            >
              <div class="flex items-center gap-3 text-sm">
                <Icon
                  icon="mdi:report-box-plus-outline"
                  class="w-7 h-7"
                  :inline="true"
                />
                <span>Reports</span>
              </div>

              <span class="text-xs text-gray-400 pr-4 pb-1 icon">
                &#x25BC;
              </span>
            </button>

            <div
              id="dropdownDivider"
              tabindex="0"
              class="pl-2.5 dropdownDivider"
            >
              <ul class="text-sm" aria-labelledby="dropdownDividerButton">
                <li v-can="'report-viewer'">
                  <router-link
                    :to="{ name: 'Nominal Roll Report' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Staff Reports</span>
                  </router-link>
                </li>

                <li v-can="'report-viewer'">
                  <router-link
                    :to="{ name: 'Picture Report' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Picture Reports</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- support -->
          <li v-can="'get-contact'">
            <router-link
              :to="{ name: 'Support View' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/support.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Support</span>
            </router-link>
          </li>

          <!-- Recruitment drop down -->
          <li class="dropdown" v-can="'create-staff'">
            <button
              class="flex items-end justify-between border-solid border border-[#8D8D8D] rounded p-2.5 text-white w-full"
              type="button"
              id="dropdownDividerButton"
              data-dropdown-toggle="dropdownDivider"
            >
              <div class="flex items-center gap-3 text-sm">
                <Icon
                  icon="clarity:employee-group-line"
                  class="w-6 h-6"
                  :inline="true"
                />
                <span>Recruitment</span>
              </div>

              <span class="text-xs text-gray-400 pr-4 pb-1 icon">
                &#x25BC;
              </span>
            </button>

            <div
              id="dropdownDivider"
              tabindex="0"
              class="pl-2.5 dropdownDivider"
            >
              <ul class="text-sm" aria-labelledby="dropdownDividerButton">
                <li v-can="'create-staff'">
                  <router-link
                    :to="{ name: 'Create Staff' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Create New Staff</span>
                  </router-link>
                </li>

                <li v-can="'create-staff'">
                  <router-link
                    :to="{ name: 'New Staff Records' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>List New Staff</span>
                  </router-link>
                </li>

                <li v-can="'sign-gen69'">
                  <router-link
                    :to="{ name: 'All Signatures' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Approve Gen69
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- Settings drop down -->
          <li class="dropdown">
            <button
              class="flex items-end justify-between border-solid border border-[#8D8D8D] rounded p-2.5 text-white w-full"
              type="button"
              id="dropdownDividerButton"
              data-dropdown-toggle="dropdownDivider"
            >
              <div class="flex gap-3 text-sm">
                <img
                  src="@/assets/images/icons/changePasswordicon.svg"
                  alt="icons"
                  class="h-6 w-6"
                />
                <span>Settings</span>
              </div>

              <span class="text-xs text-gray-400 pr-4 pb-1 icon">
                &#x25BC;
              </span>
            </button>

            <div
              id="dropdownDivider"
              tabindex="0"
              class="pl-2.5 dropdownDivider"
            >
              <ul class="text-sm" aria-labelledby="dropdownDividerButton">
                <li v-can="'manual-upload'">
                  <router-link
                    :to="{ name: 'Direct Image Upload' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Direct Image Upload
                  </router-link>
                </li>

                <li v-can="'service-points'">
                  <router-link
                    :to="{ name: 'Service Points' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Service Points</span>
                  </router-link>
                </li>

                <li v-can="'create-dashboard-link'">
                  <router-link
                    :to="{ name: 'Create Dashboard Link' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Create Dashboard Link</span>
                  </router-link>
                </li>

                <li v-can="'add-location'">
                  <router-link
                    :to="{ name: 'Admin Add Location' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Setup Office Location
                  </router-link>
                </li>

                <li v-can="'set-clock-in'">
                  <router-link
                    :to="{ name: 'Admin Setup Clockin' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Setup Clockin
                  </router-link>
                </li>

                <li v-can="'create-user'">
                  <router-link
                    :to="{ name: 'Gen 69 Signatures' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Setup Gen69 Signatory
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'Admin Change Password' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    <span>Change Password</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- System drop down -->
          <li class="dropdown" v-can="'create-user'">
            <button
              class="flex items-end justify-between border-solid border border-[#8D8D8D] rounded p-2.5 text-white w-full"
              type="button"
              id="dropdownDividerButton"
              data-dropdown-toggle="dropdownDivider"
            >
              <div class="flex items-center gap-3 text-sm">
                <Icon
                  icon="grommet-icons:system"
                  class="w-7 h-7"
                  :inline="true"
                />
                <span>System</span>
              </div>

              <span class="text-xs text-gray-400 pr-4 pb-1 icon">
                &#x25BC;
              </span>
            </button>

            <div
              id="dropdownDivider"
              tabindex="0"
              class="pl-2.5 dropdownDivider"
            >
              <ul class="text-sm" aria-labelledby="dropdownDividerButton">
                <li v-can="'manual-upload'">
                  <router-link
                    :to="{ name: 'Admin Staff Upload' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Staff Upload
                  </router-link>
                </li>

                <li v-can="'manual-upload'">
                  <router-link
                    :to="{ name: 'Admin MDA List' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    MDA List
                  </router-link>
                </li>

                <li v-can="'manual-upload'">
                  <router-link
                    :to="{ name: 'Admin Department List' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Department List
                  </router-link>
                </li>

                <li v-can="'manual-upload'">
                  <router-link
                    :to="{ name: 'Admin Bank List' }"
                    active-class="nav-active"
                    @click.native="closeNav"
                  >
                    Bank List
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <!-- Logout -->
          <li class="mt-2">
            <button
              @click="logout"
              class="flex items-center gap-3 rounded text-white px-4 py-2.5"
            >
              <img src="@/assets/images/icons/logout.svg" alt="icons" />

              <span class="text-xs">Log out</span>
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </transition>
</template>

<script>
import { Icon } from "@iconify/vue2";
export default {
  name: "AdminSideNav",

  components: { Icon },

  computed: {
    adminType() {
      return `${this.$store.getters["general/userType"]}`;
    },
    adminID() {
      return `${this.$store.getters["general/userId"]}`;
    },
  },
  methods: {
    closeNav() {
      this.$store.commit("general/toggleNavBar", false);
    },
    logout() {
      this.$router.push({ name: "Admin Login" });
      this.$store.dispatch("general/logout");
    },
  },
};
</script>

<style scoped>
nav {
  box-shadow: 0px 0px 4px 0px #a6a5a5;
  z-index: 9;
}

ul li {
  @apply border-solid border border-[#8D8D8D] rounded;
}
a {
  @apply text-white rounded text-sm hover:bg-[#3B4A52] hover:text-white flex gap-3 px-3 py-2.5;
}
.nav-active {
  @apply bg-[#3B4A52] text-white font-semibold rounded;
}
li.dropdown {
  @apply border-none;
}
li.dropdown li {
  @apply border-r-0 border-t-0 border-b-0 border-litgray rounded-none mt-2;
}
li.dropdown .dropdownDivider {
  display: none;
}
li.dropdown:focus-within .dropdownDivider {
  display: block;
  transition: 0.2s all ease-in-out;
}
li.dropdown:focus-within .icon {
  transform-origin: center;
  transform: rotateX(180deg) translateY(4px);
  transition: 0.2s all ease-in-out;
}

/* ****************** Slide transistion */
.slidex-leave-active,
.slidex-enter-active {
  transition: 0.5s;
}
.slidex-enter {
  transform: translate(-100%, 0);
}
.slidex-leave-to {
  transform: translate(-100%, 0);
}
.admin-type {
  text-transform: capitalize !important;
}
</style>
