<template>
  <div>
    <modal @close="closeModal">
      <div>
        <h4 class="text-lg font-mulish font-bold">
          Assign Office Location to
          <span class="uppercase text-base"
            >{{ userData.firstName }} {{ userData.lastName }}</span
          >
        </h4>
      </div>
      <!-- MDA List -->
      <form @submit.prevent="proceed">
        <div class="mt-5">
          <label for="mdaId">
            Select MDA
            <span class="req">*</span>
          </label>
          <v-select
            id="mdaId"
            label="name"
            :reduce="(b) => b.id"
            :options="$store.state.staff.mdas"
            v-model="form.mdaId"
            placeholder="-- select --"
          ></v-select>
        </div>

        <div class="mt-5">
          <label for="officeAddressId">
            Office Location
            <span class="req">*</span>
          </label>
          <v-select
            id="officeAddressId"
            label="approvedOfficeName"
            :reduce="(b) => b._id"
            :options="locations"
            v-model="form.officeAddressId"
            :filterable="false"
            @search="searchLocations"
            placeholder="-- start typing to find office --"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No results found for <em>{{ search }}</em
                >.
              </template>
              <span v-else clas="text-gray-400 lowercase text-xs">
                Start typing to search for an office
              </span>
            </template>

            <template v-slot:option="option">
              <div>
                <span>{{ option.approvedOfficeName }}</span>
                <br />
                <span class="text-[10px]">{{ option.officeAddress }}</span>
              </div>
            </template>
          </v-select>
        </div>

        <div class="mt-7">
          <button
            type="submit"
            class="bg-idBlued text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
            :disabled="proceeding || !formReady"
          >
            <spinner class="" v-if="proceeding"></spinner>
            <span>Assign Location</span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/app/ModalOverlay.vue";
export default {
  name: "OperatorAssignLocation",
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
  },

  data() {
    return {
      form: {
        userId: this.userData?.id,
        mdaId: null,
        officeAddressId: null,
      },
      proceeding: false,
      locations: [],
    };
  },

  computed: {
    formReady() {
      return this.form.mdaId && this.form.officeAddressId;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },

    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }

      try {
        this.proceeding = true;
        const res = await this.$http.post(
          "/assign-operator-location",
          this.form
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$emit("done");
        this.$emit("close-modal");
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },

    async searchLocations(search, loading) {
      if (search.length < 3) {
        return;
      }

      try {
        loading(true);
        const res = await this.$http.get(`setup/locations?search=${search}`);

        if (!res) {
          return;
        }

        const { data } = res;
        console.log(data);

        this.locations = data?.data?.data || [];
        loading(false);
      } catch (err) {
        loading(false);
      }
    },
  },
};
</script>
