<template>
  <div
    class="w-full p-4 bg-litergray border border-dashed border-gray-600 rounded-lg mb-4"
  >
    <div class="flex justify-between items-center">
      <h4 class="text-dkgray">{{ doc.documentTypeId?.name }}</h4>

      <p :class="`text-xs lg:text-sm text-${statusClass}-500`">
        &#x23FA; {{ doc.approvalStatus }}
      </p>
    </div>

    <div
      class="py-4 px-2 bg-white rounded mt-2 flex items-center justify-between"
    >
      <div class="flex items-center w-10/12">
        <img
          src="@/assets/images/icons/file.svg"
          alt="icons"
          class="h-7 w-7 lg:h-10 lg:w-10"
        />

        <div class="w-10/12">
          <h5 class="text-dkgray text-xs lg:text-sm font-bold truncate">
            {{ doc.documentName }}
          </h5>
          <p class="text-xs text-litgray">
            Uploaded on: {{ doc.updatedAt | moment("Do MMM YYYY") }}
          </p>
        </div>
      </div>

      <button
        @click="$emit('open', doc)"
        class="bg-oneGreen py-1.5 px-3 text-xs rounded text-white font-bold w-2/12"
      >
        View
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFile",

  props: {
    doc: {
      type: Object,
      default: () => ({
        documentName: null,
        documentUrl: null,
      }),
    },
  },

  computed: {
    statusClass() {
      return this.doc.approvalStatus === "pending"
        ? "gray"
        : this.doc.approvalStatus === "rejected"
        ? "red"
        : "blue";
    },
  },
};
</script>

<style scoped></style>
