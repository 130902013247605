<template>
  <div class="relative">
    <div
      class="sticky flex lg:justify-between flex-col lg:flex-row items-start lg:items-center bg-gray-100 py-5 px-10 w-full"
    >
      <button class="flex items-center" @click="goBack">
        <img
          class="mr-2 w-3"
          src="@/assets/images/icons/arrows/left-angle.svg"
          alt=""
        />
        <span class="text-3xl font-bold">Results</span>
      </button>
      <div
        class="flex flex-col lg:justify-end lg:flex-row gap-2 items-center w-full lg:w-2/3"
      >
        <div class="w-full lg:w-[150px]">
          <label for="mdaId" class="text-sm">Limit</label>

          <v-select
            id="mdaId"
            :reduce="(b) => b"
            :options="limits"
            v-model="form.limit"
            placeholder="-- select --"
          ></v-select>
        </div>
        <div class="w-full lg:w-[150px]">
          <label for="page" class="text-sm">Page</label>

          <v-select
            id="page"
            class="text-sm"
            label="label"
            :reduce="(b) => b"
            :options="pages"
            v-model="form.page"
            placeholder="-- select --"
          >
          </v-select>
        </div>
        <div class="lg:ml-3 lg:pt-7 w-full lg:w-auto">
          <button
            @click="filter"
            :disabled="proceeding || !formReady"
            type="submit"
            class="w-full"
          >
            <span>Generate</span>
            <spinner v-if="proceeding"></spinner>
          </button>
        </div>
        <div class="w-full lg:pt-7 flex gap-2 items-end justify-center">
          <button
            @click="printPrintout(true)"
            class="p-3 text-sm rounded-lg border-[1px] border-gray-400"
          >
            Print With Photo
          </button>
          <button
            @click="printPrintout(false)"
            class="p-3 text-sm rounded-lg border-[1px] border-gray-400"
          >
            Print Without Photo
          </button>

          <button
            @click="handleDownload"
            type="button"
            class="p-3 text-sm rounded-lg border-[1px] border-gray-400"
          >
            <span class="mr-2"> Download as Excel</span>
          </button>
        </div>
      </div>
    </div>
    <div class="overflow-y-scroll h-[78vh]">
      <div class="p-10" ref="pdfcontent" id="printoutt">
        <div
          class="text-center w-full flex justify-center items-center flex-col mb-4"
        >
          <img
            class="h-14 w-14"
            :src="require(`@/assets/images/logos/${PROJECT_NAME}.png`)"
            :alt="`${APP_NAME} Logo`"
          />
          <h3 class="text-xl">{{ APP_NAME }}</h3>
          <p class="text-xl">STAFF VERIFICATION REPORT</p>
        </div>
        <div class="mb-4">
          <div class="border-2 border-gray-400 rounded w-full p-4 mb-3">
            <div
              class="bg-green-600 p-2 rounded w-full text-white flex justify-between items-center font-semibold text-sm"
            >
              <div>
                <span>TOTAL NUMBER OF STAFF: </span>
                <span>{{ reports.totalCount }}</span>
              </div>
              <div>
                {{ `Page ${reports.page}` }}
              </div>
            </div>
          </div>

          <div v-for="(val, i) in reports.data" :key="i">
            <div class="border-2 border-gray-400 rounded w-full p-4 my-4">
              <div class="text-center text-xl font-bold mb-2">
                <span class="text-greenbg-green-500">MDA: </span
                >{{ val.mdaName }}
              </div>
              <div
                class="flex justify-between font-light items-center text-lg text-center"
              >
                <span
                  >DATE
                  <span class="font-semibold">{{ todaysDate }}</span></span
                >
                <span>NOMINAL ROLL</span>
                <span
                  >TOTAL STAFF COUNT
                  <span class="font-semibold">{{
                    val.mdaStaffCount
                  }}</span></span
                >
              </div>
            </div>

            <div
              class="border-2 border-gray-400 rounded w-full p-4 overflow-y-scroll"
            >
              <table class="table-auto">
                <thead class="mb-4 rounded border-2 border-gray-400">
                  <tr class="text-xs bg-gray-200 divide-x divide-gray-400">
                    <th class="px-2 py-1">S/N</th>
                    <th class="px-2 py-1">V. No</th>
                    <th class="px-2 py-1">Payroll Last Name</th>
                    <th class="px-2 py-1">Payroll Middle Name</th>
                    <th class="px-2 py-1">Payroll First Name</th>
                    <th class="px-2 py-1">Grade</th>
                    <th class="px-2 py-1">Step</th>
                    <th class="px-2 py-1">Department</th>
                    <th class="px-2 py-1">Date of First Appointment</th>
                    <th class="px-2 py-1">Salary Account</th>
                    <th class="px-2 py-1">Bank Name</th>
                    <th class="px-2 py-1">Branch Name</th>
                    <th class="px-2 py-1">Date of Birth</th>
                    <th class="px-2 py-1">Retirement Date</th>
                    <th class="px-2 py-1">Phone Number</th>
                    <th class="px-2 py-1">BVN Number</th>
                    <th class="px-2 py-1">NIN</th>
                    <th class="px-2 py-1">Age</th>
                    <th class="px-2 py-1">Verification Status</th>
                    <th class="px-2 py-1">BVN image</th>
                    <th class="px-2 py-1">Biometric Image</th>
                  </tr>
                </thead>
                <tbody class="mt-5 rounded border-2 border-gray">
                  <tr
                    v-for="(el, j) in val.staffs"
                    :key="j"
                    class="text-1xs divide-x divide-y divide-gray-400"
                  >
                    <td class="p-1.5">{{ j + 1 }}</td>
                    <td class="p-1.5">
                      {{ el?.verificationCode }}
                    </td>

                    <td class="p-1.5">{{ el?.lastName }}</td>
                    <td class="p-1.5">{{ el?.middleName }}</td>
                    <td class="p-1.5">{{ el?.firstName }}</td>
                    <td class="p-1.5">
                      {{ el?.salaryGradeLevel }}
                    </td>
                    <td class="p-1.5">{{ el?.currentStep }}</td>
                    <td class="p-1.5">
                      {{ el?.departmentName }}
                    </td>
                    <td class="p-1.5">
                      {{
                        el?.employmentinfos?.dateOfAppointment?.split("T")[0]
                      }}
                    </td>
                    <td class="p-1.5">{{ el?.salaryAccountNumber }}</td>
                    <td class="p-1.5">{{ el?.bankName }}</td>
                    <td class="p-1.5">{{ el?.branchName }}</td>
                    <td class="p-1.5">
                      {{ el?.dateOfBirth?.split("T")[0] }}
                    </td>
                    <td class="p-1.5">
                      {{ el?.employmentinfos?.dateOfRetirement?.split("T")[0] }}
                    </td>
                    <td class="p-1.5">{{ el?.phoneNumber }}</td>
                    <td class="p-1.5">
                      {{ el?.bvn?.bvnCode }}
                    </td>
                    <td class="p-1.5">{{ el?.nin }}</td>
                    <td class="p-1.5">{{ el?.age }}</td>
                    <td class="p-1.5 flex flex-wrap uppercase">
                      {{ el?.onlineVerificationStatus }}
                    </td>
                    <td class="p-1.5 w-[40px]">
                      <a
                        :href="el?.bvn?.photograph"
                        :data-fancybox="`image-${el?.verificationCode}`"
                        data-caption="BVN Image"
                      >
                        <img
                          class="w-full h-12"
                          v-if="!printWithImage || !el?.bvn?.photograph"
                          src="@/assets/images/null_female.png"
                          alt=""
                        />
                        <img
                          class="w-full h-12"
                          v-else
                          :src="el?.bvn?.photograph"
                          alt="BVN image"
                        />
                      </a>
                    </td>
                    <td class="p-1.5 w-10 h-12">
                      <a
                        :href="el?.photograph"
                        :data-fancybox="`image-${el?.verificationCode}`"
                        data-caption="Biometric Image"
                      >
                        <img
                          class="w-full h-7"
                          v-if="
                            !printWithImage || !el.photograph || !el.accredited
                          "
                          src="@/assets/images/null_female.png"
                          alt=""
                        />
                        <img
                          class="w-full h-12"
                          v-else
                          :src="el?.photograph"
                          alt="Biometric Image"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
  name: "NominalRollReport",
  props: {
    reports: {
      type: Object,
      required: true,
    },
    proceeding: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      printWithImage: true,
      loading: false,

      limits: [10, 20, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
      form: {
        page: this.reports.page,
        limit: this.reports.limit,
      },
    };
  },
  mounted() {
    Fancybox.bind("[data-fancybox]", {
      // Your custom options
    });
  },
  watch: {
    calPage(val) {
      this.loopPages(val);
    },
  },
  computed: {
    formReady() {
      return this.form.limit || this.form.page;
    },

    pages() {
      const page = Math.ceil(
        Number(this.reports.totalCount) / Number(this.form.limit)
      );

      const pageCount = [];

      for (let i = 0; i < page; i++) {
        pageCount.push(i + 1);
      }
      return pageCount;
    },
    todaysDate() {
      return new Date().toLocaleString();
    },
  },
  methods: {
    goBack() {
      this.$emit("goBack");
      this.$router.replace(this.$route.path);
    },
    handleDownload() {
      console.log("Downloading...");
      const query = { ...this.$route.query, ...this.form };
      this.$emit("downloadExcel", query);
    },
    filter() {
      const query = { ...this.$route.query, ...this.form };
      this.$emit("filter", query);
      this.$router.replace({
        path: this.$route.path,
        query,
      });
    },
    async hideShowImage(val) {
      this.printWithImage = val;
    },
    async printPrintout(pv) {
      await this.hideShowImage(pv);

      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("printoutt").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );

      mywindow.document.write("</head><body >");
      mywindow.document.write(divContents);
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1000);
    },
  },
};
</script>

<style scoped>
button[type="submit"] {
  @apply w-full bg-oneGreen text-white font-semibold py-2.5 rounded-lg flex justify-center items-center gap-2 px-2 py-3.5 text-sm;
}
</style>
