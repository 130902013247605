<template>
  <div>
    <div
      class="h-12 lg:h-14 bg-white rounded border border-dashed border-[#CED4DA] relative"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <!-- multiple -->
      <input
        type="file"
        :name="`fields[${id}][]`"
        :id="id"
        class="w-px h-px opacity-0 inset-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        :accept="acceptedTypes"
      />

      <label
        :for="id"
        class="flex gap-2 justify-center items-center cursor-pointer inset-0 absolute py-4"
      >
        <div v-if="file" class="flex justify-between items-center w-full px-4">
          <div class="flex gap-2.5">
            <img
              :src="require('@/assets/images/icons/' + iconType)"
              alt="icons"
              class="w-8 h-8"
            />
            <div class="w-10/12 text-xs lg:text-sm">
              <p class="text-[#484848] truncate font-bold">
                {{ file.name }}
              </p>
              <p class="text-[#949494]">
                {{ formatFileSize(file.size) }}
              </p>
            </div>
          </div>

          <button
            class="ml-2 text-xs py-1.5 px-3 underline text-red-500"
            type="button"
            @click="remove()"
            title="Remove file"
          >
            remove
          </button>
        </div>
        <div v-else class="flex items-center gap-2.5">
          <img src="@/assets/images/icons/cloud_upload.svg" alt="icons" />
          <div>
            Drag and drop or <span class="underline text-blue-400">browse</span>
          </div>
        </div>
      </label>
    </div>

    <div>
      <small class="req" v-if="sizeFailed">
        File size is bigger than 2MB
      </small>
      <small class="req" v-if="typeFailed">
        File type is not a valid format
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadInput",
  props: {
    value: {
      default: () => null,
      required: true,
    },

    id: {
      type: String,
      required: true,
    },
    acceptedTypes: {
      type: String,
      default: "image/png, image/jpeg, image/jpg, application/pdf",
    },
  },
  data() {
    return {
      fileSizeLimit: 2 * 1024 ** 2, // 2MB
      typeFailed: false,
      file: null,
      sizeFailed: false,
      proceeding: false,
    };
  },
  computed: {
    iconType() {
      let icon = "jpeg.svg";
      if (this.file && this.file.type?.includes("pdf")) {
        icon = "pdf.svg";
      }
      return icon;
    },
    formReady() {
      return Boolean(this.file);
    },
  },
  methods: {
    onChange() {
      if (
        this.$refs.file.files[0] &&
        this.$refs.file.files[0].size > this.fileSizeLimit
      ) {
        this.sizeFailed = true;
      } else {
        this.sizeFailed = false;
      }
      if (
        this.$refs.file.files[0] &&
        !this.acceptedTypes.includes(this.$refs.file.files[0].type)
      ) {
        this.typeFailed = true;
      } else {
        this.typeFailed = false;
      }

      if (!this.typeFailed && !this.sizeFailed) {
        this.file = this.$refs.file.files[0];
        this.$emit("input", this.file);
      }
    },
    remove() {
      this.$refs.file.value = "";
      this.file = null;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("border-green-300")) {
        event.currentTarget.classList.remove("border-gray-100");
        event.currentTarget.classList.add("border-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("border-gray-100");
      event.currentTarget.classList.remove("border-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("border-gray-100");
      event.currentTarget.classList.remove("border-green-300");
    },
    formatFileSize(fileSizeInBytes) {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;

      if (fileSizeInBytes >= gigabyte) {
        return `${(fileSizeInBytes / gigabyte).toFixed(2)} GB`;
      } else if (fileSizeInBytes >= megabyte) {
        return `${(fileSizeInBytes / megabyte).toFixed(2)} MB`;
      } else if (fileSizeInBytes >= kilobyte) {
        return `${(fileSizeInBytes / kilobyte).toFixed(2)} KB`;
      } else {
        return `${fileSizeInBytes} bytes`;
      }
    },
  },
};
</script>

<style></style>
