<template>
  <div v-if="open" id="modal">
    <transition name="slidey" appear>
      <div id="modal-body">
        <section
          class="w-full lg:w-7/12 max-w-[700px] min-h-[200px] max-h-[60vh] lg:max-h-[87vh] overflow-scroll p-4 lg:p-6 rounded-lg bg-white fancy"
        >
          <slot name="title"></slot>

          <slot name="content"></slot>
        </section>

        <section
          class="p-4 lg:p-6 rounded-lg bg-white w-full lg:w-[250px] min-h-[184px] flex flex-col gap-6"
        >
          <button
            @click="$emit('close')"
            class="border border-dkgray w-full py-2.5 text-center text-dkgray rounded-lg"
          >
            Close
          </button>
          <slot name="action"></slot>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ActionModal",

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
#modal {
  @apply h-full fixed bottom-0 right-0 left-0 w-full bg-black/50;
  z-index: 8;
}
#modal-body {
  @apply flex flex-col lg:flex-row lg:justify-center items-start gap-4 mt-24 px-2;
}
.z-5 {
  z-index: 5;
}
.fancy::-webkit-scrollbar {
  display: block !important;
}
.fancy {
  scrollbar-width: thin !important;
  scrollbar-color: #6969dd #810e0e;
}
</style>
