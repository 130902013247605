<template>
  <div
    class="flex flex-row lg:flex-col justify-center"
    :style="`--steps: ${vsteps.length - 1}`"
  >
    <div v-for="x in vsteps.length - 1" :key="x" class="holder">
      <div
        :class="`box ${step >= x ? 'bvisit' : ''} ${
          $route.name === vsteps[x - 1].link ? 'bactive' : ''
        }`"
      >
        {{ x }}
      </div>
      <div :class="`line ${step > x ? 'lactive' : ''}`"></div>
    </div>

    <div
      :class="`box ${
        $route.name === vsteps[vsteps.length - 1].link ? 'bactive' : ''
      }`"
    >
      {{ vsteps.length }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BioDataNav",
  props: {
    vsteps: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    step() {
      return this.$store.state.staff.bioStep ?? 1;
    },
  },
};
</script>

<style scoped>
.holder {
  @apply flex flex-row lg:flex-col items-center justify-center w-[calc((100%-28px)/var(--steps))] lg:w-7 lg:h-[calc((343px-28px)/var(--steps))];
}
.box {
  @apply p-2.5 w-7 h-7 flex items-center justify-center text-litgray border-2 border-litgray;
  border-radius: 5px;
}
.bvisit {
  @apply border-2 border-[#DEFFDE] bg-[#DEFFDE] text-oneGreen;
}
.bactive {
  @apply border-2 border-oneGreen bg-[#DEFFDE] text-oneGreen font-bold;
}
.line {
  @apply w-full h-0.5 lg:w-0.5 lg:h-full bg-[#C7C7C7];
}
.lactive {
  @apply bg-oneGreen;
}
</style>
