<template>
  <section class="mt-6">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-7">
      <!-- <small-box class="bg-[#F4F4F4]">
        <template #title>CATEGORIES</template>
        <template #value>2,000,000</template>
      </small-box> -->

      <small-box class="bg-[#F4F4F4]">
        <template #title>MDA’s</template>
        <template #value>
          <spinner
            v-if="processing"
            class="w-5 h-5"
            color="bg-gray-800"
          ></spinner>
          <span v-else>{{ dashboardData.totalMda }}</span>
        </template>
      </small-box>

      <small-box class="bg-[#DEFFDE]">
        <template #title>DEPARTMENTS</template>
        <template #value>
          <spinner
            v-if="processing"
            class="w-5 h-5"
            color="bg-gray-800"
          ></spinner>
          <span v-else>{{ dashboardData.totalDepartment }}</span>
        </template>
      </small-box>

      <small-box class="bg-[#F4F4F4]">
        <template #title>COMPLETED ONLINE VERIFICATION</template>
        <template #value>
          <spinner
            v-if="processing"
            class="w-5 h-5"
            color="bg-gray-800"
          ></spinner>
          <span v-else>{{ dashboardData.totalUsers }}</span>
        </template>
      </small-box>

      <small-box class="bg-yellow-200">
        <template #title>TOTAL RECORDS</template>
        <template #value>
          <spinner
            v-if="processing"
            class="w-5 h-5"
            color="bg-gray-800"
          ></spinner>
          <span v-else>{{ dashboardData.totalRecords }}</span>
        </template>
        <template #action>
          <CustomSelect
            :options="staffTypes"
            title="Staff Categroy"
            class="w-40"
            v-model="category"
          />
        </template>
      </small-box>

      <small-box class="bg-[#F4F4F4]">
        <template #title>VERIFIED RECORDS</template>
        <template #value>
          <spinner
            v-if="processing"
            class="w-5 h-5"
            color="bg-gray-800"
          ></spinner>
          <span v-else>{{ dashboardData.verified }}</span>
        </template>
      </small-box>

      <small-box class="bg-[#D7E0FF]">
        <template #title>UNVERIFIED RECORDS</template>
        <template #value>
          <spinner
            v-if="processing"
            class="w-5 h-5"
            color="bg-gray-800"
          ></spinner>
          <span v-else>{{ dashboardData.unverified }}</span>
        </template>
      </small-box>
    </div>
  </section>
</template>

<script>
import SmallBox from "@/components/staff/dashboard/SmallBox.vue";
import CustomSelect from "@/components/form/CustomSelect.vue";
export default {
  name: "AdminSmallBoxes",
  components: {
    SmallBox,
    CustomSelect,
  },

  data() {
    return {
      category: null,
      processing: false,
      staffTypes: [
        { label: "All", value: "all" },
        ...this.$store.state.admin.staffTypes.map((el) => ({
          label: el.name,
          value: el.slug,
        })),
      ],
      dashboardData: {
        totalMda: 0,
        totalDepartment: 0,
        totalUsers: 0,
        totalRecords: 0,
        verified: 0,
        unverified: 0,
      },
    };
  },
  watch: {
    category(val) {
      const stafftype = this.$store.state.admin.staffTypes.find(
        (el) => el.slug === val
      );

      if (stafftype) this.fetchAdminDashboard(stafftype._id);
      else this.fetchAdminDashboard();
    },
  },
  computed: {},
  mounted() {
    this.fetchAdminDashboard();
  },
  methods: {
    async fetchAdminDashboard(stafftypeId = undefined) {
      this.processing = true;
      try {
        const res = await this.$http.get("/a/dashboard", {
          params: { staffTypeId: stafftypeId ? stafftypeId : undefined },
        });

        if (!res) {
          return;
        }

        const { data } = res.data;
        this.processing = false;
        this.dashboardData = data;
      } catch (error) {
        console.log({ error });
        this.processing = false;
      }
    },
  },
};
</script>
