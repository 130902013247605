<template>
  <div class="w-full">
    <h2 class="text-[#484848] text-[24px] mb-5">Records for Authorization</h2>

    <api-table
      apiUrl="/records/authorization"
      :fields="fields"
      :show-page="true"
      :show-search="true"
      placeholder="Search by name, phone number or email"
    >
      <template slot="approvalStatus" slot-scope="props">
        <span
          v-if="props.rowData.approvalStatus === 'pending'"
          class="text-gray-500 font-bold uppercase"
        >
          {{ props.rowData.approvalStatus }}
        </span>

        <span
          v-if="props.rowData.approvalStatus === 'accepted'"
          class="text-oneGreen font-bold uppercase"
        >
          {{ props.rowData.approvalStatus }}
        </span>
      </template>

      <template slot="action" slot-scope="props">
        <!-- v-can="'get-one-user'" -->
        <router-link
          v-if="props.rowData.verificationStepStatus == 'completed'"
          :to="{
            name: 'Admin Bio Data',
            params: { id: props.rowData.id },
            query: { xwd: 'judhm' },
          }"
          class="bg-oneGreen text-center text-white text-xs rounded py-1 px-2 mr-2"
        >
          Verify
        </router-link>
      </template>
    </api-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "AuthorizationNeeded",

  data() {
    // let sv = "hidden lg:table-cell";
    const tc = "bg-green-100 py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "name",
          title: "Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 uppercase`,
        },
        {
          name: "salaryGradeLevel",
          title: "Grade Level",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "verificationCode",
          title: "Verification Number",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },

        {
          name: "submissionDate",
          title: "Date of submission",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("DD-MM-YYYY"),
        },
        {
          name: "approvalStatus",
          title: "Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },

  methods: {
    onCellClicked() {
      this.$router.push({ name: "Bio Data" });
    },
  },
};
</script>
