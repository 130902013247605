<template>
  <div class="py-10 px-4 lg:pl-22">
    <h3 class="text-idBlued text-2xl font-bold">Staff Clock-In Setup</h3>
    <p class="text-idBluedLight text-xs">
      Assign an existing staff within Abia state to to location, as their
      primary place for Clock-In and Clock-Out.
    </p>

    <div class="mt-10 bg-litergray rounded-lg p-6 w-full max-w-[700px]">
      <h5 class="text-idBlued text-xs font-bold">Assigned Location</h5>

      <div class="mt-2 bg-twoGreen rounded-xl p-2.5">
        <!-- <div class="infos">
          <p>MDA</p>
          <p>Ministry of Finance</p>
        </div> -->

        <div class="infos">
          <p>Official Office Name</p>
          <p class="uppercase">{{ operator?.officeName }}</p>
        </div>

        <div class="infos">
          <p>Official Office Address</p>
          <p>{{ operator?.officeAddress }}.</p>
        </div>
      </div>

      <form
        @submit.prevent="proceed"
        class="flex justify-between items-end gap-2 mt-6"
      >
        <div class="w-full">
          <label for="verificationCode">Verification ID</label>
          <input
            type="text"
            class="input"
            autofocus
            placeholder="Input verification ID"
            v-model="form.verificationCode"
          />
        </div>
        <button
          type="submit"
          class="bg-oneGreen text-white rounded-lg px-6 font-semibold py-[13px] flex gap-2"
          :disabled="proceeding || !formReady"
        >
          <span>Start</span>
          <spinner v-if="proceeding"></spinner>
        </button>
      </form>

      <div
        v-if="doneSetup"
        class="bg-red-100 text-red-700 px-2 py-1.5 rounded-lg mt-4 flex justify-between items-center"
      >
        <div>
          <p class="text-sm font-bold">
            The selected staff has already been setup for Clock-In
          </p>
          <p class="text-xs font-semibold">
            To update staff location, please contacct admin
          </p>
        </div>
        <div>
          <button @click="doneSetup = false" class="px-2 py-1 text-lg">
            &times;
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetupStart",

  props: {
    operator: {
      type: Object,
    },
  },

  data() {
    return {
      proceeding: false,
      doneSetup: false,
      form: {
        verificationCode: null,
        // verificationCode: "2736314949",
      },
    };
  },

  computed: {
    formReady() {
      return (
        this.form.verificationCode && this.form.verificationCode.length >= 5
      );
    },
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please Enter Verification ID",
        });
        return;
      }

      try {
        this.proceeding = true;
        const res = await this.$http.get(
          `setup/enroll?search=${this.form.verificationCode}`
        );

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        if (data.code === "S200") {
          this.$swal({
            icon: "error",
            text: data.message,
          });
          return;
        }

        const user = {
          staffPayload: data.staffPayload,
          bvnPayload: data.bvnPayload,
        };

        this.$emit("done", user);
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style scoped>
.infos {
  @apply rounded-lg border border-oneGreen flex justify-between mt-2.5 px-2 py-2 text-xs text-dkgray;
}
</style>
