<template>
  <header>
    <Logo />

    <button
      @click="contactus"
      class="flex gap-2 lg:gap-2.5 p-2 lg:p-2.5 bg-oneGreen/10 rounded items-center"
    >
      <img
        src="@/assets/images/icons/headphone.svg"
        alt="icons"
        class="w-4 lg:w-6 h-4 lg:h-6"
      />
      <span class="font-semibold text-sm lg:text-2pxl text-oneGreen">
        Contact Us
      </span>
    </button>
  </header>
</template>

<script>
import Logo from "@/components/app/Logo.vue";
export default {
  name: "AuthHeader",

  components: {
    Logo,
  },
  methods: {
    contactus() {
      this.$router.push({ name: "Message Us" });
    },
  },
};
</script>

<style scoped>
header {
  @apply flex justify-between px-4 lg:px-40 py-[1.0625rem] lg:py-3 fixed top-0 left-0 right-0;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
</style>
