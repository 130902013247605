<template>
  <div class="px-4 w-full max-w-[750px] mx-auto py-5">
    <vc-date-picker mode="date" is-range v-model="search" :masks="masks">
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex justify-start items-end">
          <div>
            <label for="start">Date</label> <br />
            <input
              id="start"
              :value="inputValue.start"
              v-on="inputEvents.start"
              class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
            />
          </div>

          <button class="ml-2" @click="searchRecords" type="button">
            Search
          </button>
        </div>
      </template>
    </vc-date-picker>

    <div>
      <div
        class="flex justify-between items-center flex-col md:flex-row gap-5 mt-10"
      >
        <div class="small-box bg-litergray">
          <h5>Records Approved</h5>
          <h5
            class="py-2 w-3/5 lg:w-11/12 border-2 border-litgray text-center mt-2 rounded-lg"
          >
            <div
              v-if="gettingAnalysis"
              class="flex justify-center items-center"
            >
              <spinner class="h-5 w-5" color="ansGreen"></spinner>
            </div>

            <span v-else>
              {{ processedRecords.totalAccepted }}
            </span>
          </h5>
        </div>
        <div class="small-box bg-litergray">
          <h5>Records Authorized</h5>
          <h5
            class="py-2 w-3/5 lg:w-11/12 border-2 border-litgray text-center mt-2 rounded-lg"
          >
            <div
              v-if="gettingAnalysis"
              class="flex justify-center items-center"
            >
              <spinner class="h-5 w-5" color="ansGreen"></spinner>
            </div>

            <span v-else>
              {{ processedRecords.totalAuthorized }}
            </span>
          </h5>
        </div>
        <div class="small-box bg-sideBlack">
          <h5 class="text-white font-bold">Rejected Records</h5>
          <h5
            class="py-2 w-3/5 lg:w-11/12 border-2 border-litgray text-center mt-2 rounded-lg text-white"
          >
            <div
              v-if="gettingAnalysis"
              class="flex justify-center items-center"
            >
              <spinner class="h-7 w-7" color="ansGreen"></spinner>
            </div>

            <span v-else>
              {{ processedRecords.totalRejected }}
            </span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileDashboard",
  data() {
    return {
      masks: {
        input: "YYYY-MM-DD",
      },
      gettingAnalysis: false,
      processedRecords: {
        totalAccepted: 0,
        totalAuthorized: 0,
        totalRejected: 0,
      },
      search: {
        start: "",
        end: "",
      },
    };
  },
  created() {
    this.searchRecords();
  },
  methods: {
    async searchRecords() {
      this.gettingAnalysis = true;
      const res = await this.$http.get("/records/processed-stats", {
        params: {
          start_date: this.search.start
            ? new Date(this.search.start)
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .join("-")
            : undefined,
          end_date: this.search.end
            ? new Date(this.search.end)
                .toISOString()
                .replace(/T.*/, "")
                .split("-")
                .join("-")
            : undefined,
        },
      });

      this.gettingAnalysis = false;
      if (!res) {
        return;
      }
      const { data } = res;

      if (data.data) {
        this.processedRecords = data.data;
      } else {
        this.processedRecords = {
          totalAccepted: 0,
          totalAuthorized: 0,
          totalRejected: 0,
        };
      }
    },
  },
};
</script>

<style scoped>
.small-box {
  @apply p-5 w-full flex flex-col justify-center items-center rounded-lg;
}

button[type="button"] {
  @apply w-full lg:w-[80px] bg-[#484848] text-white font-semibold py-1.5 rounded flex justify-center items-center text-[12px];
}
</style>
