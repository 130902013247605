<template>
  <div class="font-mulish">
    <StaffHeader />

    <section class="flex justify-between relative mt-[75px] lg:mt-[82px] z-1">
      <AdminSideNav
        v-if="$store.state.general.sideOpen"
        class="nav-small block lg:hidden w-[250px]"
      />

      <AdminSideNav class="hidden lg:block nav-big w-[250px]" />

      <main class="w-full lg:w-[calc(100%-200px)] bg-white">
        <router-view></router-view>
      </main>
    </section>

    <LoadOverlay v-if="$store.state.general.overlay" />
  </div>
</template>

<script>
import StaffHeader from "@/components/staff/element/Header.vue";
import AdminSideNav from "@/components/admin/element/SideNav.vue";
import LoadOverlay from "@/components/app/LoadOverlay";
export default {
  name: "Scaffold",

  components: {
    StaffHeader,
    AdminSideNav,
    LoadOverlay,
  },

  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style scoped>
.nav-small {
  height: calc(100vh - 74px);
}
.nav-big {
  height: calc(100vh - 80px);
}
main {
  height: calc(100vh - 90px);
  overflow-y: scroll;
}
</style>
