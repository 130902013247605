<template>
  <section>
    <!-- <button
      v-if="!doc"
      @click="$emit('next')"
      class="bg-oneGreen text-sm p-3 rounded-lg text-white w-full"
    >
      Continue
    </button> -->

    <div v-if="doc && doc.rejectedBy" class="my-2 flex justify-end">
      <p class="text-sm text-red-500 underline">Record rejected</p>
    </div>

    <div v-else-if="doc && doc.approvedBy" class="my-2 flex justify-end">
      <p class="text-sm text-blue-500 underline">Record approved</p>
    </div>

    <div class="flex justify-between gap-6 mt-7 mb-3">
      <!-- View mode -->
      <template v-if="$store.state.admin.isViewMode">
        <button
          @click="prev()"
          class="bg-litergray border-2 border-litgray text-sm p-3 rounded-lg w-full"
        >
          Back
        </button>

        <button
          @click="$emit('next')"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white w-full"
        >
          Continue
        </button>
      </template>

      <!--Action mode  -->
      <template v-else>
        <!-- <button
          @click="updateData('rejected')"
          class="bg-red-100 text-sm p-3 rounded-lg w-full"
        >
          Reject Record
        </button> -->

        <button
          v-if="update && doc && !doc.approvedBy && !doc.rejectedBy"
          @click="updateData('requested')"
          v-can="'update-record'"
          class="bg-litergray border-2 border-litgray text-sm p-3 rounded-lg w-full"
        >
          Update Record
        </button>

        <button
          v-if="doc && !doc.approvedBy && !doc.rejectedBy"
          @click="updateData('approved')"
          v-can="'approve-report'"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white w-full"
        >
          Approve and Continue
        </button>

        <button
          v-else-if="doc && doc.approvedBy && !doc.authorizedBy"
          v-can="'authorize-report'"
          @click="updateData('authorized')"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white w-full"
        >
          Authorize and Continue
        </button>

        <button
          v-else
          @click="$emit('next')"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white w-full"
        >
          Continue
        </button>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: "ActionButton",

  props: {
    doc: {
      type: Object,
      required: false,
      default: null,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    console.log(this.doc);
  },

  methods: {
    prev() {
      this.$router.go(-1);
    },
    updateData(acn) {
      this.$emit(acn);
    },
  },
};
</script>

<style scoped></style>
