<template>
  <div class="w-full m-auto mb-8 px-5 lg:px-10 mt-5">
    <div class="flex items-center justify-between mb-4">
      <h2 class="text-[#484848] text-2xl font-bold font-mulish">
        Records for Signature
      </h2>
    </div>
    <div>
      <api-table
        apiUrl="/signatories/all-pending-staff"
        :fields="fields"
        :showPage="true"
        :showSearch="true"
        placeholder="Search by verification code"
      >
        <!-- :bus="bus" -->
        <template slot="action" slot-scope="props">
          <div class="flex gap-4 items-center">
            <button
              @click="viewGen(props.rowData)"
              :disabled="props.rowData.getting"
              class="bg-blue-500 text-center text-white text-xs rounded py-1 px-4 flex items-center gap-2"
            >
              View
              <spinner v-if="props.rowData.getting" class="w-4 h-4"></spinner>
            </button>

            <button
              v-can="'sign-gen69'"
              v-if="props.rowData.signatureStatus === 'requested'"
              @click="viewGen(props.rowData)"
              :disabled="props.rowData.getting"
              class="bg-oneGreen text-center text-white text-xs rounded py-1 px-4 flex items-center gap-2"
            >
              Sign
              <spinner v-if="props.rowData.getting" class="w-4 h-4"></spinner>
            </button>
            <small v-else class="text-oneGreen font-bold text-sm">
              Signed
            </small>
          </div>
        </template>
      </api-table>
    </div>

    <ActionModal :open="openGen" @close="openGen = false">
      <template #content>
        <section id="genPrintout">
          <Gen69 :staff-data="staffData" :getting="getting" />
        </section>
      </template>

      <template #action>
        <!-- <button class="bg-[#FFECEC] text-sm p-3 rounded-lg">Reject</button> -->

        <button
          v-can="'sign-gen69'"
          v-if="staffData && staffData.signatureStatus === 'requested'"
          @click="sendOtp()"
          :disabled="sending"
          class="bg-oneGreen text-sm p-3 rounded-lg text-white flex gap-2 justify-center"
        >
          <span>Sign</span>
          <spinner v-if="sending" class="w-5 h-5"></spinner>
        </button>

        <button
          v-if="staffData && staffData.signatureStatus === 'signed'"
          @click="printOutGen"
          class="bg-idBlued text-sm p-3 rounded-lg text-white"
        >
          Print
        </button>
      </template>
    </ActionModal>

    <modal v-model="openToken" v-if="openToken">
      <TokenVerify
        @token="signGen"
        @resend="sendOtp"
        :resending="sending"
        :processing="signning"
        :message="otpMessage"
      />
    </modal>
  </div>
</template>

<script>
// import Vue from "vue";
import ActionModal from "@/components/admin/records/ActionModal.vue";
import Gen69 from "@/components/admin/staff/Gen69.vue";
import TokenVerify from "@/components/admin/signature/TokenVerify.vue";

export default {
  name: "ListSignature",
  components: {
    ActionModal,
    Gen69,
    TokenVerify,
  },
  data() {
    const tc = "bg-[#F2F2F2] py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      // bus: new Vue(),
      openGen: false,
      openToken: false,
      getting: false,
      sending: false,
      signning: false,
      staffData: {},
      otpMessage: null,
      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "staffName",
          title: "Name",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4 uppercase`,
        },
        {
          name: "mdaName",
          title: "MDA",
          titleClass: `${tc} text-left pl-0 `,
          dataClass: `${dc} `,
        },
        {
          name: "createdAt",
          title: "Date Created",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
          formatter: (val) =>
            !val ? val : this.moment(val).format("MMM DD. YYYY"),
        },
        {
          name: "action",
          title: "Action",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
      ],
    };
  },
  methods: {
    async viewGen(dt) {
      try {
        this.openGen = true;
        dt.getting = true;
        this.getting = true;
        const res = await this.$http.post(`service-staff/print-out/${dt.id}`, {
          baseUrl: `${window.location.origin}/gen69`,
        });

        this.getting = false;
        // dt.getting = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.staffData = data.data;
      } catch (error) {
        this.getting = false;
        dt.getting = false;
        console.log({ error });
      }
    },

    async sendOtp() {
      try {
        this.sending = true;

        const res = await this.$http.post(
          `/signatories/sign-form/${this.staffData.id}`
        );

        this.sending = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.otpMessage = data.message;

        // this.openGen = false;
        this.openToken = true;
      } catch (error) {
        this.sending = false;
      }
    },

    async signGen(dt) {
      try {
        this.signning = true;

        const res = await this.$http.post("/signatories/validate-otp", {
          id: this.staffData.id,
          token: dt,
        });

        this.signning = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.staffData.signatureStatus = "signed";
        this.$events.fire("table-refresh");
        this.openGen = false;
        this.openToken = false;
      } catch (error) {
        this.signning = false;
      }
    },

    printOutGen() {
      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("genPrintout").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );
      mywindow.document.write("</head><body>");

      const links = document.querySelectorAll("style");
      links.forEach(function (link) {
        mywindow.document.head.appendChild(link.cloneNode(true));
      });

      mywindow.document.write("<div style='margin-top:20px;'></div>");
      mywindow.document.write(divContents);
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.btn-elip:focus + .dropdown,
.dropdown:hover {
  @apply block;
}
</style>
