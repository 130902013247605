<template>
  <div>
    <section id="faceio-modal" class="z-[9999] w-[660px] mx-auto"></section>

    <section
      class="flex flex-col lg:flex-row justify-center items-center lg:items-stretch gap-5 py-10 px-4"
    >
      <BvnCard
        :userData="userData.bvnPayload"
        class="bg-litergray w-full lg:w-1/3"
      />

      <BiometricCard
        :userData="userData.staffPayload"
        class="bg-treeGreen w-full lg:w-1/3"
      />

      <CaptureCard
        v-model="capturedImage"
        :reportSubmitted="reportSubmitted"
        :estimatedData="estimatedData"
        class="bg-litergray w-full lg:w-1/3"
      />
    </section>

    <hr class="pt-9" />

    <section
      v-if="isCaptured && !estimatedData"
      class="px-4 lg:mx-20 w-full max-w-[750px] pb-10"
    >
      <h5 class="text-dkgray font-bold text-xl">
        Choose an option below to proceed
      </h5>

      <div class="">
        <div
          v-for="(reason, i) in options"
          :key="i"
          class="flex gap-4 mt-4 bg-litergray px-4 py-1 rounded-full"
        >
          <input
            type="radio"
            name="selectedReason"
            :id="`selectedReason${i}`"
            :value="reason"
            v-model="selectedReason"
            class="input"
          />
          <label :for="`selectedReason${i}`">
            <p class="text-dkgray font-bold">{{ reason.title }}</p>
            <p class="text-mdgray font-medium text-xs">
              {{ reason.message }}
            </p>
          </label>
        </div>

        <div v-if="imageNotMatch" class="mt-5 bg-litergray p-4 rounded-xl">
          <label for="reason">
            More information about the Mismatch
            <span class="text-red-600">*</span>
          </label>
          <textarea
            name="misMatchNote"
            v-model="misMatchNote"
            id="misMatchNote"
            rows="3"
          ></textarea>
          <small class="text-purple-600 font-bold">
            Do specify what you observe, this report will be reviewed
          </small>
        </div>
      </div>

      <div class="flex justify-between mt-10 bg-litergray p-4 rounded-xl">
        <button
          @click="$emit('prev')"
          class="border border-gray-700 text-idBlued px-5 py-2 rounded-lg"
        >
          <span>&#9204;</span>
          Restart
        </button>

        <div>
          <button
            v-if="imageNotMatch"
            @click="report('bad')"
            :disabled="proceeding || !misMatchNote"
            class="bg-idBlued text-white px-8 py-2 rounded-lg flex items-center gap-3"
          >
            Submit Report
            <spinner v-if="proceeding"></spinner>
          </button>

          <button
            v-else
            :disabled="proceeding || !selectedReason"
            @click="report('good')"
            class="bg-oneGreen text-white px-8 py-2 rounded-lg flex items-center gap-3"
          >
            Proceed to Clock-in Capture
            <spinner v-if="proceeding"></spinner>
          </button>
        </div>
      </div>
    </section>

    <section v-if="estimatedData" class="px-4 w-full pb-10">
      <div class="flex justify-end bg-litergray p-4 rounded-xl">
        <button
          @click="proceed"
          class="bg-oneGreen text-white font-bold py-2.5 px-20 rounded-lg flex gap-3"
        >
          <span>Complete</span>
          <spinner v-if="proceeding"></spinner>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import faceIO from "@faceio/fiojs";
import BiometricCard from "./BiometricCard.vue";
import BvnCard from "./BvnCard.vue";
import CaptureCard from "./CaptureCard.vue";

let faceio;

export default {
  name: "SetupCompare",

  components: {
    BiometricCard,
    BvnCard,
    CaptureCard,
  },

  props: {
    userData: {
      type: Object,
    },
  },

  computed: {
    imageNotMatch() {
      return this.selectedReason && !this.selectedReason?.isMatch;
    },
  },

  data() {
    return {
      isMatch: false,
      isCaptured: false,
      isVerified: false,
      validating: false,
      capturedImage: null,
      selectedReason: null,
      misMatchNote: null,
      proceeding: false,
      estimatedData: null,
      reportSubmitted: false,
      validateResult: null,
      options: [
        {
          title: "Picture Mismatch",
          isMatch: false,
          message:
            "Pick this option if the BVN and Biometric pictures do not match.",
        },
        {
          title: "Person Mismatch",
          isMatch: false,
          message:
            "Pick this option  only if the person present is different from that visible on BVN and Biometric pictures.",
        },
        {
          title: "Perfect Match",
          isMatch: true,
          message:
            "Pick this option if the person present is the same with the visible data from BVN and Biometric pictures.",
        },
      ],
    };
  },

  watch: {
    capturedImage(n) {
      if (!n) return;
      this.isCaptured = true;
    },
  },

  mounted() {
    faceio = new faceIO("fioa7372");
  },

  methods: {
    async validateImage() {
      if (!this.capturedImage) {
        this.$swal({
          icon: "error",
          text: "Please make sure you have captured an image",
        });
        return;
      }

      try {
        this.validating = true;
        const res = await this.$http.post("/setup/photo-verify", {
          verificationCode: this.userData.staffPayload.verificationCode,
          photograph: this.userData.staffPayload.photograph,
          livePhotoUrl: this.capturedImage,
        });

        this.validating = false;
        if (!res) {
          return;
        }

        const { data } = res;
        console.log(data);
        this.isVerified = true;
      } catch (error) {
        this.validating = false;
        console.log({ error });
      }
    },

    async report(t) {
      if (!this.selectedReason) {
        this.$swal({
          icon: "error",
          text: "Please selected an option",
        });
        return;
      }

      try {
        this.proceeding = true;
        const res = await this.$http.post("/send-report", {
          verificationCode: this.userData?.staffPayload?.verificationCode,
          title: this.selectedReason.title,
          message: this.misMatchNote,
          description: this.selectedReason.message,
          biometricPhotograph: this.userData?.staffPayload?.photograph,
          liveDataImage: this.capturedImage,
          isMatch: this.selectedReason.isMatch,
        });

        this.proceeding = false;
        if (!res) {
          setTimeout(() => {
            // this.$emit("prev");
          }, 1000);
          return;
        }

        const { data } = res;
        this.reportSubmitted = true;

        if (t === "bad") {
          this.$emit("prev");

          this.$swal({
            icon: "success",
            text: data.message,
          });
          return;
        }
        console.log(data);

        this.enrollNewUser();
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },

    async proceed() {
      try {
        this.proceeding = true;
        const res = await this.$http.post("/setup/assign-staff", {
          employeeId: this.userData?.staffPayload?.staffId,
          faceId: this.estimatedData?.facialId,
        });

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$emit("prev");
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },

    async enrollNewUser() {
      try {
        const fiRes = await faceio.enroll({
          locale: "auto",
          userConsent: false,
          payload: {
            whoami: this.userData.staffPayload.verificationCode,
            email: this.userData.staffPayload.email,
          },
        });
        this.estimatedData = fiRes;
        faceio.restartSession();
      } catch (errCode) {
        this.$swal({
          icon: "error",
          text: this.handleError(errCode),
        });
        faceio.restartSession();
      }
    },

    handleError(errCode) {
      const fioErrCode = faceio.fetchAllErrorCodes();
      switch (errCode) {
        case fioErrCode.PERMISSION_REFUSED:
          return "Access to the Camera stream was denied by the end user";
        case fioErrCode.NO_FACES_DETECTED:
          return "No faces were detected during the enroll or authentication process";
        case fioErrCode.UNRECOGNIZED_FACE:
          return "Unrecognized face on this application's Facial Index";
        case fioErrCode.MANY_FACES:
          return "Two or more faces were detected during the scan process";
        case fioErrCode.FACE_DUPLICATION:
          return "User enrolled previously (facial features already recorded). Cannot enroll again!";
        case fioErrCode.MINORS_NOT_ALLOWED:
          return "Minors are not allowed to enroll on this application!";
        case fioErrCode.PAD_ATTACK:
          return "Presentation (Spoof) Attack (PAD) detected during the scan process";
        case fioErrCode.FACE_MISMATCH:
          return "Calculated Facial Vectors of the user being enrolled do not match";
        case fioErrCode.WRONG_PIN_CODE:
          return "Wrong PIN code supplied by the user being authenticated";
        case fioErrCode.PROCESSING_ERR:
          return "Server side error";
        case fioErrCode.UNAUTHORIZED:
          return "Your application is not allowed to perform the requested operation (eg. Invalid ID, Blocked, Paused, etc.). Refer to the FACEIO Console for additional information";
        case fioErrCode.TERMS_NOT_ACCEPTED:
          return "Terms & Conditions set out by FACEIO/host application rejected by the end user";
        case fioErrCode.UI_NOT_READY:
          return "The FACEIO Widget could not be (or is being) injected onto the client DOM";
        case fioErrCode.SESSION_EXPIRED:
          return "Client session expired. The first promise was already fulfilled but the host application failed to act accordingly";
        case fioErrCode.TIMEOUT:
          return "Ongoing operation timed out (eg, Camera access permission, ToS accept delay, Face not yet detected, Server Reply, etc.)";
        case fioErrCode.TOO_MANY_REQUESTS:
          return "Widget instantiation requests exceeded for freemium applications. Does not apply for upgraded applications";
        case fioErrCode.EMPTY_ORIGIN:
          return "Origin or Referer HTTP request header is empty or missing";
        case fioErrCode.FORBIDDDEN_ORIGIN:
          return "Domain origin is forbidden from instantiating fio.js";
        case fioErrCode.FORBIDDDEN_COUNTRY:
          return "Country ISO-3166-1 Code is forbidden from instantiating fio.js";
        case fioErrCode.SESSION_IN_PROGRESS:
          return "Another authentication or enrollment session is in progress";
        default:
          return "No error";
      }
    },
  },
};
</script>

<style scoped></style>
