<template>
  <div>
    <label for="" class=""> {{ field.label }} </label>
    <p
      class="w-full bg-dtgray border border-litgray px-2 h-10 flex items-center rounded mb-4 uppercase"
    >
      <span v-if="!field.type || field.type === 'text'">{{ field.value }}</span>
      <span v-else-if="field.type === 'date'">
        {{ field.value | moment("Do MMM YYYY") }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "DataField",
  props: {
    field: {
      type: Object,
      default: () => ({
        value: "",
        label: "",
        type: "text",
      }),
    },
  },
};
</script>
