<template>
  <nav>
    <ul>
      <li v-for="(nav, i) in navs" :key="i" v-can="nav.permit">
        <router-link
          :class="{ disabled: disabled }"
          :to="{ name: nav.link }"
          active-class="nav-active"
          :disabled="disabled"
          :event="!disabled ? 'click' : ''"
        >
          {{ nav.title }}
          <span
            v-if="nav?.count"
            class="ml-3 bg-[#8D8D8D] rounded-md text-[#fff] px-2 text-[8px] badge-active"
            >{{ nav.count }}</span
          >
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "HorizontalNav",

  props: {
    navs: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
nav {
  @apply sticky overflow-x-scroll top-0 right-0 left-0 w-full h-[80px];
  z-index: 8;
}
ul {
  @apply flex w-max lg:w-max h-auto bg-[#F9F9F9] relative;
}
ul li {
  @apply relative;
}
a {
  @apply flex items-center border-r border-gray-200 font-bold relative px-4 h-14 lg:px-10 text-mdgray bg-[#F9F9F9] text-xs lg:text-base;
}
.nav-active {
  @apply bg-[#DEFFDE] text-oneGreen;
}

.nav-active .badge-active {
  @apply bg-[#187A33] text-[#fff];
}
.nav-active::after {
  content: "";
  z-index: 7;
  @apply absolute left-1/2 -translate-x-1/2 translate-y-full;
  width: 0;
  height: 0;
  border-left: 1.875rem solid transparent;
  border-right: 1.875rem solid transparent;
  border-top: 30px solid #deffde;
  /*  */
}
.disabled {
  /* opacity: 0.5; */
  pointer-events: none;
}
</style>
