<template>
  <div class="w-full z-200 relative">
    <div
      v-if="loading"
      class="flex justify-center items-center mt-3 w-full h-screen"
    >
      <spinner class="h-20 w-20" color="ansGreen"></spinner>
    </div>

    <div v-else class="">
      <ProfileActions :user-data="userData" @open-modal="showModal = true" />

      <ProfileDetails
        :isPersonal="isPersonal"
        :userData="userData"
        :permissionName="permissionName"
        :mdaNames="mdaNames"
      />
    </div>

    <!-- modal -->
    <ProfileModalEdit
      :userData="userData"
      v-if="showModal"
      @close-modal="closeEdit"
      @initialize-data="updateData"
    />
    <ProfileDashboard />
  </div>
</template>
<script>
import ProfileDetails from "@/components/admin/profile/ProfileDetails.vue";
import ProfileActions from "@/components/admin/profile/ProfileActions.vue";
import ProfileModalEdit from "@/components/admin/profile/ProfileEdit.vue";
import ProfileDashboard from "@/components/admin/profile/ProfileDashboard.vue";
export default {
  name: "Profile",
  components: {
    ProfileDetails,
    ProfileModalEdit,
    ProfileDashboard,
    ProfileActions,
  },
  data() {
    return {
      loading: false,

      showModal: false,

      currentEndpoint: null,

      userData: {},
      mdas: [],
      permissions: [],

      userId: null,

      isPersonal: false,
    };
  },
  computed: {
    adminID() {
      return `${this.$store.getters["general/userId"]}`;
    },
    mdaNames() {
      return this.mdas.map((md) => md.name).join(", ");
    },
    permissionName() {
      return this.permissions
        .map((perm) => perm.action.split("-").join(" ").toUpperCase())
        .join(", ");
    },
  },
  created() {
    this.userId = this.$route.params.id;
    if (!this.userId) {
      this.$router.push({ name: "Admin List Users" });
      return;
    }

    let url = `/users/${this.userId}`;
    this.isPersonal = false;

    if (this.userId === this.adminID) {
      url = "/users/profile";
      this.isPersonal = true;
    }

    this.getData(url);
  },

  methods: {
    closeEdit() {
      this.showModal = false;
    },

    updateData() {
      this.getData(this.currentEndpoint);
      this.showModal = false;
    },

    async getData(url) {
      try {
        this.currentEndpoint = url;
        this.getting = true;
        this.loading = true;
        const res = await this.$http.get(url);

        this.getting = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.userData = data.data;

        this.mdas = data.data.mdas;
        this.permissions = data.data.permissions;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.getting = false;
      }
    },
  },
};
</script>
<style scoped></style>
