<template>
  <section>
    <h2 class="text-[#484848] font-bold">Created Roles (4)</h2>

    <div class="overflow-x-scroll w-full mt-2">
      <my-table
        records="2000"
        displayCount="20"
        :fields="fields"
        :tableData="tableData"
        :showPage="false"
        :showSearch="false"
        :bus="bus"
      >
        <template slot="action" slot-scope="props">
          <div class="flex gap-3">
            <button
              class="px-2.5 py-1.5 rounded"
              @click="onCellClicked(props.rowData)"
            >
              <img src="@/assets/images/icons/btnactionicon.svg" alt="" />
            </button>
          </div>
        </template>

        <template slot="role">
          <div class="flex gap-3">
            <p class="px-2.5 py-1.5 text-[#8D8D8D] bg-[#F2F2F2] rounded">
              Authoriser
            </p>
          </div>
        </template>

        <template slot="status" slot-scope="props">
          <span
            v-if="props.rowData.status === 1"
            class="text-red-600 text-[13px] font-bold"
            >Disabled</span
          >
          <span v-else class="text-green-600 text-[13px] font-bold"
            >Active</span
          >
        </template>

        <template slot="accname">
          <div class="flex justify-start items-center">
            <div class="">
              <h4 class="text-[#484848] text-[14px] font-bold">
                Abengowe Clement
              </h4>
              <p class="text-[#8D8D8D] text-[12px] font-normal">
                Abengowelement@gmail.com
              </p>
            </div>
          </div>
        </template>
      </my-table>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

export default {
  name: "DashUsersTable",

  data() {
    const tc = "bg-[#F2F2F2] py-3.5 text-cadet font-semibold text-sm";
    const dc = "py-2 text-xs text-romanSilver";
    return {
      bus: new Vue(),

      fields: [
        {
          name: "id",
          title: "S/N",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "accname",
          title: "Account Name ",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "v_number",
          title: "Verification Number",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "role",
          title: "Role",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },

        {
          name: "status",
          title: "Status",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "created_by",
          title: "Created By",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        {
          name: "date_created",
          title: "Date created",
          titleClass: `${tc} text-left pl-4 `,
          dataClass: `${dc} text-left pl-4`,
        },
        // {
        //   name: "action",
        //   title: "Action",
        //   titleClass: `${tc} text-left pl-4 `,
        //   dataClass: `${dc} text-left pl-4`,
        // },
      ],
      tableData: [
        {
          id: 1,
          accname: "Raphael",
          v_number: "9375828748",
          created_by: "Raphael",
          date_created: "30/04/2023, 08:30PM",
        },
        {
          id: 2,
          accname: "Raphael",
          v_number: "9375828748",
          created_by: "Raphael",
          date_created: "30/04/2023, 08:30PM",
        },
        {
          id: 3,
          accname: "Raphael",
          v_number: "9375828748",
          created_by: "Raphael",
          date_created: "30/04/2023, 08:30PM",
        },
      ],
    };
  },
};
</script>
