<template>
  <div class="relative font-mulish">
    <section
      class="w-full sticky bg-litergray flex md:justify-between items-center px-3 lg:px-6 py-2 flex-col md:flex-row overflow-x-scroll top-0 right-0 left-0 z-100 shadow-sm"
    >
      <div class="flex items-center gap-2">
        <button
          @click="$router.push({ name: 'Location Records' })"
          class="px-3 py-1 text-gray-500 text-center text-lg"
        >
          &#9204;
        </button>
        <h4 class="font-bold text-xl text-dkgray">
          {{ $store.state.admin.locationName }}
        </h4>
      </div>
    </section>

    <section class="sticky top-[52px] w-full">
      <HorizontalNav :navs="tabs" :disabled="false" />
    </section>

    <section class="w-full mx-auto px-4 lg:px-10 mt-2">
      <router-view></router-view>
    </section>
  </div>
</template>
<script>
import HorizontalNav from "@/components/staff/verification/HorizontalNav.vue";

export default {
  name: "Location",
  components: {
    HorizontalNav,
  },

  data() {
    return {
      location: {
        name: "Ministry of Works Umuahia",
      },
      tabs: [
        {
          title: "Location Details",
          link: "Location Details",
          permit: "get-location-details",
        },
        {
          title: "Staff List",
          link: "Location Staffs",
          permit: "get-location-staffs",
        },
        {
          title: "Assign Staff",
          link: "Assign Staffs",
          permit: "assign-staff-location",
        },
      ],
    };
  },

  methods: {
    getData() {
      console.log("first");
    },
  },
};
</script>
