<template>
  <div class="w-full lg:w-[700px] m-auto my-5 rounded border-[1px] border-gray">
    <div class="py-3 bg-[#FFECEC] font-bold text-red-900 text-sm text-center">
      Leaving The Fields To Their Default Gives You Details Of All Employees
    </div>
    <div class="bg-[#F9F9F9] p-5 text-[#484848] font-bold">Search Reports</div>

    <form @submit.prevent="proceed">
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="verificationCode" class="text-sm">Verification Code</label>
        <div class="w-full lg:w-[300px]">
          <input
            id="verificationCode"
            type="text"
            placeholder="-- type here --"
            v-model="form.verificationCode"
            class="input"
          />
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="phoneNumber" class="text-sm">Phone Number</label>
        <div class="w-full lg:w-[300px]">
          <input
            id="phoneNumber"
            type="text"
            placeholder="-- type here --"
            v-model="form.phoneNumber"
            class="input"
          />
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="bvn" class="text-sm">BVN</label>
        <div class="w-full lg:w-[300px]">
          <input
            id="bvn"
            type="text"
            placeholder="-- type here --"
            v-model="form.bvn"
            class="input"
          />
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="staffType" class="text-sm">Staff Type</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="staffType"
            label="slug"
            :reduce="(b) => b._id"
            :options="$store.state.admin.staffTypes"
            v-uppercase
            v-model="form.staffTypeId"
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="mdaId" class="text-sm">Ministry/MDA</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="mdaId"
            label="name"
            :reduce="(b) => b._id"
            :options="$store.state.staff.mdas"
            v-uppercase
            v-model="form.mdaId"
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="departmentCode" class="text-sm">Department</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="departmentCode"
            label="departmentName"
            :reduce="(b) => b._id"
            :options="departments"
            :filterable="false"
            v-model="form.departmentId"
            @search="searchDepartments"
            placeholder="-- type here --"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No results found for <em>{{ search }}</em
                >.
              </template>
              <span v-else clas="text-gray-400 lowercase text-xs">
                Start typing to search for your department
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="" class="text-sm">Status</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="verificatonStatus"
            label="label"
            :reduce="(b) => b.value"
            :options="verificationStatus"
            v-model="form.status"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="grade" class="text-sm">Grade Level</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="grade"
            label="grade"
            :reduce="(b) => b.grade"
            v-model="form.salaryGradeLevel"
            :options="$store.state.staff.salaryGradeLevels"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>

      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="" class="text-sm">Date Of Verification (DD/MM/YYYY) </label>
        <div class="w-full lg:w-[300px] grid grid-cols-2 gap-1">
          <div>
            <input
              id="verificationDateFrom"
              type="date"
              v-uppercase
              v-model="form.verificationDateFrom"
              placeholder="."
              class="input"
            />
          </div>
          <div class="flex items-center gap-1">
            <span class="">To</span>
            <input
              id="verificationDateTo"
              type="date"
              v-uppercase
              v-model="form.verificationDateTo"
              class="input"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="" class="text-sm">Date Of Registration (DD/MM/YYYY)</label>
        <div class="w-full lg:w-[300px] grid grid-cols-2 gap-1">
          <div>
            <input
              id="registrationDateFrom"
              type="date"
              v-uppercase
              v-model="form.registrationDateFrom"
              class="input"
            />
          </div>
          <div class="flex items-center gap-1">
            <span class="">To</span>
            <input
              id="registrationDateTo"
              type="date"
              v-model="form.registrationDateTo"
              v-uppercase
              class="input"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="" class="text-sm"
          >Retirement Date Between (DD/MM/YYYY)</label
        >
        <div class="w-full lg:w-[300px] grid grid-cols-2 gap-1">
          <div>
            <input
              id="retirementDateFrom"
              v-model="form.dateOfRetirementFrom"
              type="date"
              v-uppercase
              class="input"
            />
          </div>
          <div class="flex items-center gap-1">
            <span class="">To</span>
            <input
              v-model="form.dateOfRetirementTo"
              id="retirementDateTo"
              type="date"
              v-uppercase
              class="input"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="gender" class="text-sm">Sex</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="gender"
            v-model="form.gender"
            label="label"
            :reduce="(b) => b.value"
            :options="genders"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="gradeStep" class="text-sm">Step</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="gradeStep"
            v-model="form.currentStep"
            label="step"
            :reduce="(b) => b.step"
            :options="$store.state.staff.gradeSteps"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="designation" class="text-sm">Designation</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="designation"
            label="designation"
            v-model="form.designation"
            :reduce="(b) => b.designationId"
            :options="$store.state.staff.designations"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="salaryAccountNumber" class="text-sm"
          >Salary Account Number</label
        >
        <div class="w-full lg:w-[300px]">
          <input
            id="salaryAccountNumber"
            type="text"
            placeholder="-- type here --"
            v-model="form.salaryAccountNumber"
            class="input"
          />
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="bank" class="text-sm">Bank Name</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="bank"
            label="bankName"
            :reduce="(b) => b.bankName"
            v-model="form.bankName"
            :options="$store.state.staff.banks"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row items-start justify-between lg:items-center p-3"
      >
        <label for="bank" class="text-sm">Bank Type</label>
        <div class="w-full lg:w-[300px]">
          <v-select
            id="bank"
            label="bankType"
            :reduce="(b) => b"
            v-model="form.bankType"
            :options="bankTypes"
            v-uppercase
            placeholder="-- select --"
          ></v-select>
        </div>
      </div>
      <div class="px-4 py-2 bg-[#F9F9F9] flex justify-end">
        <button
          :disabled="proceeding || downloading"
          type="submit"
          class="bg-oneGreen font-bold text-white rounded py-2 px-9 text-sm flex items-center justify-between"
        >
          <span class="mr-2"> Generate Report</span>
          <spinner class="h-[20px] w-[20px]" v-if="proceeding"></spinner>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { fetchDepartments } from "@/services/sourceData";

/**
status =
1. [approvalDone] -  Final verification approval completed => approvalStep = 2
2. [accreditationDone] - Biometrics accreditation completed => accredited = true
3. [verificationDone] - Online verification completed => verificationStepStatus = completed
3. [verificationOnGoing] - Online verification completed => verificationStepStatus = in-progress
4. [verificationNotStarted] -  Online verification not started  => registered = null
 */
export default {
  props: {
    proceeding: {
      type: Boolean,
      requried: true,
    },
  },
  name: "NominalRollForm",
  data() {
    return {
      genders: [
        { label: "Female", value: "F" },
        { label: "MALE", value: "M" },
      ],
      departments: [],
      verificationStatus: [
        {
          label: "Final verification approval completed",
          value: "approvalDone",
        },
        {
          label: "Biometrics accreditation completed",
          value: "accreditationDone",
        },
        { label: "Online verification completed", value: "verificationDone" },
        {
          label: "Online verification in-progress",
          value: "verificationOnGoing",
        },
        {
          label: "Online verification not started",
          value: "verificationNotStarted",
        },
      ],
      serviceStatus: ["active", "inactive"],
      downloading: false,
      form: {
        staffTypeId: null,
        departmentId: null,
        status: null,
        salaryGradeLevel: null,
        nationality: null,
        gender: null,
        serviceStatus: null,
        currentStep: null,
        bankName: null,
        designation: null,
        verificationDateFrom: null,
        verificationDateTo: null,
        registrationDateFrom: null,
        registrationDateTo: null,
        dateOfRetirementFrom: null,
        dateOfRetirementTo: null,
        verificationCode: null,
        phoneNumber: null,
        bvn: null,
        bankType: null,
        salaryAccountNumber: null,
        mdaId: null,
        limit: 500,
      },
    };
  },
  computed: {
    bankTypes() {
      return [
        ...new Set(this.$store.state.staff.banks.map((el) => el.bankType)),
      ];
    },
  },
  methods: {
    proceed() {
      const query = this.clean(this.form);
      if (Object.keys(query).length) {
        this.$router.replace({
          path: this.$route.path,
          query,
        });
      }
      this.$emit("searchReport", query);
    },

    clean(obj) {
      for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
    async searchDepartments(search, loading) {
      if (search.length < 3) {
        return;
      }

      try {
        loading(true);
        this.departments = await fetchDepartments(search);
        loading(false);
      } catch (err) {
        loading(false);
      }
    },
  },
};
</script>

<style scoped>
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}
</style>
