<template>
  <div>
    <section
      class="w-full sticky bg-litergray flex md:justify-between items-center px-3 lg:px-6 py-2 flex-col md:flex-row overflow-x-scroll top-0 right-0 left-0 z-100 shadow-sm"
    >
      <div class="flex items-center gap-2">
        <button
          @click="$router.go(-1)"
          class="px-3 py-1 text-gray-500 text-center text-lg"
        >
          &#9204;
        </button>
        <h4 class="font-bold text-xl text-dkgray">
          New GEN 69 Signature Setup
        </h4>
      </div>
    </section>

    <div v-if="step === 1">
      <VerifySignatory
        @verify="proceed"
        :userData="userData"
        :proceeding="proceeding"
        :showBio="showBio"
        @next="step = 2"
      />
    </div>
    <div v-if="step === 2">
      <CreateSignature
        @create-signature="submitSignature"
        :officerName="officerName"
        :proceeding="proceeding"
      />
    </div>
  </div>
</template>

<script>
import VerifySignatory from "@/components/admin/signature/VerifyUser.vue";
import CreateSignature from "@/components/admin/signature/CreateSignature.vue";
export default {
  name: "SignatureSetup",
  components: {
    VerifySignatory,
    CreateSignature,
  },
  data() {
    return {
      proceeding: false,
      showBio: false,
      step: 1,
      userData: {},
      verificationCode: null,
    };
  },
  computed: {
    formReady() {
      return (
        this.form.verificationCode && this.form.verificationCode.length >= 5
      );
    },
    officerName() {
      return `${this.userData.firstName} ${this.userData.middleName} ${this.userData.lastName}`;
    },
  },

  methods: {
    async proceed(form) {
      try {
        this.proceeding = true;
        const payload = {
          search: form.verificationCode,
        };
        const res = await this.$http.get("/signatories/find-signatory", {
          params: payload,
        });

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.userData = data.data;
        this.showBio = true;
        this.verificationCode = form.verificationCode;
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },

    async submitSignature(form) {
      this.proceeding = true;

      try {
        const payload = {
          verificationCode: this.verificationCode,
          ...form,
        };

        const res = await this.$http.post("/signatories/create-signatory", {
          ...payload,
        });

        this.proceeding = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.go(-1);
      } catch (error) {
        this.proceeding = false;
        console.log({ error });
      }
    },
  },
};
</script>

<style></style>
