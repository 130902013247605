<template>
  <div class="px-12 mt-10">
    <section class="flex justify-between items-center">
      <div class="flex items-center gap-6">
        <div>
          <img
            src="@/assets/images/null_female.png"
            alt="user profile pics"
            class="w-12 h-12 lg:w-14 lg:h-14 rounded-lg border object-contain"
          />
        </div>

        <div>
          <h5 class="text-xs font-medium text-idBlued">Welcome</h5>
          <h1 class="text-3pxl font-bold text-idBlued uppercase">
            {{ $store.getters["general/username"] }}
          </h1>
        </div>
      </div>

      <div>
        <router-link
          v-can="'create-user'"
          :to="{ name: 'Admin Create User' }"
          class="bg-idBlued text-white rounded py-2.5 px-2.5 text-sm font-semibold w-full flex justify-center items-center gap-4"
        >
          <img src="@/assets/images/icons/user-add-icon.svg" alt="" />
          <span>Create New User</span>
        </router-link>
      </div>
    </section>

    <!--Small Boxes -->
    <section class="mb-7">
      <AdminSmallBoxes />
    </section>

    <!-- Graph section -->
    <section class="flex justify-between mb-4">
      <AdminBarChart />

      <VerifiedStaff />
    </section>

    <!-- Users Table Data -->
    <section class="mt-9 pb-7" v-can="'create-user'">
      <UsersTable />
    </section>
  </div>
</template>

<script>
import AdminSmallBoxes from "@/components/admin/dashboard/SmallBoxes.vue";
import AdminBarChart from "@/components/admin/dashboard/BarChart.vue";
import VerifiedStaff from "@/components/admin/dashboard/VerifiedStaff.vue";
import UsersTable from "@/components/admin/dashboard/UsersTable.vue";

export default {
  name: "AdminDashboard",

  components: {
    AdminSmallBoxes,
    AdminBarChart,
    VerifiedStaff,
    UsersTable,
  },

  data() {
    return {};
  },

  async created() {
    if (this.$store.getters["admin/permit"]("create-user")) {
      await this.$store.dispatch("admin/getPermissions");
      await this.$store.dispatch("admin/getRoles");
    }
    await this.$store.dispatch("staff/getMdas");
    await this.$store.dispatch("staff/getDesignations");
    await this.$store.dispatch("staff/getBanks");
    await this.$store.dispatch("staff/getSalaryGradeLevels");
    await this.$store.dispatch("staff/getGradeSteps");
    await this.$store.dispatch("admin/getStaffTypes");
  },
};
</script>
